import React, { useState } from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import * as yup from "yup"
import {
  useCourse,
  useCourseMutations,
} from "../../../../../hooks/data/courses/courses"
import { safeGetEntity } from "../../../../../library/utils/entities"
import { Formik } from "formik"
import Form from "../../../../../components/v2/layout/forms/form/form"
import GroupMemberPicker from "../../../../../components/v2/inputs/pickers/groupMemberPicker"
import SchoolCalendarPicker from "../../../../../components/v2/inputs/pickers/schoolCalendarPicker"
import SubjectPicker from "../../../../../components/v2/inputs/pickers/subjectPicker"
import { useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import { useToggle } from "../../../../../hooks/useToggle"
import { PlainButton } from "../../../../../components/v2/controls"
import AlertDialog from "../../../../../components/ui/alertDialog"

const validationSchema = yup.object({
  name: yup.string().min(3).max(200).required(),
  instructions: yup.string().max(5000),
  goal: yup.string().max(200),
  measurement: yup.string().max(200),
  learning_days_number: yup.number().min(1).max(365),
  links: yup.array().of(
    yup.object({
      title: yup.string().max(250).required(),
      url: yup.string().url().required(),
    })
  ),
  gradual_unlocking_steps: yup.boolean(),
})

const CourseSettingsMain = () => {
  const { successAlert, errorAlert } = useAlert()
  const { userData } = useSelector(({ auth }) => auth)
  const { course, isSuccess, queryKey } = useCourse()
  const { updateCourse, deleteCourse } = useCourseMutations({ queryKey })

  const initialValues = {
    name: course.name || "",
    instructions: course.instructions || "",
    links: course.links || [],
    goal: course.goal || "",
    measurement: course.measurement || "",
    learning_days_number: course.learning_days_number || 1,
    gradual_unlocking_steps: course.gradual_unlocking_steps || false,
  }

  const [otherValues, setOtherValues] = React.useState({
    subject: safeGetEntity(course.subject),
    blobs: course.blobs || [],
    schedule: safeGetEntity(course.schedule),
    teacher: safeGetEntity(course.teacher),
  })

  const [errors, setErrors] = useState({
    subjectError: false,
    scheduleError: false,
    teacherError: false,
  })

  const [isDeleteOpen, toggleDelete] = useToggle()

  const handleChangeRequired = (name) => (event) => {
    setErrors({
      ...errors,
      [name + "Error"]: !event.target.value,
    })
    setOtherValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }))
  }

  const onSubmit = async (values) => {
    const data = {
      name: values.name,
      instructions: values.instructions,
      links: values.links,
      goal: values.goal,
      measurement: values.measurement,
      learning_days_number: Number(values.learning_days_number),
      gradual_unlocking_steps: values.gradual_unlocking_steps,
      subject_id: otherValues.subject?.id,
      teacher_id: otherValues.teacher?.id,
      schedule_id: otherValues.schedule?.id,
      blobs: otherValues.blobs,
    }

    try {
      await updateCourse.mutateAsync({ id: course.id, ...data })
      successAlert("Information updated")
    } catch (e) {
      errorAlert(e)
    }
  }

  const onDelete = async () => {
    try {
      await deleteCourse.mutateAsync({ id: course.id })
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <BoxPage title={"Main information"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(fk) => (
          <Form>
            <Form.Row name={"name"} label={"Course name"}>
              <Form.TextField name={"name"} placeholder={"Type course name"} />
            </Form.Row>
            <Form.Row name={"instructions"} label={"Info"}>
              <Form.TextField name={"instructions"} placeholder={"Type info"} />
            </Form.Row>
            <Form.Row name={"goal"} label={"Goal"}>
              <Form.TextField name={"goal"} placeholder={"Type goal"} />
            </Form.Row>
            <Form.Row name={"measurement"} label={"Measurement"}>
              <Form.TextField
                name={"measurement"}
                placeholder={"Type measurement"}
              />
            </Form.Row>
            <Form.Row
              name={"learning_days_number"}
              label={"Estimated total days on this course"}
            >
              <Form.TextField
                name={"learning_days_number"}
                type={"number"}
                InputProps={{ min: 1, max: 365 }}
              />
            </Form.Row>
            <GroupMemberPicker
              value={otherValues.teacher}
              onChange={handleChangeRequired("teacher")}
              hasError={errors.teacherError}
              required
              groupId={course?.creator_group?.id}
              tags={[2]}
              userLabel={"teacher"}
            />
            {!!course?.creator_group && (
              <SchoolCalendarPicker
                groupId={course?.creator_group?.id}
                hasError={errors.scheduleError}
                onChange={handleChangeRequired("schedule")}
                value={otherValues.schedule}
                required
                disabled={!!otherValues.schedule}
              />
            )}

            <SubjectPicker
              value={otherValues.subject}
              required
              hasError={errors.subjectError}
              onChange={handleChangeRequired("subject")}
              // disabled={isPublic}
              scheduleId={
                !otherValues.schedule?.has_district_calendar
                  ? otherValues.schedule?.id
                  : undefined
              }
              calendarId={
                otherValues.schedule?.has_district_calendar
                  ? otherValues.schedule?.calendar_id
                  : undefined
              }
            />

            <Form.Row name={"links"} label={"Links"}>
              <Form.InputsArray
                name={"links"}
                subFields={[
                  {
                    name: "title",
                    placeholder: "Type link title",
                  },
                  {
                    name: "url",
                    placeholder: "Type URL",
                  },
                ]}
              />
            </Form.Row>
            <Form.Row name={"blobs"} label={"Attachments"}>
              <Form.Attachments
                token={userData.token}
                attachments={otherValues.blobs}
                setAttachments={({ blobs }) =>
                  setOtherValues((prev) => ({ ...prev, blobs }))
                }
                isModal
              />
            </Form.Row>
            <Form.Row name={"gradual_unlocking_steps"}>
              <Form.Checkbox
                name={"gradual_unlocking_steps"}
                label={
                  "Show each next Step only upon student clicking “Finish” to the current Step, completing all Step quizzes or their attendance was confirmed at the current Step’s follow-on Meet."
                }
                onChange={(e) => {
                  fk.setFieldValue("gradual_unlocking_steps", e.target.checked)
                }}
              />
            </Form.Row>
            {/*<TagsInput*/}
            {/*  value={values.themes}*/}
            {/*  onChange={(v) =>*/}
            {/*    setValues((prevState) => ({ ...prevState, themes: v }))*/}
            {/*  }*/}
            {/*  label={"Themes"}*/}
            {/*  limit={8}*/}
            {/*  entityName={"theme"}*/}
            {/*/>*/}
          </Form>
        )}
      </Formik>
      <hr className={"border-divider my-4"} />
      <div className="flex justify-center items-center text-black54">
        <div>
          <span>You can </span>
          <PlainButton inline onClick={toggleDelete}>
            delete this course.
          </PlainButton>
        </div>
      </div>
      {isDeleteOpen && (
        <AlertDialog
          open={isDeleteOpen}
          handleClose={toggleDelete}
          handleAccept={onDelete}
          title="Delete this Course?"
          message="If you delete course you will not be able to view information on
              the site. You can restore course only through the support site."
        />
      )}
    </BoxPage>
  )
}

export default CourseSettingsMain
