export const planFeatures = {
  free: [
    "Eliminate back-and-forth by giving out your calendar link for bookings.",
    "Integrate Google or Microsoft Outlook Calendars",
    "Create auto-cohorting AI Meets which form multiple meetings on different dates for cohorts of your total number of invitees based on when they accept",
    "Create regular events",
    "Create event groups",
    "Create interest groups",
    "Advanced meeting tracking with “push forward” option",
    "Personal Profile Pages",
    "Social posting for Educators & Representatives without groups",
    "Social posting for Learners in associated groups",
    "News feeds",
    "Finding and inviting friends",
    "Bookmarking",
    "Creating Cred based on permissions levels for others or yourself",
    "Featured Products Marketplace",
    "One Free Hold (open time bracketed for the AI to calendar meetings into)",
  ],
  1: [
    "Create Request Appointments which send invitations for an amount of time only and then set automatically between members, non-members use your bookings link",
    "Use Holds to tell the AI and outside requesters when to drop-in appointments and meetings. A Hold has your label on bracketed open time which can also have minus-time breaks within it. Educators might use a label like “Parent Conference Time” from 3-4:00 pm Monday through Friday.",
    "Create interest groups.",
    "Create event groups.",
  ],
  2: [
    "Create one-teacher-school group and interest groups.",
    "Create Holds to bracket and label open time.",
    "Create interest and event groups.",
    "Frame your courses to be self-paced but live-intersecting on-demand by building descriptions per step and attaching auto-cohorting class meetings with any step.",
    "Display courses in the featured products section.",
    "Tracking panels.",
    "Create 'Awarded' Cred from your Group (Grades/Marks, Certificates.)",
    "Create a master calendar for your schedule.",
    "Assign study schedules to members.",
    "Use academic standards for all U.S. States and Canada in Course Frames.",
    "Use bookmarking for planning.",
  ],
  3: [
    "Claim or create school and districts groups.",
    // "Sync Google or Outlook calendars for all users.",
    "Users create Holds to bracket and label open time.",
    "Create interest groups.",
    "Create event groups.",
    "Associate schools under districts for collective analytics.",
    "Authentication to invite learners.",
    "Frame your courses to be self-paced but live-intersecting on-demand by building descriptions per step and attaching auto-cohorting class meetings.",
    "Option for closed social feeds for members.",
    "Build course steps and attach auto-cohorting class meetings by step.",
    "Add academic standards, attachments, links and quizzes to steps.",
    "Display courses in the featured products section.",
    "Tracking panels.",
    "Create Validated Cred badges from your Group – Social, Extra-Curricular, Grades/Marks, Certificates, Degrees and Diplomas.",
    "Create a master calendar for your schedule.",
    "Assign study schedules to members.",
    "Use academic standards for all U.S. States and Canada in Course Frames.",
    "Use bookmarking for planning.",
    "Instant transfer of courses and associated class meetings to substitutes, tutors and new teachers.",
  ],
  4: [
    "Create your company group and interest groups.",
    "Authentication for offering demo scheduling to Learners (which may include parents), and educators.",
    // "Sync Google calendars for all users.",
    "Associate a 'School' premium account under your company premium account to offer courses with live teaching or tutoring.",
    "Use Holds to tell the AI and outside requesters when to drop-in appointments and meetings. A Hold has your label on bracketed open time which can also have minus-time breaks within it. Your company agents might use a label like 'Demo Time' from 9-12:00pm Monday through Friday.",
    "Build product tiles in the featured products section of the site with full descriptions.",
    "Create interest groups.",
    "Create event groups.",
  ],
}
