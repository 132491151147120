import React from "react"
import Button from "@material-ui/core/Button"
import { usePermissionChecker } from "../../../../permissionChecker/usePermissionChecker"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { useSelector } from "react-redux"
import { usePaidPlansQuery } from "../../../../screens/info/pricing/usePlans"
import { Li, Ul } from "../../../../screens/info/accounts/accountsInfoMain"
import PlainLink from "../../../ui/link"
import { PRICING } from "../../../../library/constants/routes"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { Link } from "react-router-dom"

const ActionWithPermissions = ({
  component = "button",
  allowedPlans = [],
  paidOnly = false,
  allowGrantedSubscription = true,
  allowedRoles = [],
  action,
  isLoading = false,
  loadingText = "Loading...",
  children,
  ...componentProps
}) => {
  const { subscriptions, userData } = useSelector(({ auth }) => auth)

  const { paidPlans } = usePaidPlansQuery()

  const getCurrentPlanName = () => {
    if (!subscriptions.personal_subscription) {
      return "Free"
    }

    return subscriptions.personal_subscription.plan_name
  }

  let whitelist = []

  if (paidOnly) {
    whitelist = [1, 2, 3, 4]
  }

  if (
    allowedPlans.length &&
    !(allowedPlans.length === 1 && allowedPlans[0] === 1)
  ) {
    whitelist = allowedPlans
  }

  const isAllowed = usePermissionChecker(whitelist, allowGrantedSubscription)

  const [isAlertOpen, setIsAlertOpen] = React.useState(false)

  const Component = component
  let __action = action

  if (!isAllowed) {
    __action = () => {
      setIsAlertOpen(true)
    }
  }

  return (
    <>
      <Component {...componentProps} onClick={__action}>
        {isLoading ? loadingText : children}
      </Component>

      {isAlertOpen && (
        <Dialog
          open={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
          aria-labelledby="alert-dialog-title"
          maxWidth={"sm"}
        >
          <DialogTitle>
            You don't have permission to perform this action
          </DialogTitle>
          <DialogContent>
            <div>
              <p>Please upgrade your subscription to access this feature.</p>
              <p>You are on {getCurrentPlanName()} plan</p>
              <p>Check the plans that support this feature:</p>
              <Ul className={"mt20"}>
                {paidPlans
                  ?.filter((p) => allowedPlans.includes(p.id))
                  .map((p) => (
                    <Li key={p.id}>
                      <PlainLink to={PRICING}>{p.name}</PlainLink>
                    </Li>
                  ))}
              </Ul>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setIsAlertOpen(false)}>
              Close
            </Button>
            <Button color="primary" component={Link} to={PRICING}>
              See Pricing
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ActionWithPermissions
