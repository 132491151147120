import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import SuperDataTable from "../../../../../components/v2/dataTable/SuperDataTable"
import UserLink from "../../../../../components/v2/utils/UserLink"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import PlainLink from "../../../../../components/ui/link"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"

const TrackingIncomingAiAppointmentsPage = () => {
  return (
    <BoxPage title={"Incoming AI Meets"}>
      <SuperDataTable
        columns={[
          { label: "Title or Step #", role: "name" },
          { label: "Type" },
          { label: "Inviter" },
          { label: "Target or Set Date" },
          { label: "Duration" },
          { label: "Link/Phone" },
          { label: "Instructions" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          noFetch: true,
          key: [
            {
              type: "ai-meets-incoming",
              url: "tracking.ai_meets",
              fetchParams: { b_creator: false },
            },
          ],
          listKey: "list",
          counterKey: "total",
          composeRowData: (record) => [
            record.name || "",
            record.type || "",
            record.creator.id
              ? {
                  type: "component",
                  component: () => <UserLink user={record.creator} />,
                }
              : record.creator.name_first + " " + record.creator.name_last,
            safeFormatDate(record.target),
            record.duration || "",
            record.conference_link.length
              ? {
                  type: "component",
                  component: () => (
                    <PlainLink native to={record.link}>
                      {record.link}
                    </PlainLink>
                  ),
                }
              : record.phone.length
                ? {
                    type: "component",
                    component: () => (
                      <PlainLink phone to={record.phone}>
                        {record.phone}
                      </PlainLink>
                    ),
                  }
                : "—",
            record.instructions || "",
          ],
        }}
        actions={[
          {
            type: "button",
            label: () => (
              <>
                <CheckOutlined />
                <span>Accept</span>
              </>
            ),
            onClick: () => {
              console.log("accept")
            },
          },
          {
            type: "button",
            label: () => (
              <>
                <CloseOutlined />
                <span>Decline</span>
              </>
            ),
            onClick: () => {
              console.log("decline")
            },
          },
          {
            type: "button",
            label: () => (
              <>
                <UpdateIcon />
                <span>Push forward</span>
              </>
            ),
            onClick: () => {
              console.log("push forward")
            },
          },
        ]}
        actionsPinned
        isSearchable
        customColumnsWidth
      />
    </BoxPage>
  )
}

export default TrackingIncomingAiAppointmentsPage
