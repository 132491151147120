import React from "react"
import AuthPageLayout from "../components/AuthPageLayout"
import AuthFormLayout from "../components/AuthFormLayout"
import * as yup from "yup"
import { useAlert } from "../../../hooks/useAlert"
import useCreator from "../../../hooks/useCreator"
import { signIn } from "../../../library/store/actions/creators/authCreators"
import { RESET_PASSWORD } from "../../../library/constants/routes"
import { useAuthenticatedRedirect } from "../../../hooks/useAuthenticatedRedirect"
import { useSelector } from "react-redux"
import SEO from "../../../components/SEO"
import { Form, Formik } from "formik"
import TextInput from "../../../components/v2/inputs/textInput/textInput"
import Checkbox from "../../../components/v2/inputs/checkbox/checkbox"
import PlainLink from "../../../components/ui/link"
import Button from "@material-ui/core/Button"

const validationSchema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
  remember: yup.boolean(),
})

const SignInPage = () => {
  const { errorAlert } = useAlert()
  const creator = useCreator()
  const { isLoggedIn } = useSelector((state) => state.auth)

  const [shouldRedirect, setShouldRedirect] = React.useState(false)

  React.useEffect(() => {
    setShouldRedirect(isLoggedIn)
  }, [isLoggedIn])

  useAuthenticatedRedirect({ shouldRedirect, redirectFromSearchQuery: true })

  const initialValues = {
    email: "",
    password: "",
    remember: false,
  }

  const onSubmit = async (values) => {
    try {
      await creator(signIn({ email: values.email, password: values.password }))

      setShouldRedirect(true)
    } catch (e) {
      errorAlert(e)
    }
  }
  return (
    <AuthPageLayout shouldRedirectIfLoggedIn={false}>
      <SEO title="Sign In" />
      <AuthFormLayout title={"Sign In"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          <Form className={"w-full space-y-6"}>
            <TextInput name={"email"} label={"Email *"} />
            <TextInput
              name={"password"}
              type={"password"}
              label={"Password *"}
            />
            <div className="flex items-center justify-between mb-6">
              <Checkbox name={"remember"} label={"Remember me"} />
              <PlainLink to={RESET_PASSWORD}>Forgot password?</PlainLink>
            </div>
            <div className="flex justify-center">
              <Button color="primary" variant="contained" type="submit">
                Sign In
              </Button>
            </div>
          </Form>
        </Formik>
      </AuthFormLayout>
    </AuthPageLayout>
  )
}

export default SignInPage
