import React from "react"
import StaticDataSelect from "../v2/inputs/select/staticDataSelect"
import { useTimezone } from "../../hooks/useTimezone"

const TimezonePicker = ({
  value,
  onChange,
  allowEmpty = false,
  withLeftLabel = false,
}) => {
  const { currentTz, tzList } = useTimezone()

  const [selectedValue, setSelectedValue] = React.useState(value)

  console.log(tzList)

  React.useEffect(() => {
    if (!value && !allowEmpty) {
      const suggestedTz = tzList.find((tz) => tz.id === currentTz)
      setSelectedValue(suggestedTz)
      onChange(suggestedTz)
    }

    if (typeof value === "string" && !!value) {
      const suggestedTz = tzList.find((tz) => tz.id === value)
      console.log({ suggestedTz })
      setSelectedValue(suggestedTz)
      onChange(suggestedTz)
    }
  }, [])

  const handleChange = (tz) => {
    setSelectedValue(tz)
    onChange(tz)
  }

  console.log(selectedValue)

  return (
    <StaticDataSelect
      data={tzList}
      value={selectedValue}
      onChange={handleChange}
      leftLabel={withLeftLabel}
      entityName={"Time zone"}
      fieldName={"timezone"}
    />
  )
}

export default TimezonePicker
