import React from "react"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { NOTIFICATION } from "../../../../library/store/actions/types/notificationTypes"
import { call } from "../../../../library/networking/API"
import { useAlert } from "../../../../hooks/useAlert"
import { useDispatch } from "react-redux"
import Loader from "../../../ui/loader"
import NotificationCard from "../../../../screens/main/notifications/notificationCard"
import useCreator from "../../../../hooks/useCreator"
import * as routes from "../../../../library/constants/routes"
import PlainLink from "../../../ui/link"

const NotificationsList = () => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()
  const creator = useCreator()

  const dispatch = useDispatch()

  const { data, isSuccess } = useQuery({
    queryKey: [NOTIFICATION.LIST, { offset: 0, limit: 5 }],
    queryFn: async (ctx) => {
      const response = await call(NOTIFICATION.LIST, ctx.queryKey[1])

      dispatch({ type: NOTIFICATION.CLEAR_NOTIFICATIONS_COUNT })

      return response.data
    },
    refetchOnWindowFocus: false,
    refetchInterval: false,
  })

  React.useEffect(() => {}, [])

  return (
    <div className={"box w-[350px]"}>
      <div className={"box__heading"}>Notifications</div>
      {isSuccess ? (
        <>
          {data.notifications.length ? (
            <>
              {data.notifications.slice(0, 5).map((item, i) => {
                return (
                  <NotificationCard
                    key={item.id}
                    notification={item}
                    queryKey={[NOTIFICATION.LIST, { offset: 0, limit: 5 }]}
                    index={i}
                    dense
                  />
                )
              })}

              <div className="h-divider" />

              <div className="pt-1 pb-4 px-4 text-center w-full">
                {!!data.notifications.length && (
                  <PlainLink inline to={routes.NOTIFICATIONS}>
                    Show all
                  </PlainLink>
                )}
              </div>
            </>
          ) : (
            <div className="box__content">
              <div className="flex justify-center text-black54">
                There are no notifications
              </div>
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </div>
  )
}

export default NotificationsList
