import React from "react"
import Stepper from "@material-ui/core/Stepper/Stepper"
import Step from "@material-ui/core/Step/Step"
import StepLabel from "@material-ui/core/StepLabel/StepLabel"
import SignUpStep3 from "./components/SignUpStep3"
import SignUpStep2 from "./components/SignUpStep2"
import SignUpStep4 from "./components/SignUpStep4"
import { useSignUpContext } from "./SignUpProvider"
import AuthPageLayout from "../components/AuthPageLayout"
import { useHistory } from "react-router-dom"
import { SIGN_UP } from "../../../library/constants/routes"

const CompleteSignUpPage = () => {
  const history = useHistory()
  const {
    data,
    setData,
    navigateNext,
    navigateBack,
    navigateToSignUpRoot,
    activeStep,
    setActiveStep,
  } = useSignUpContext()

  const steps = [
    {
      label: "Location",
      component: <SignUpStep3 />,
    },
    {
      label: "Role",
      component: <SignUpStep2 />,
    },
    {
      label: data.role === "1" ? "School" : "Organization",
      component: <SignUpStep4 />,
    },
  ]

  return (
    <AuthPageLayout className={"bg-white"}>
      <div className={"w-[460px] mx-auto"}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className="pt-8">{steps[activeStep].component}</div>
      </div>
    </AuthPageLayout>
  )
}

export default CompleteSignUpPage
