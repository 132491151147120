import React from "react"
import { useAlert } from "../../../hooks/useAlert"
import * as yup from "yup"
import AuthPageLayout from "../components/AuthPageLayout"
import SEO from "../../../components/SEO"
import AuthFormLayout from "../components/AuthFormLayout"
import { Form, Formik } from "formik"
import TextInput from "../../../components/v2/inputs/textInput/textInput"
import Button from "@material-ui/core/Button"
import { call } from "../../../library/networking/API"
import * as endpoints from "../../../library/networking/apiEndpoints"

const validationSchema = yup.object({
  email: yup.string().email().required(),
})

const ResetPasswordRequestPage = () => {
  const { errorAlert, successAlert } = useAlert()

  const initialValues = {
    email: "",
  }

  const onSubmit = async ({ email }, { resetForm }) => {
    try {
      await call(endpoints.USERS.REQUEST_PASSWORD_RESET, {
        email,
      })
      successAlert("Your request received. Check your email to reset password.")
      resetForm()
    } catch (e) {
      if (e.error.code === 20006) return errorAlert("Invalid email")

      errorAlert(e)
    }
  }

  return (
    <AuthPageLayout>
      <SEO title={"Reset Password"} />
      <AuthFormLayout
        title={"Reset Password"}
        description={
          "Enter your email address below. We'll look for your account and send you a password reset email"
        }
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          <Form className={"w-full space-y-6"}>
            <TextInput name={"email"} label={"Email *"} />
            <div className="flex justify-center">
              <Button color="primary" variant="contained" type="submit">
                Send password reset
              </Button>
            </div>
          </Form>
        </Formik>
      </AuthFormLayout>
    </AuthPageLayout>
  )
}

export default ResetPasswordRequestPage
