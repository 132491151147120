export const returnExternalUrl = (url) => {
  if (!url) return ""

  return url.startsWith("https://") || url.startsWith("http://")
    ? url
    : `http://${url}`
}

export default returnExternalUrl

export const isAbsoluteUrl = (url) => {
  const regex = new RegExp("^(?:[a-z]+:)?//", "i")
  return regex.test(url)
}
