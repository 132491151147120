import unavailableAvatar from "../../../assets/img/user_unavailable.svg"
import userAvatarDefault from "../../../assets/img/user_avatar.svg"
import { generatePath } from "react-router-dom"
import { BOOK_MEET, USER } from "../../constants/routes"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../getUserHighlightedInfo"
import { baseUrl } from "../../networking/API"
import { userRolesIds } from "../../constants/userRoles"

export const getUserName = (user) => {
  if (user?.name_first && user?.name_last) {
    return user.name_first + " " + user.name_last
  }

  if (user?.first_name && user?.last_name) {
    return user?.first_name + " " + user?.last_name
  }

  return ""
}

export const getUserPhoto = (user) => {
  if (user?.deleted || user?.blocked) return unavailableAvatar
  if (!user?.photo) return userAvatarDefault

  return user?.photo
}

export class User {
  data = null

  constructor(user) {
    this.data = user
  }

  get info() {
    return this.data
  }

  get name() {
    return getUserName(this.data)
  }

  get photo() {
    if (this.data.deleted || this.data.blocked) return unavailableAvatar
    if (!this.data.photo) return userAvatarDefault

    return this.data.photo
  }

  get accountRole() {
    return userRolesIds[this.data.role].name
  }

  get hasLocation() {
    return !![this.data.city, this.data.state, this.data.country].filter(
      Boolean
    ).length
  }

  get location() {
    return [this.data.city, this.data.state, this.data.country]
      .filter(Boolean)
      .join(", ")
  }

  get path() {
    return generatePath(USER, { userId: this.data.id || this.data.user_id })
  }

  get hasHighlightedEducation() {
    if (!this.data.highlighted) {
      return !!this.data.education
    }

    return this.data.highlighted === 2 && !!this.data.education
  }

  get hasHighlightedWork() {
    if (!this.data.highlighted) {
      return !!this.data.work
    }

    return this.data.highlighted === 1 && !!this.data.work
  }

  get hasHighlightedInfo() {
    if (!this.data.highlighted) {
      return !!this.data.education || !!this.data.work
    }

    return this.hasHighlightedWork || this.hasHighlightedEducation
  }

  get highlightedInfo() {
    if (this.hasHighlightedEducation) {
      return composeUserHighlightedEducation(this.data.education)
    }

    if (this.hasHighlightedWork) {
      return composeUserHighlightedWork(this.data.work)
    }

    return ""
  }
}

export const getUserBookingLink = (userId) => {
  return baseUrl + generatePath(BOOK_MEET, { userId })
}
