import React from "react"
import { Form, Formik } from "formik"
import TextInput from "../../../../components/v2/inputs/textInput/textInput"
import FormControl from "@material-ui/core/FormControl/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import Checkbox from "../../../../components/v2/inputs/checkbox/checkbox"
import Button from "@material-ui/core/Button"
import * as routes from "../../../../library/constants/routes"
import { useSignUpContext } from "../SignUpProvider"
import { useAlert } from "../../../../hooks/useAlert"
import * as yup from "yup"
import PlainLink from "../../../../components/ui/link"

const validationSchema = yup.object({
  first_name: yup.string().required().max(75),
  last_name: yup.string().required().max(75),
  email: yup.string().email().required(),
  password: yup
    .string()
    .min(8)
    .max(32)
    .matches(/^\S*$/, "Spaces are not allowed")
    .required(),
  age_consent: yup.string(),
})

const SignUpStep1 = () => {
  const { data, navigateNext } = useSignUpContext()
  const { errorAlert } = useAlert()

  const initialValues = {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    password: data.password,
    age_consent: data.age_consent,
  }

  const onSubmit = async (values) => {
    try {
      navigateNext({
        ...values,
        role: values.age_consent !== "true" ? "1" : data.role,
      })
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {(fk) => {
        return (
          <Form className={"w-full space-y-6"}>
            <TextInput name={"first_name"} label={"First name *"} />
            <TextInput name={"last_name"} label={"Last name *"} />
            <TextInput name={"email"} label={"Email *"} />
            <TextInput
              name={"password"}
              type={"password"}
              label={"Password *"}
            />
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="age_consent"
                name="age_consent"
                value={fk.values.age_consent}
                onChange={fk.handleChange("age_consent")}
              >
                <FormControlLabel
                  className="mb-4"
                  value="true"
                  control={<Radio />}
                  label={
                    <span className={"text-sm leading-none"}>
                      I am at least 13 years of age or have my parents approval
                      to use KnowStory
                    </span>
                  }
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label={
                    <span className={"text-sm leading-none"}>
                      I am under 13 and have parent’s approval or my school’s
                      approval to sign up as a Learner. I know I will not be
                      able to post and will see posts only from closed Groups
                      that I am a member of, and my profile will not be visible
                      to the public internet.
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
            <div className="mt-6">
              <Button color="primary" variant="contained" type="submit">
                Next
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SignUpStep1
