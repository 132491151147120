import React from "react"
import SuperListPage from "../../../../../../components/v2/layout/superListPage/superListPage"
import { GROUPS } from "../../../../../../library/store/actions/types/groupsTypes"
import { getCardSize } from "../../../../../../library/constants/styles/cardSizes"
import { User } from "../../../../../../library/utils/user/userUtils"
import UserCard from "../../../../../../components/v2/layout/cards/userCard"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { useGroupMemberMutations } from "../../../../../../hooks/data/groups/groups"
import CheckIcon from "@material-ui/icons/CheckOutlined"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import { Tooltip } from "@material-ui/core"

const GroupSettingsMembershipRequestsPage = () => {
  const groupId = useIdParam("groupId")

  const queryKey = [GROUPS.PARTICIPANTS.REQUESTS.LIST, { group_id: groupId }]

  const {
    requests: { accept, decline },
  } = useGroupMemberMutations({ queryKey })

  const onDecline = async (userId) => {
    await decline.mutateAsync({ group_id: groupId, user_id: userId })
  }
  const onAccept = async (userId) => {
    await accept.mutateAsync({ group_id: groupId, user_id: userId })
  }

  return (
    <>
      <SuperListPage
        queryConfig={{
          key: queryKey,
          fetchParams: {
            group_id: groupId,
          },
          listKey: "users",
        }}
        headerProps={{
          title: "Requests",
        }}
        listItemHeight={getCardSize(8).numValPx}
        getListItemComponent={({ item }) => {
          const __user = new User(item)

          return (
            <UserCard
              key={item.id}
              user={item}
              textAboveName={__user.accountRole}
              infoComponent={() => (
                <div className={"space-y-1"}>
                  {__user.hasLocation && (
                    <p className={""}>{__user.location}</p>
                  )}
                  {__user.hasHighlightedInfo && <p>{__user.highlightedInfo}</p>}
                </div>
              )}
              controls={{
                getControls: () => (
                  <div className={"flex items-center gap-1"}>
                    <Tooltip title={"Decline Request"} arrow>
                      <IconButton
                        aria-label={"Decline Request"}
                        onClick={() => onDecline(item.id)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Accept Request"} arrow>
                      <IconButton
                        color={"primary"}
                        aria-label={"Accept Request"}
                        onClick={() => onAccept(item.id)}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ),
              }}
            />
          )
        }}
      />
    </>
  )
}

export default GroupSettingsMembershipRequestsPage
