import { useSelector } from "react-redux"
import { generatePath, Redirect, Route } from "react-router-dom"
import LoggedInLayout from "./main/loggedInLayout"
import { SIGN_IN, SIGN_OUT } from "../library/constants/routes"

const PrivateRouteV2 = ({ path, publicView, redirectRouteIfLoggedOut }) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const requestedUrl = window.location.href

  if (!!publicView) {
    return (
      <Route
        exact
        path={path}
        render={(props) =>
          isLoggedIn ? <LoggedInLayout {...props} /> : publicView(props)
        }
      />
    )
  }

  return (
    <Route
      exact
      path={path}
      render={(props) => {
        return isLoggedIn ? (
          <LoggedInLayout {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectRouteIfLoggedOut
                ? generatePath(redirectRouteIfLoggedOut, props.match.params)
                : SIGN_IN,
              state: { from: props.location },
              search:
                props.match.path !== SIGN_OUT
                  ? "?redirectTo=" + requestedUrl
                  : undefined,
            }}
          />
        )
      }}
    />
  )
}

export default PrivateRouteV2
