import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"

const TrackingOutgoingAiAppointmentsByStudentPage = () => {
  return (
    <BoxPage title={"Outgoing AI Meets By Student"}>
      TrackingOutgoingAiAppointmentsByStudentPage
    </BoxPage>
  )
}

export default TrackingOutgoingAiAppointmentsByStudentPage
