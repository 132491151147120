import React, { useEffect, useState } from "react"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "./eventsCalendar.scss"
import { views } from "react-big-calendar/lib/utils/constants"
import { useCalendar } from "../../CalendarContext"
import { addMonths, subMonths } from "date-fns"
import { useDispatch, useSelector } from "react-redux"
import { useAlert } from "../../../../../hooks/useAlert"
import { getCalendarEventsList } from "../../../../../library/store/actions/creators/calendarCreators"
import KSCalendar from "../../../../../components/v2/calendar/calendar"
import * as qs from "query-string"
import { useLocation } from "react-router-dom"
import Loader from "../../../../../components/ui/loader"
import { Backdrop } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useBackdropStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
  },
}))

const initialRange = {
  date_from: subMonths(new Date(), 3),
  date_to: addMonths(new Date(), 3),
}

const EventsCalendar = ({
  noFilters = false,
  calendarStyle = {},
  isFullScreen = false,
  onClose,
}) => {
  const backdropStyles = useBackdropStyles()

  const location = useLocation()
  const dispatch = useDispatch()
  const { errorAlert } = useAlert()
  const {
    globalDate,
    setGlobalDate,
    activeRange,
    onEventFormModalOpen,
    onEventDialogOpen,
  } = useCalendar()

  const [date, setDate] = useState(globalDate?.date || new Date())
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdating, setIsUpdating] = useState(false)
  const eventsList = useSelector(({ calendar }) =>
    calendar.eventsList.map((e) => ({
      ...e,
      start: new Date(e.start),
      end: new Date(e.end),
    }))
  )

  const fetchData = async () => {
    const { groups } = qs.parse(location.search, { arrayFormat: "comma" })

    const _g = Array.isArray(groups)
      ? groups.map((id) => parseInt(id, 10))
      : groups?.split(",")?.map((id) => parseInt(id, 10)) || []

    await dispatch(
      getCalendarEventsList({
        ...activeRange,
        group_ids: _g.length ? _g : undefined,
      })
    )
  }

  useEffect(async () => {
    try {
      await fetchData()
      setIsLoading(false)
    } catch (e) {
      errorAlert(e)
    }
  }, [])

  useEffect(async () => {
    if (!isLoading) {
      setIsUpdating(true)
      await fetchData()
      setIsUpdating(false)
    }
  }, [activeRange, location.search])

  useEffect(() => {
    if (globalDate.updatedBy === "sidebar" && globalDate.date !== date) {
      setDate(globalDate.date)
    }
  }, [globalDate])

  const onNavigate = (d) => {
    setDate(d)
    setGlobalDate({ date: d, updatedBy: "main" })
  }

  return (
    <>
      <KSCalendar
        style={calendarStyle}
        disabled={isLoading}
        date={date}
        onNavigate={onNavigate}
        eventsList={!isLoading ? eventsList : []}
        viewsList={[views.MONTH, views.WEEK, views.DAY, views.AGENDA]}
        onSelectEvent={(event) =>
          onEventDialogOpen(event.id, event.entity_type)
        }
        onSelectSlot={({ start, end }) => onEventFormModalOpen({ start, end })}
        noFilters={noFilters}
        isFullScreen={isFullScreen}
        onClose={onClose}
        isUpdating={isUpdating}
      />
      {isLoading && (
        <Backdrop open className={backdropStyles.backdrop}>
          <Loader size={64} />
        </Backdrop>
      )}
    </>
  )
}

export default EventsCalendar
