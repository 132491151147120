import React from "react"
import { Form, Formik } from "formik"
import Button from "@material-ui/core/Button"
import * as yup from "yup"
import TextInput from "../../../../components/v2/inputs/textInput/textInput"
import { countries, states } from "../../../../library/constants/states"
import Select from "../../../../components/v2/inputs/select/select"
import { useSignUpContext } from "../SignUpProvider"

const validationSchema = yup.object({
  country: yup.string().required(),
  state: yup.string(),
  city: yup.string(),
})

const SignUpStep3 = () => {
  const { data, setData, navigateBack } = useSignUpContext()

  const initialValues = {
    country: data.country,
    state: data.state,
    city: data.city,
  }

  const onSubmit = async (values) => {}

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {(fk) => {
          console.log(fk.values)
          return (
            <Form className={"w-full space-y-6"}>
              <Select
                name={"country"}
                placeholder={"Select your country"}
                options={[
                  { label: "Select country" },
                  ...countries.map((c) => ({ value: c, label: c })),
                ]}
                onBlur={() => setData({ ...data, country: fk.values.country })}
              />
              {fk.values.country === "USA" && (
                <Select
                  name={"state"}
                  placeholder={"Select your state"}
                  options={[
                    { label: "Select state" },
                    ...states.map((state) => ({ value: state, label: state })),
                  ]}
                  onBlur={() => setData({ ...data, state: fk.values.state })}
                />
              )}
              <TextInput
                name={"city"}
                placeholder={"Type your city"}
                onBlur={() => setData({ ...data, city: fk.values.city })}
              />
              <div className="flex gap-4 mt-6">
                <Button
                  color="primary"
                  variant="outlined"
                  type="button"
                  onClick={() => navigateBack(fk.values)}
                >
                  Back
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default SignUpStep3
