import React from "react"

import "./auth-page.scss"
import { generatePath, Redirect, useParams } from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import TermsAndConditions from "../../info/termsAndConditions/termsAndConditions"
import ContactForm from "../../info/contactForm"
import CreateMeetAsGuest from "../createMeetAsGuest/createMeetAsGuest"
import IncomingSolicitAppointmentUnauthorized from "../incomingSolicitAppointmentUnauthorized/IncomingSolicitAppointmentUnauthorized"

const AuthPage = (props) => {
  const { pageType } = props
  const params = useParams()

  console.log("Auth Page", props)

  if (props.auth && props.auth.isLoggedIn && props.auth.userData.id) {
    const redirectTo = props.redirectRouteIfLoggedIn
      ? generatePath(props.redirectRouteIfLoggedIn, params)
      : generatePath(routes.USER, { userId: props.auth.userData.id })

    console.log("redirectTo", redirectTo)

    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { from: props.location },
        }}
      />
    )
  } else {
    localStorage.clear()

    const AuthPageContent = () => {
      switch (pageType) {
        case "terms":
          return <TermsAndConditions />

        case "feed-back":
          return <ContactForm />

        case "book-meet-unauthorized":
          return <CreateMeetAsGuest />

        case "incoming-solicit-appointment-unauthorized":
          return <IncomingSolicitAppointmentUnauthorized />

        default:
          return null
      }
    }

    return (
      <div className="container home home-page">
        <AuthPageContent />
      </div>
    )
  }
}

export default AuthPage
