import React from "react"
import { generatePath } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import Card from "./card"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../library/utils/getUserHighlightedInfo"
import unavailableAvatar from "../../../../assets/img/user_unavailable.svg"
import userAvatarDefault from "../../../../assets/img/user_avatar.svg"
import { getCardSize } from "../../../../library/constants/styles/cardSizes"
import { User } from "../../../../library/utils/user/userUtils"

const UserCard = ({ user, infoComponent, controls, textAboveName = "" }) => {
  const __user = new User(user)

  return (
    <Card
      size={getCardSize(8).numValPx}
      name={__user.name}
      photo={__user.photo}
      link={__user.path}
      textAboveName={textAboveName}
      infoComponent={
        typeof infoComponent === "function"
          ? infoComponent
          : () => (
              <div className={"space-y-1"}>
                {__user.hasHighlightedInfo && <p>{__user.highlightedInfo}</p>}
              </div>
            )
      }
      controls={controls}
    />
  )
}

export default UserCard
