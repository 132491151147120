import React from "react"
import { Form, Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useAlert } from "../../../../hooks/useAlert"
import { useHistory } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import * as yup from "yup"
import TextInput from "../../../../components/v2/inputs/textInput/textInput"
import { countries, states } from "../../../../library/constants/states"
import Select from "../../../../components/v2/inputs/select/select"
import { useSignUpContext } from "../SignUpProvider"

const validationSchema = yup.object({})

const SignUpStep4 = ({ activeStep, setActiveStep }) => {
  const { errorAlert } = useAlert()
  const history = useHistory()
  const { data, setData } = useSignUpContext()

  const initialValues = {}

  const onSubmit = async (values) => {
    try {
      setData({ ...data, ...values })
      console.log("SUBMIT", data)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {(fk) => {
        console.log(fk.values)
        return (
          <Form className={"w-full space-y-6"}>
            <div className="flex justify-between mt-6">
              <Button color="primary" variant="contained" type="submit">
                Next
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SignUpStep4
