import React from "react"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import { useUser } from "../../../../../../hooks/data/user/useUser"
import defaultDate from "../../../../../../library/constants/defaultDates"
import { isDefaultDate } from "../../../../../../library/utils/dateConvert"
import { clsx } from "clsx"
import { format } from "date-fns"

const InfoRecords = ({ records = [] }) => {
  return (
    <div
      className={
        "grid grid-cols-[auto,_1fr] grid-flow-row gap-x-8 gap-y-2 w-3/4 pb-4 last:pb-0 border-b border-solid border-gray-200 last:border-b-0"
      }
    >
      {records.filter(Boolean).map((record, i) => (
        <div key={record.label} className={"grid grid-cols-subgrid col-span-2"}>
          <p className={"text-black54"}>{record.label}:</p>
          <p>{record.content}</p>
        </div>
      ))}
    </div>
  )
}

const UserInfoTabs = () => {
  const userId = useIdParam("userId")
  const { user, isSuccess, isLoading, isFetching } = useUser(userId)

  const [activeTab, setActiveTab] = React.useState(0)

  const tabs = React.useMemo(() => {
    let list = []

    if (user.educations.length) {
      list.push({
        label: "Education",
        content: () => {
          let educationsInfo = []
          user.educations.forEach((education) => {
            let inst, dep
            switch (education.type) {
              case 1:
              case 2:
              case 3:
                inst = "School"
                dep = ""
                break
              case 4:
                inst = "College/University"
                dep = "Major"
                break
              case 5:
                inst = "School"
                dep = "Training"
                break
              case 6:
                inst = "School"
                dep = "Certification"
                break
              default:
                inst = "Institution"
                dep = "Department"
            }

            let studyStarted =
              !!education.date_from && !isDefaultDate(education.date_from)
                ? new Date(education.date_from).getFullYear()
                : ""
            let studyFinished =
              !!education.date_to && !isDefaultDate(education.date_to)
                ? new Date(education.date_to).getFullYear()
                : ""

            educationsInfo.push([
              {
                label: inst,
                content:
                  education.name +
                  (studyStarted
                    ? `, ${studyStarted} ${studyFinished ? "- " + studyFinished : ""}`
                    : ""),
              },
              !!education.department && {
                label: dep,
                content: education.department,
              },
            ])
          })

          return (
            <div className={"space-y-4"}>
              {educationsInfo.map((r) => (
                <InfoRecords records={r} />
              ))}
            </div>
          )
        },
      })
    }

    if (user.works.length) {
      list.push({
        label: "Work",
        content: () => {
          let worksInfo = []

          user.works.forEach((work) => {
            let workStarted =
              !!work.date_from && !isDefaultDate(work.date_from)
                ? new Date(work.date_from).getFullYear()
                : ""
            let workFinished =
              !!work.date_to && !isDefaultDate(work.date_to)
                ? new Date(work.date_to).getFullYear()
                : ""

            let department = work.department ? work.department : ""

            worksInfo.push([
              {
                label: [1, 2].includes(user.role) ? "Institution" : "Company",
                content:
                  work.name +
                  (workStarted
                    ? `, ${workStarted} ${workFinished ? "- " + workFinished : ""}`
                    : ""),
              },
              !!work.role && {
                label: "Position",
                content: work.role,
              },
              !!work.description && {
                label: "About",
                content: work.description,
              },
            ])
          })
          return (
            <div className={"space-y-4"}>
              {worksInfo.map((r) => (
                <InfoRecords records={r} key={r.label} />
              ))}
            </div>
          )
        },
      })
    }

    if (
      !!(
        (!!user?.birthdate && user?.birthdate !== defaultDate) ||
        user?.interests?.trim() ||
        user?.favorite_films?.trim() ||
        user?.favorite_books?.trim() ||
        user?.favorite_games?.trim() ||
        user?.favorite_quotes?.trim() ||
        user?.favorite_subjects?.trim()
      )
    ) {
      let personalInfo = []

      if (user?.birthdate && !isDefaultDate(user?.birthdate))
        personalInfo.push({
          label: "Date of birth",
          content: format(new Date(user.birthdate), "d MMMM yyyy"),
        })

      if (user?.interests?.trim())
        personalInfo.push({ label: "Interests", content: user.interests })

      if (user?.favorite_subjects?.trim())
        personalInfo.push({
          label: "Favorite subjects",
          content: user.favorite_subjects,
        })

      if (user?.favorite_books?.trim())
        personalInfo.push({
          label: "Favorite books",
          content: user.favorite_books,
        })

      if (user?.favorite_films?.trim())
        personalInfo.push({
          label: "Favorite films",
          content: user.favorite_films,
        })

      if (user?.favorite_games?.trim())
        personalInfo.push({
          label: "Favorite games",
          content: user.favorite_games,
        })

      if (user?.favorite_quotes?.trim())
        personalInfo.push({
          label: "Favorite quotes",
          content: user.favorite_quotes,
        })

      list.push({
        label: "Personal",
        content: () => <InfoRecords records={personalInfo} />,
      })
    }

    return list
  }, [user])

  return (
    !!tabs.length && (
      <div>
        <div className="flex gap-x-2">
          {tabs.map((tab, index) => (
            <button
              onClick={() => setActiveTab(index)}
              className={clsx(
                " h-9 w-28 text-center  py-1 px-4 rounded-full border border-solid border-primary transition-200 transition-colors",
                index === activeTab
                  ? "bg-primary text-white"
                  : "bg-white hover:bg-primary15 text-primary"
              )}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div className={"mt-4"}>{tabs[activeTab].content()}</div>
      </div>
    )
  )
}

export default UserInfoTabs
