import React from "react"
import "./userProfileHeader.scss"
import avatarDefault from "../../../../../assets/img/user_avatar.svg"
import bgDefault from "../../../../../assets/img/bg-default.png"
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import * as routes from "../../../../../library/constants/routes"
import { userRolesIds } from "../../../../../library/constants/userRoles"
import UserProfileControls from "./userProfileControls/userProfileControls"
import ImagePickerModal from "../../../../../components/imagePickerModal/imagePickerModal"
import { imageAspectRatios } from "../../../../../library/constants/images"
import {
  composeUserHighlightedEducation,
  composeUserHighlightedWork,
} from "../../../../../library/utils/getUserHighlightedInfo"
import PlainLink from "../../../../../components/ui/link"
import LinkOutlinedIcon from "@material-ui/icons/LinkOutlined"
import { PlainButton } from "../../../../../components/v2/controls"
import {
  getUserBookingLink,
  getUserName,
} from "../../../../../library/utils/user/userUtils"
import { useIdParam } from "../../../../../hooks/useIdParam"
import { useUser } from "../../../../../hooks/data/user/useUser"
import UserAvatar from "../../../../../components/userAvatar"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { useAlert } from "../../../../../hooks/useAlert"
import UserInfoTabs from "./components/UserInfoTabs"
import { useToggle } from "../../../../../hooks/useToggle"
import { useUserInfoMutations } from "../../../../../hooks/data/user/useUserInfoMutations"
import { generatePath, Link } from "react-router-dom"
import {
  FRIENDS,
  GROUPS,
  KNOWCRED,
  USER,
} from "../../../../../library/constants/routes"
import { useSelector } from "react-redux"
import Button from "@material-ui/core/Button/Button"
import AuthorizationCTA from "../../../../../library/utils/AuthorizationCTA"

const UserProfileHeader = ({ isContentVisible }) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const { successAlert } = useAlert()
  const userId = useIdParam("userId")
  const { user, isCurrentUser, key } = useUser(userId)
  const { updateUserInfo } = useUserInfoMutations({ queryKey: key })

  const userAddress = React.useMemo(() => {
    return [user.city, user.state, user.country].filter(Boolean).join(", ")
  }, [user])

  const [isAvatarUpdateOpen, toggleAvatarUpdate] = useToggle(false)
  const [isBgUpdateOpen, toggleBgUpdate] = useToggle(false)

  return (
    <div className={"rounded-sm shadow-box bg-white"}>
      <div
        className="aspect-page-banner p-4 bg-gray-200 relative bg-no-repeat bg-cover bg-center"
        style={{
          backgroundImage: `url(${user.background_photo})` || undefined,
        }}
      >
        <div className="w-full flex justify-between items-center">
          <div className="flex items-center h-11 py-2 px-3 radius-sm text-primary font-medium bg-white/70 leading-none text-xl">
            <span>{userRolesIds[user.role].name || ""}</span>
          </div>
          {isLoggedIn && isCurrentUser && (
            <button
              className="flex items-center overflow-hidden py-2 pl-2 pr-1 radius-sm text-primary bg-white/70 leading-none group hover:pr-2 duration-200 transition-all"
              onClick={toggleBgUpdate}
            >
              <CameraAltOutlined />
              <span className="w-0 ml-1 opacity-0 nowrap group-hover:w-full group-hover:opacity-100">
                Edit background
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="min-h-40 p-4 bg-white relative">
        <div className={"absolute left-4 translate-y-[calc(-50%_-_1rem)]"}>
          <div className="rounded-full h-41 w-41 overflow-hidden bg-grey7 border-2 border-white relative group">
            <UserAvatar user={user} photo={user.photo} size={"xl"} />
            {isLoggedIn && isCurrentUser && (
              <button
                className="absolute w-full bottom-0 left-0 hidden flex-col items-center justify-center h-1/2 transition-all duration-200 bg-primary/70 text-white text-sm group-hover:flex"
                onClick={toggleAvatarUpdate}
              >
                <span className="d-none sm:d-block mb-1">Edit your photo</span>
                <CameraAltOutlined />
              </button>
            )}
          </div>
        </div>
        {isLoggedIn && isCurrentUser && (
          <div className="absolute top-4 right-4">
            <PlainLink to={routes.PROFILE_EDIT} className={"mb-2"}>
              <EditOutlined />
              <span className={"ml-1"}>Edit my profile</span>
            </PlainLink>
          </div>
        )}
        <div className={"mt-20"}>
          <div className={"flex gap-x-2 mb-4"}>
            <h3 className={"text-2xl font-medium"}>{getUserName(user)}</h3>
            {isLoggedIn && isCurrentUser && (
              <PlainButton
                onClick={async () => {
                  await navigator.clipboard.writeText(
                    getUserBookingLink(user.id)
                  )
                  successAlert("Data copied to clipboard")
                }}
              >
                <LinkOutlinedIcon />
                <span>My booking link</span>
              </PlainButton>
            )}
          </div>
          <div className={"flex justify-between"}>
            <div className={"space-y-2 flex-1"}>
              {!!user.highlighted && (
                <p className={""}>
                  {user.highlighted === 1 &&
                    !!user.work &&
                    composeUserHighlightedWork(user.work)}
                  {user.highlighted === 2 &&
                    !!user.education &&
                    composeUserHighlightedEducation(user.education)}
                </p>
              )}
              {!!userAddress.length && (
                <p className="text-black54">{userAddress}</p>
              )}

              <UserInfoTabs />
            </div>
            <div className={"space-y-2"}>
              <div className={"flex justify-end items-baseline"}>
                <span className={"text-primary font-medium mr-4"}>
                  {user.friends_number}
                </span>
                {isLoggedIn ? (
                  <PlainLink
                    to={generatePath(FRIENDS, { userId: user.id })}
                    className={"text-primary group-hover:underline"}
                  >
                    Friends
                  </PlainLink>
                ) : (
                  <span>Friends</span>
                )}
              </div>
              <div className={"flex justify-end items-baseline"}>
                <span className={"text-primary font-medium mr-4"}>
                  {user.knowcreds_number}
                </span>
                {isLoggedIn ? (
                  <PlainLink to={generatePath(KNOWCRED, { userId: user.id })}>
                    Creds
                  </PlainLink>
                ) : (
                  <span>Creds</span>
                )}
              </div>
              <div className={"flex justify-end items-baseline"}>
                <span className={"text-primary font-medium mr-4"}>
                  {user.groups_number}
                </span>
                {isLoggedIn && isCurrentUser ? (
                  <PlainLink to={generatePath(GROUPS, { userId: user.id })}>
                    Groups
                  </PlainLink>
                ) : (
                  <span>Groups</span>
                )}
              </div>
            </div>
          </div>
          {isLoggedIn ? (
            !isCurrentUser && (
              <div className={"mt-8"}>
                <UserProfileControls />
              </div>
            )
          ) : (
            <div className={"mt-8"}>
              <AuthorizationCTA
                redirectTo={generatePath(USER, { userId: user.id })}
                component={({ toggle }) => (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={toggle}
                  >
                    Add to friends
                  </Button>
                )}
              />
            </div>
          )}
        </div>
      </div>

      {isAvatarUpdateOpen && (
        <ImagePickerModal
          // dimensions={{width: 50, x: 20, y: 20}}
          originImage={user.full_image.original_image || avatarDefault}
          defaultImage={avatarDefault}
          open={isAvatarUpdateOpen}
          onSave={(full_image) => updateUserInfo.mutate({ full_image })}
          onClose={toggleAvatarUpdate}
          aspectRatio={imageAspectRatios.square}
          isCircle
        />
      )}

      {isBgUpdateOpen && (
        <ImagePickerModal
          // dimensions={{width: 50, x: 20, y: 20}}
          originImage={user.background_photo || bgDefault}
          defaultImage={bgDefault}
          open={isBgUpdateOpen}
          onSave={(full_header) => updateUserInfo.mutate({ full_header })}
          onClose={toggleBgUpdate}
          aspectRatio={imageAspectRatios.page_banner}
        />
      )}
    </div>
  )
}

export default UserProfileHeader
