import React from "react"

const AuthFormLayout = ({ title, description, children }) => {
  return (
    <div
      className={
        "max-w-[90%] sm:max-w-100 bg-white rounded-xl overflow-hidden mx-auto mt-16 mb-6"
      }
    >
      <div className="py-8 px-6 text-center bg-primary">
        <h1 className={"text-white font-normal text-4xl leading-none"}>
          {title}
        </h1>
        {!!description && (
          <p className={"text-center text-white mt-2"}>{description}</p>
        )}
      </div>
      <div className="flex flex-col items-center p-8">{children}</div>
    </div>
  )
}

export default AuthFormLayout
