import React, { useState } from "react"
import { Dialog, DialogActions, DialogContent } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl/FormControl"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Button from "@material-ui/core/Button/Button"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { groupMemberTags } from "../../../../../../../library/constants/userRoles"
import { useGroupMemberMutations } from "../../../../../../../hooks/data/groups/groups"

const tags = (user) => {
  if (!user.tags) return []

  return groupMemberTags.map((tag) => ({
    ...tag,
    selected: user.tags.includes(tag.id),
  }))
}

const MemberOccupationsModal = ({ user, queryKey, open, handleClose }) => {
  console.log(user)
  const groupId = useIdParam("groupId")
  const { errorAlert, successAlert } = useAlert()

  const { member } = useGroupMemberMutations({ queryKey })

  const [selectedTags, setSelectedTags] = useState(tags(user))
  const [followerOnly, setFollowerOnly] = useState(
    user.tags.length === 1 && user.tags[0] === 1
  )

  const handleChange = (event) => {
    event.persist()

    if (parseInt(event.target.value) === 1) {
      setFollowerOnly(event.target.checked)

      return setSelectedTags((prevState) => {
        return prevState.map((tag) => ({
          ...tag,
          selected:
            parseInt(event.target.value) === tag.id
              ? event.target.checked
              : event.target.checked
                ? false
                : tag.selected,
        }))
      })
    }

    setSelectedTags((prevState) => {
      return prevState.map((tag) =>
        tag.id === parseInt(event.target.value)
          ? {
              ...tag,
              selected: event.target.checked,
            }
          : tag
      )
    })
  }

  const processTagsSave = async () => {
    try {
      await member.changeOccupations.mutateAsync({
        user_id: user.id,
        group_id: groupId,
        tags: selectedTags.filter((tag) => tag.selected).map((tag) => tag.id),
      })
      successAlert("Occupations changed")
      handleClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="member-occupation-dialog"
      onClose={handleClose}
      open={open}
      maxWidth="md"
    >
      <DialogContent>
        <div className="group-create">
          <div className="text-center f fdc aic">
            <h3 className="group-create__title">Member occupation</h3>
            <p className={"color-black-54"}>
              Member can either be only a follower or all of other options.
              <br />
              If "Follower" option is checked, you must uncheck it and check any
              other option you need
            </p>
          </div>
          <FormControl component="fieldset" id="group-member-tags">
            {selectedTags.map((tag) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={tag.selected}
                      onChange={handleChange}
                      value={tag.id}
                      disabled={tag.id !== 1 && followerOnly}
                    />
                  }
                  label={
                    <span
                      className="break-word"
                      style={{ maxWidth: "175px", display: "block" }}
                    >
                      {tag.name}
                    </span>
                  }
                  key={tag.id}
                />
              )
            })}
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          variant="text"
          color="primary"
          onClick={processTagsSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemberOccupationsModal
