import { enUS } from "date-fns/esm/locale"
import { Calendar, dateFnsLocalizer } from "react-big-calendar"
import { format, getDay, parse, startOfWeek } from "date-fns"
import { colors } from "../../../library/constants/styles/colors"
import { views } from "react-big-calendar/lib/utils/constants"
import CalendarToolbar, {
  AgendaEvent,
  CalendarDayEventWrapper,
  CalendarDayWrapper,
  CalendarEventWrapper,
  CalendarWeekEventWrapper,
} from "../../../screens/main/calendar/components/eventsCalendar/components/calendarUI"
import React from "react"

const locales = {
  "en-US": enUS,
}

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

const formats = {
  dateFormat: (date, culture, localizer) => {
    return localizer.format(date, date.getDate() === 1 ? "MMM d" : "d", culture)
  },
}

const eventStyleGetter = (event, start, end, isSelected) => {
  const backgroundColor = "#" + event.color
  const style = {
    padding: "0 4px",
    lineHeight: 1.38,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "13px",
    backgroundColor: event.color || colors.black12,
    borderRadius: "2px",
    color: colors.black87,
    border: 0,
    overflow: "hidden",
  }
  return {
    style: style,
  }
}

const KSCalendar = ({
  date,
  onNavigate,
  eventsList,
  viewsList = [views.MONTH],
  defaultView = views.MONTH,
  height = 850,
  style,
  getToolbar,
  getMonthEvent,
  onSelectEvent,
  onSelectSlot,
  noFilters = false,
  disabled = false,
  isFullScreen = false,
  onClose,
  isUpdating,
}) => {
  return (
    <Calendar
      selectable={!disabled}
      date={date}
      onNavigate={onNavigate}
      localizer={localizer}
      events={eventsList}
      views={viewsList}
      defaultView={defaultView}
      style={style ? style : { height }}
      components={{
        toolbar: getToolbar
          ? (props) => getToolbar(props)
          : (props) => (
              <CalendarToolbar
                {...props}
                noFilters={noFilters}
                isFullScreen={isFullScreen}
                onClose={onClose}
                isUpdating={isUpdating}
              />
            ),
        month: {
          eventWrapper: getMonthEvent
            ? (props) => getMonthEvent(props)
            : CalendarEventWrapper,
          dateCellWrapper: CalendarDayWrapper,
        },
        week: {
          eventWrapper: CalendarWeekEventWrapper,
        },
        day: {
          eventWrapper: CalendarDayEventWrapper,
        },
        agenda: {
          event: AgendaEvent,
        },
      }}
      eventPropGetter={eventStyleGetter}
      formats={formats}
      onSelectEvent={(event) => onSelectEvent(event)}
      onSelectSlot={({ start, end }) => onSelectSlot({ start, end })}
      popup
      timeslots={6}
      step={10}
    />
  )
}

export default KSCalendar
