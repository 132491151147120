import React from "react"
import {
  setInviterGroup,
  setNewFriend,
} from "../../../library/store/actions/creators/authCreators"
import { useDispatch } from "react-redux"
import { useSearchQuery } from "../../../hooks/useSearchQuery"

const SignUpContext = React.createContext({})
export const useSignUpContext = () => React.useContext(SignUpContext)

const SignUpProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [data, setData] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    age_consent: "true",
    role: "1",
    country: "",
    state: "",
    city: "",
    work: "",
    education: {
      type: "",
      name: "",
    },
  })

  const [activeStep, setActiveStep] = React.useState(0)

  const navigateBack = (values) => {
    setData({ ...data, ...values })
    setActiveStep(activeStep - 1)
  }

  const navigateNext = (values) => {
    setData({ ...data, ...values })
    setActiveStep(activeStep + 1)
  }

  const {
    fields: { newFriendId, inviterGroupId },
  } = useSearchQuery()

  React.useEffect(() => {
    if (newFriendId) dispatch(setNewFriend(Number(newFriendId)))
    if (inviterGroupId) dispatch(setInviterGroup(Number(inviterGroupId)))
  }, [])

  return (
    <SignUpContext.Provider
      value={{
        data,
        setData,
        activeStep,
        setActiveStep,
        navigateNext,
        navigateBack,
      }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

export default SignUpProvider
