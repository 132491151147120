import React from "react"
import styled from "@emotion/styled"
import { colors } from "../../../../library/constants/styles/colors"
import { Link } from "react-router-dom"
import LineSlice from "../../../ui/lineSlice"
import { mq } from "../../../../theme/globalStyle"
import Button from "@material-ui/core/Button/Button"
import { PlainButton } from "../../controls"
import isPropValid from "@emotion/is-prop-valid"
import MenuList from "@material-ui/core/MenuList/MenuList"
import DropdownControl from "../../../ui/dropdowns/dropdownControl"
import { withStyles } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem/MenuItem"
import withErrorBoundary from "../../../../hocs/guards/withErrorBoundary"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import Menu from "../../controls/menu/menu"
import { CARD_SIZES } from "../../../../library/constants/styles/cardSizes"

const Container = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  position: relative;
  display: flex;
  align-items: stretch;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 2px;
  background-color: ${colors.white};
  box-shadow: 0 0 2px 0 rgba(210, 210, 210, 0.5);
  color: ${colors.black54};
  height: ${(props) => (props.height ? props.height + "px" : "auto")};

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr max-content;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  ${mq.xs} {
    grid-template-columns: 1fr;
  }
`

const CardContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const ImgContainer = styled(Link, {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: ${(props) => (props.small ? "63px" : "90px")};
  min-width: ${(props) => (props.small ? "63px" : "90px")};
  height: ${(props) => (props.small ? "63px" : "90px")};
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
`

const StaticImgContainer = styled.div`
  width: ${(props) => (props.small ? "63px" : "90px")};
  min-width: ${(props) => (props.small ? "63px" : "90px")};
  height: ${(props) => (props.small ? "63px" : "90px")};
  margin-right: 15px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #fff;
`

const Img = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
`

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  word-break: break-word;
`

const Name = styled.h3`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  display: block;
  width: 350px;
  font-weight: 500;
  font-size: 1rem;

  transition: all 0.2s;
  color: ${colors.primary};
  text-align: left;
  cursor: ${(props) => (props.staticText ? "auto" : "pointer")};

  &:hover {
    text-decoration: ${(props) => (props.staticText ? "none" : "underline")};
  }

  ${mq.xs} {
    width: 175px;
  }
`

const Stats = styled.div`
  width: 350px;

  ${mq.xs} {
    width: 175px;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`
const ControlPanel = styled.div`
  display: flex;
  position: relative;
  top: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  min-width: 200px;
  align-items: stretch;
  margin-left: 10px;
  height: 100%;

  @supports (display: grid) {
    min-width: 24px;
  }

  ${mq.xs} {
    position: relative;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    margin: 0;
  }

  .btn-group {
    width: 100%;
  }

  .more-dropdown-menu {
    position: absolute;
    align-self: flex-end;
    margin-bottom: 15px;
    top: 0;
    right: 0;
    //z-index: 100;

    ${mq.xs} {
      align-self: auto;
      margin-bottom: 0;
      position: relative;
    }
  }
`

const CustomMenuItem = withStyles({
  root: {
    color: colors.primary,
  },
})(MenuItem)

const Card = ({
  name,
  onNameClick,
  photo,
  isPhotoSmall = false,
  link = "",
  stats,
  info,
  infoComponent,
  controls,
  size,
  textAboveName = "",
}) => {
  const hasButton =
    !!controls?.button?.getComponent ||
    (!!controls?.button?.label && !!controls?.button?.action)
  const hasDropdownMenu = !!controls?.dropdownMenu?.items?.length
  const hasDropdownButton =
    !!controls?.dropdownButton?.buttonLabel &&
    !!controls?.dropdownButton?.items?.length
  const hasCustomControls = typeof controls?.getControls === "function"

  const composeControls = () => {}

  return (
    <Container height={size}>
      <CardContent>
        {!!photo && (
          <>
            {!!link ? (
              <ImgContainer to={link} small={isPhotoSmall}>
                <Img src={photo} alt={name} aria-label={"Avatar"} />
              </ImgContainer>
            ) : (
              <StaticImgContainer small={isPhotoSmall}>
                <Img src={photo} alt={name} aria-label={"Avatar"} />
              </StaticImgContainer>
            )}
          </>
        )}
        <CardInfoContainer>
          {!!textAboveName && (
            <span className={"text-xs"}>{textAboveName}</span>
          )}
          {link ? (
            <Link to={link}>
              <Name>{name}</Name>
            </Link>
          ) : onNameClick ? (
            <PlainButton onClick={onNameClick} bold>
              <Name>{name}</Name>
            </PlainButton>
          ) : (
            <Name staticText={true}>{name}</Name>
          )}
          {!!stats && <Stats>{stats}</Stats>}

          {!!info && (
            <div className="break-word">
              <LineSlice text={info} />
            </div>
          )}

          {!!infoComponent && <div className={"mt10"}>{infoComponent()}</div>}
        </CardInfoContainer>
        <ControlPanel>
          {hasButton && (
            <>
              {controls.button?.getComponent ? (
                controls?.button?.getComponent()
              ) : (
                <Button
                  variant={"contained"}
                  color={"primary"}
                  onClick={controls.button.action}
                  disabled={
                    controls.button.isLoading ||
                    controls.button.disabled ||
                    false
                  }
                  startIcon={
                    controls.button.isLoading ? (
                      <CircularProgress size={18} color={"inherit"} />
                    ) : null
                  }
                >
                  {controls.button.label}
                </Button>
              )}
            </>
          )}

          {hasDropdownButton && (
            <DropdownControl
              buttonLabel={controls?.dropdownButton?.buttonLabel}
            >
              <MenuList>
                {controls?.dropdownButton?.items.map((item) => (
                  <CustomMenuItem onClick={item.action} key={item.label}>
                    {item.label}
                  </CustomMenuItem>
                ))}
              </MenuList>
            </DropdownControl>
          )}

          {hasDropdownMenu && <Menu items={controls.dropdownMenu.items} />}

          {hasCustomControls && controls.getControls()}
        </ControlPanel>
      </CardContent>
    </Container>
  )
}

export default withErrorBoundary(Card)
