import React from "react"
import BoxPage from "../../../../../../components/v2/layout/boxPage/boxPage"
import SchoolCalendarPicker from "../../../../../../components/v2/inputs/pickers/schoolCalendarPicker"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import SegmentPicker from "../../../../../../components/v2/inputs/pickers/segmentPicker"
import SectionPicker from "../../../../../../components/v2/inputs/pickers/sectionPicker"
import { calendarGrades } from "../../../../../../library/constants/educationalDictionary"
import GradePicker from "../../../../../../components/v2/inputs/pickers/gradePicker"
import { useToggle } from "../../../../../../hooks/useToggle"
import TeacherScheduleAssignmentFormModal from "./components/TeacherScheduleAssignmentFormModal"
import ScheduleAssignmentTable from "./components/ScheduleAssignmentTable"
import { useScheduleAssignment } from "./useScheduleAssignment"
import { weekDays } from "../../../../../../library/utils/timeConvert"
import { rotations } from "../../../../../../library/constants/groupTypes"
import { getObjectById } from "../../../../../../library/utils/arrays"
import UserLink from "../../../../../../components/v2/utils/UserLink"
import { safeGetEntity } from "../../../../../../library/utils/entities"

const SchoolScheduleTeachers = () => {
  const groupId = useIdParam("groupId")
  const today = new Date().getDay()

  const {
    filters,
    onFilterChange,
    filterErrors,
    blocksQuery: { blocks, isBlocksSuccess, blocksQueryKey, refetchBlocks },
    tracksQuery: { tracks, isTracksSuccess, refetchTracks },
    sectionRotations,
    columns,
    activeDay,
    setActiveDay,
  } = useScheduleAssignment({ type: "teacher" })

  const rows = React.useMemo(() => {
    return isBlocksSuccess && isTracksSuccess
      ? tracks.map((t, i) => {
          return [
            t.name,
            ...blocks.map((b) => ({
              ...b,
              track: b.tracks.find((bt) => bt.id === t.id),
              enabled: !!b.tracks.find((bt) => bt.id === t.id),
            })),
          ]
        })
      : []
  }, [blocks, tracks])

  const [isAssignationModalOpen, toggleAssignationModal] = useToggle(false)
  const [activeCell, setActiveCell] = React.useState({
    block: null,
    track: null,
  })

  const initAssignation = ({ block, track }) => {
    setActiveCell({ block, track })
    toggleAssignationModal()
  }

  const onAssignationModalClose = () => {
    toggleAssignationModal()
    setActiveCell({ block: null, track: null })
  }

  return (
    <BoxPage title={"Teacher schedule"}>
      <div className={"mb20"}>
        <SchoolCalendarPicker
          groupId={groupId}
          value={filters.schedule}
          hasError={filterErrors.schedule}
          onChange={onFilterChange("schedule")}
        />
        <GradePicker
          grades={calendarGrades}
          value={filters.grade}
          onChange={onFilterChange("grade")}
        />
        <SegmentPicker
          onChange={onFilterChange("segment")}
          hasError={filterErrors.segment}
          value={filters.segment}
          scheduleId={filters.schedule?.id}
          grade={filters.grade?.id}
          isWaitingForOtherFieldsMessage={"First select schedule"}
        />
        <SectionPicker
          onChange={onFilterChange("section")}
          hasError={filterErrors.section}
          value={filters.section}
          segmentId={filters.segment?.id}
        />
      </div>

      <div>
        {!!filters.section && (
          <div className="box">
            <nav className="h-tabs">
              <ul className="h-tabs__nav-list">
                {Object.entries(weekDays).map(([key, value], tabIdx) => {
                  return (
                    <li
                      key={value}
                      className={`relative text-center h-tabs__item ${
                        activeDay === key ? "h-tabs__item--active" : ""
                      }`}
                      onClick={() => setActiveDay(key)}
                    >
                      <p className="h-tabs__link border-b-0 leading-none">
                        {value}
                      </p>
                      <p className={"text-xs text-black38 leading-none"}>
                        {rotations[sectionRotations[tabIdx]]}
                      </p>
                      {Number(key) === today && (
                        <div
                          className={
                            "absolute top-0 right-0 w-1 h-1 rounded-full bg-primary"
                          }
                        />
                      )}
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        )}
        {isBlocksSuccess && isTracksSuccess && !!filters.schedule && (
          <>
            {!!blocks.length ? (
              <ScheduleAssignmentTable
                rows={rows}
                columns={columns}
                dataReceived={true}
                onCellClick={initAssignation}
                getCellContent={(cell, cellIdx) => {
                  if (
                    cell?.track?.slot?.teachers.length ||
                    cell?.track?.slot?.subject?.id
                  ) {
                    const prioritizedTeachers = [
                      ...cell?.track?.slot?.teachers,
                    ].sort((a, b) => a.priority - b.priority)
                    const subject = safeGetEntity(cell?.track?.slot?.subject)
                    return (
                      <>
                        {!!subject && (
                          <p className={"text-sm mb-1"}>
                            {getObjectById(calendarGrades, subject.grade).name}{" "}
                            grade, {subject.name}
                          </p>
                        )}
                        {prioritizedTeachers.length && (
                          <div className={"space-y-0.5"}>
                            {prioritizedTeachers.map((t) => (
                              <UserLink
                                user={t}
                                linkClassName={"!leading-none"}
                                key={t.id || t.user_id}
                              />
                            ))}
                          </div>
                        )}
                      </>
                    )
                  } else {
                    return <p className={"text-center"}>—</p>
                  }
                }}
              />
            ) : (
              <p className={"pt15 text-center text-black54"}>
                Blocks not found for this day
              </p>
            )}
          </>
        )}
      </div>
      {isAssignationModalOpen && (
        <TeacherScheduleAssignmentFormModal
          open={isAssignationModalOpen}
          onClose={onAssignationModalClose}
          activeCell={activeCell}
          schoolSchedule={filters.schedule}
          blocksQueryKey={blocksQueryKey}
          type={"teacher"}
        />
      )}
    </BoxPage>
  )
}

export default SchoolScheduleTeachers
