import React, { useState } from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Button from "@material-ui/core/Button/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { useGroupMemberMutations } from "../../../../../../../hooks/data/groups/groups"
import { useIdParam } from "../../../../../../../hooks/useIdParam"

const GroupMemberRoleModal = ({ user, queryKey, onClose, open }) => {
  const groupId = useIdParam("groupId")
  const { errorAlert, successAlert } = useAlert()
  const [role, setRole] = useState(user?.group_role?.toString() || "")

  const { member } = useGroupMemberMutations({ queryKey })

  const onSubmit = async () => {
    try {
      await member.changeRole.mutateAsync({
        group_id: groupId,
        user_id: user.id,
        role: parseInt(role),
      })
      successAlert("Role changed")
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      aria-labelledby="create-group-dialog"
      onClose={onClose}
      open={open}
      maxWidth="md"
    >
      <DialogContent>
        <div className="group-create">
          <div className="text-center">
            <h3 className="group-create__title">Group role</h3>
          </div>
          <FormControl component="fieldset" id="group-type">
            <RadioGroup
              aria-label="group-type"
              name="groupType"
              value={role.toString()}
              onChange={(e) => setRole(e.target.value)}
            >
              <FormControlLabel
                value="1"
                control={<Radio color="primary" />}
                label={
                  <div className="radio__label max-width-360 mb20">
                    <div className="radio__title font-weight-500">Member</div>
                    <div className="radio__description">
                      Can view all the information in the group, add and delete
                      their information.
                    </div>
                  </div>
                }
                labelPlacement="end"
              />
              <FormControlLabel
                value="2"
                control={<Radio color="primary" />}
                label={
                  <div className="radio__label max-width-360">
                    <div className="radio__title font-weight-500">
                      Administrator
                    </div>
                    <div className="radio__description">
                      Can add, edit, delete all information in the group. Has
                      access to all group settings.
                    </div>
                  </div>
                }
                labelPlacement="end"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button type="submit" variant="text" color="primary" onClick={onSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default GroupMemberRoleModal
