import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"

const TrackingOutgoingAiAppointmentsByCoursePage = () => {
  return (
    <BoxPage title={"Outgoing AI Meets By Course"}>
      TrackingOutgoingAiAppointmentsByCoursePage
    </BoxPage>
  )
}

export default TrackingOutgoingAiAppointmentsByCoursePage
