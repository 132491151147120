import React from "react"
import { Form, Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useAlert } from "../../../../hooks/useAlert"
import { useHistory } from "react-router-dom"
import * as routes from "../../../../library/constants/routes"
import * as yup from "yup"
import TextInput from "../../../../components/v2/inputs/textInput/textInput"
import { countries, states } from "../../../../library/constants/states"
import Select from "../../../../components/v2/inputs/select/select"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import FormControl from "@material-ui/core/FormControl/FormControl"
import { useSignUpContext } from "../SignUpProvider"
import { educationTypes } from "../../../../library/constants/educationalDictionary"

const roles = [
  {
    id: 1,
    name: "Learner",
    description: "I am currently a student or parent of one.",
  },
  {
    id: 2,
    name: "Educator",
    description:
      "I am en educator, administrator, tutor or other school representative as well as learner. I may use Calendar, create Knowmeets, Courses, Creds, and publish paid or free products for my group or the global market.",
  },
  // {id: 3, name: 'Admin', description: 'I work in an organization.'}
  {
    id: 4,
    name: "Company Representative",
    description:
      'I am with a company or work as a free representative that may create both my own profile and a company group or interest group, post products to the global market, use the "Schedule Demo" function, make Courses for training, post news and find new friends.',
  },
]

const validationSchema = yup.object({
  role: yup.string(),
  work: yup.string().max(150),
  education: yup.object({
    type: yup.string().when("role", {
      is: "1",
      then: yup.string().required(),
      otherwise: yup.string(),
    }),
    name: yup.string().when("role", {
      is: "1",
      then: yup.string().max(150).required(),
      otherwise: yup.string().max(150),
    }),
  }),
})

const SignUpStep2 = () => {
  const { errorAlert } = useAlert()
  const { data, navigateNext, navigateBack } = useSignUpContext()

  const initialValues = {
    role: String(data.role),
    work: data.organization,
    education: data.education,
  }

  const onSubmit = async (values) => {
    try {
      navigateNext(values)
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
    >
      {(fk) => {
        const organizationType =
          fk.values.role !== "1" ? "Organization" : "Education"
        const organizationFieldName =
          fk.values.role !== "1" ? "work" : "education"

        return (
          <Form className={"w-full"}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="role"
                name="role"
                value={fk.values.role}
                onChange={fk.handleChange("role")}
              >
                {roles.map((role) => (
                  <>
                    <FormControlLabel
                      className="mb-4"
                      value={String(role.id)}
                      control={<Radio />}
                      disabled={data.age_consent !== "true" && role.id !== 1}
                      label={
                        <div>
                          <h4 className="font-medium text-lg">{role.name}</h4>
                          <p className="">{role.description}</p>
                        </div>
                      }
                    />
                  </>
                ))}
              </RadioGroup>
            </FormControl>
            <p className={"mt-6"}>
              {organizationType}{" "}
              <span className={"text-black54"}>
                (if you are associated with any)
              </span>
            </p>
            {fk.values.role === "1" ? (
              <>
                <Select
                  name={"education.type"}
                  placeholder={"Select type"}
                  options={Object.entries(educationTypes).map(
                    ([key, value]) => ({
                      value: key,
                      label: value,
                    })
                  )}
                  renderedValue={(v) => educationTypes[v]}
                />
                <TextInput name={"education.name"} label={"Name"} />
              </>
            ) : (
              <TextInput name={"work"} label={"Organization"} />
            )}

            <div className="flex gap-4 mt-6">
              <Button
                color="primary"
                variant="outlined"
                type="button"
                onClick={() => navigateBack(fk.values)}
              >
                Back
              </Button>
              <Button color="primary" variant="contained" type="submit">
                Next
              </Button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SignUpStep2
