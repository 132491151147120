import React from "react"
import { useField } from "formik"
import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
} from "@material-ui/core"

const Select = ({
  options,
  renderedValue,
  displayEmpty = true,
  disabled = false,
  ...rest
}) => {
  const [field, meta] = useField(rest)

  return (
    <FormControl error={Boolean(meta.error)} fullWidth>
      <MuiSelect
        id={field.name}
        fullWidth
        displayEmpty={displayEmpty}
        renderValue={(value) => {
          if (!value || value.length === 0) {
            return (
              <span className="color-black-38">
                {!options?.[0].value ? options?.[0].label : "Select one option"}
              </span>
            )
          }

          return renderedValue ? renderedValue(value) : value
        }}
        disabled={disabled}
        {...field}
        {...rest}
      >
        {options.map((option, idx) => {
          if (!option.value) {
            return (
              <MenuItem value={""} key={option.label + idx}>
                <span className="color-black-38">{option.label}</span>
              </MenuItem>
            )
          }

          return (
            <MenuItem value={option.value} key={option.label + idx}>
              {option.label}
            </MenuItem>
          )
        })}
      </MuiSelect>
      {Boolean(meta.error) && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

Select.Item = ({ value = "", children }) => (
  <MenuItem value={value}>
    <span className="color-black-38">{children}</span>
  </MenuItem>
)

export default Select
