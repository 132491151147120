import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { USER } from "../../../library/store/actions/types/authTypes"
import { useAlert } from "../../useAlert"
import { call } from "../../../library/networking/API"

export const useContactLinks = (options = {}) => {
  const { data, ...query } = useQuery({
    queryKey: [USER.CONTACT_LINKS_GET],
    staleTime: Infinity,
    ...options,
  })

  return { links: data?.contact_links, ...query }
}

export const useContactLinksMutation = () => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  return useMutation(
    async (links) => {
      try {
        await call(USER.CONTACT_LINKS_UPDATE, links)
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER.CONTACT_LINKS_GET])
      },
    }
  )
}
