import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import { useIdParam } from "../../../../../../../hooks/useIdParam"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import { useSlotMutations } from "../../../../../../../hooks/data/calendar/schedules"
import { useAlert } from "../../../../../../../hooks/useAlert"
import { getUserName } from "../../../../../../../library/utils/user/userUtils"
import UserPicker from "../../../../../../../components/v2/inputs/pickers/userPicker"
import {
  GROUPS,
  SCHOOLS,
} from "../../../../../../../library/store/actions/types/groupsTypes"
import { useQuery } from "@tanstack/react-query"
import StaticDataSelect from "../../../../../../../components/v2/inputs/select/staticDataSelect"
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { safeGetEntity } from "../../../../../../../library/utils/entities";

const LearnerScheduleAssignmentFormModal = ({
  open,
  onClose,
  activeCell,
  blocksQueryKey,
  activeLearner,
  activeGrade,
  type,
}) => {
  console.log({ activeCell })
  const { errorAlert } = useAlert()
  const groupId = useIdParam("groupId")
  const currentSlot = activeCell.track.slot

  const [selectedLearners, setSelectedLearners] = React.useState(
    currentSlot?.students?.length ? currentSlot.students.map((t) => ({
        ...t,
      id: t.user_id,
        space: safeGetEntity(t.space)
      }))
      : []
  )

  const { data: spacesData, isSuccess: isSpacesSuccess } = useQuery({
    queryKey: [
      GROUPS.SPACES.LIST,
      { school_id: groupId, offset: 0, limit: 100 },
    ],
    enabled: !!groupId,
    staleTime: 1000 * 60 * 2,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })

  const [space, setSpace] = React.useState("")

  const { updateSlot } = useSlotMutations(blocksQueryKey, false)

  const onSubmit = async () => {
    try {
      await updateSlot.mutateAsync({
        id: currentSlot.id,
        students: selectedLearners.map((s) => ({
          user_id: s.id,
          space_id: s.space.id,
        })),
      })
      onClose()
    } catch (e) {
      console.log(e)
      errorAlert(e)
    }
  }



  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={"text-center"}>
        {!(currentSlot?.teachers?.length && currentSlot?.subject_id)
          ? "Assign"
          : "Reassign"}{" "}
        Block
      </DialogTitle>
      <DialogContent>
        <div className="form form--modal">
          <UserPicker
            value={selectedLearners}
            onChange={setSelectedLearners}
            userLabel={"learner"}
            multiple={!!activeCell}
            requestConfig={{
              fetchConfig: {
                apiUrl: SCHOOLS.STUDENTS.LIST,
                params: {
                  group_id: groupId,
                  grade: activeGrade?.id,
                },
              },
              key: "users",
            }}
          />
          {!!selectedLearners.length && isSpacesSuccess && (
            <div className="form__fieldset">
              <span className={"form__label"}>Spaces</span>
              <div className="form__fieldbox pt-2.5">
                {selectedLearners.map((l, i) => (
                  <div className="form__input mb-2 last:mb-0">
                    <div className={"flex items-baseline gap-2"}>
                      <p className={"flex-1"}>{getUserName(l)}: </p>
                      <div className={"flex-1"}>
                        <Select
                          id={`l-${l.id}-space`}
                          name={'space'}
                          displayEmpty
                          value={l.space}
                          fullWidth
                          onChange={(e) =>
                            setSelectedLearners((prev) => {
                              return prev.map((learner) => {
                                return l.id === learner.id
                                  ? { ...learner, space: e.target.value }
                                  : learner
                              })
                            })
                          }
                        >
                          <MenuItem value={""} className={'text-black54'}>Select space</MenuItem>
                          {spacesData?.spaces?.map((s) => (
                            <MenuItem value={s}>{s.name}</MenuItem>
                          ))}
                        </Select>
                        {/*<StaticDataSelect*/}
                        {/*  fieldName={"space"}*/}
                        {/*  entityName={"space"}*/}
                        {/*  value={l.space}*/}
                        {/*  onChange={(s) =>*/}
                        {/*    setSpace((prev) => {*/}
                        {/*      return prev.map((learner) => {*/}
                        {/*        return l.id === learner.id*/}
                        {/*          ? { ...learner, space: s }*/}
                        {/*          : learner*/}
                        {/*      })*/}
                        {/*    })*/}
                        {/*  }*/}
                        {/*  data={spacesData?.spaces || []}*/}
                        {/*  leftLabel={false}*/}
                        {/*/>*/}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Assign
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LearnerScheduleAssignmentFormModal
