import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import ProfileTimezoneForm from "./components/ProfileTimezoneForm"
import CalendarConnect from "./components/CalendarConnect"
import ConferenceLinks from "./components/ConferenceLinks"
import AppointmentTypes from "./components/AppointmentTypes"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import Loader from "../../../../../components/ui/loader"

const ProfileSettingsCalendar = () => {
  const { isSuccess } = useCurrentUser()

  return isSuccess ? (
    <BoxPage title={"Calendar"}>
      <CalendarConnect />
      <hr className={"border-border my-2"} />
      <ProfileTimezoneForm />
      <hr className={"border-border my-2"} />
      <ConferenceLinks />
      <hr className={"border-border my-2"} />
      <AppointmentTypes />
    </BoxPage>
  ) : (
    <Loader />
  )
}

export default ProfileSettingsCalendar
