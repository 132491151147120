import React from "react"
import { twc } from "react-twc"
import { useTableLayout } from "../../../hooks/useTable"
import { useElementWidth } from "../../../hooks/useElementWidth"
import Stub from "../../stub"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import EditOutlinedIcon from "@material-ui/icons/EditOutlined"
import { TablePagination, Tooltip } from "@material-ui/core"
import { SearchOutlined } from "@material-ui/icons"
import Loader from "../../ui/loader"
import { useSuperTable } from "../../../hooks/useSuperTable"
import { PlainButton } from "../controls"
import KeyboardArrowDownOutlined from "@material-ui/icons/KeyboardArrowDownOutlined"
import KeyboardArrowUpOutlined from "@material-ui/icons/KeyboardArrowUpOutlined"

const DataTableRow = twc.div.attrs({ role: "row" })((props) => [
  `grid grid-cols-subgrid border-b border-gray-200`,
  !props.$isHeader ? "group" : "border-t",
  props.$isExpanded && "border-b-0",
])

const DataTableCell = twc.div.attrs({ role: "cell" })((props) => [
  "flex items-center py-2 px-1 min-h-11 max-h-18 text-sm transition-colors transition-200 first:border-l-2 first:border-l-transparent",
  props.$isFirstColumnPinned &&
    "first:border-r first:border-gray-200 first:sticky first:left-0 first:z-10",
  props.$isLastColumnPinned &&
    "last:border-l last:border-gray-200 last:sticky last:right-0 last:z-10",
  // props.isScrolled && "first:border-r-2",
  !props.$isHeaderCell && "group-hover:first:border-l-primary",
  props.$isRowExpanded && "first:!border-l-primary50 !bg-primary5",
])

export const MIN_TABLE_CELL_WIDTH = 160

const DataTable = ({
  columns,
  actions,
  onDetailsOpen,
  onEditOpen,
  isFirstColumnPinned = true,
  actionsPinned = true,
  customColumnsWidth = false,
  isSearchable = false,
  searchInputPlaceholder = "Search by name",
  notFoundText = "No records found",
  queryConfig = {
    noFetch: false,
    key: [null],
    fetchParams: {},
    listKey: null,
    url: null,
    queryFn: null,
  },
  getFilterComponent,
  isRowExpandable = false,
  getRowToggleComponent,
}) => {
  const { composeValue, composeActions } = useTableLayout()

  const tableRef = React.useRef(null)

  const {
    query,
    pagination,
    onPageChange,
    onRowsPerPageChange,
    search,
    rowsPerPageOptions,
  } = useSuperTable({ queryConfig })

  const [isTableScrolled, setIsTableScrolled] = React.useState(false)
  const [gridCols, setGridCols] = React.useState(
    `repeat(${columns.length}, 1fr)`
  )

  const containerId = "table-container"

  const containerWidth = useElementWidth(containerId)

  React.useEffect(() => {
    const table = tableRef.current

    const colFixedWidthSum = columns.reduce(
      (prev, cur) =>
        prev +
        (customColumnsWidth
          ? cur.width || MIN_TABLE_CELL_WIDTH
          : MIN_TABLE_CELL_WIDTH),
      0
    )

    const isFluid = colFixedWidthSum <= containerWidth

    if (customColumnsWidth) {
      const calculateGridCols = () => {
        return columns.reduce((prev, column) => {
          if (!isFluid) {
            return (
              prev +
              ` ${
                customColumnsWidth
                  ? column.width || MIN_TABLE_CELL_WIDTH
                  : MIN_TABLE_CELL_WIDTH
              }px`
            )
          } else {
            return prev + ` ${column.width ? column.width + "px" : "1fr"}`
          }
        }, "")
      }

      setGridCols(calculateGridCols())
    }

    const setScroll = (e) => {
      if (isFirstColumnPinned) {
        if (e.target.scrollLeft > 0) {
          setIsTableScrolled(true)
        } else {
          setIsTableScrolled(false)
        }
      }
    }

    if (containerWidth) {
      table.addEventListener("scroll", setScroll)
      return () => {
        table.removeEventListener("scroll", setScroll)
      }
    }
  }, [containerWidth, query.isSuccess])

  const canOpenDetails = typeof onDetailsOpen === "function"
  const canEditData = typeof onEditOpen === "function"
  const hasLeftControls = canOpenDetails || canEditData || isRowExpandable

  const [expandedRows, setExpandedRows] = React.useState([])
  const toggleExpandedRow = (id) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter((rowId) => rowId !== id))
    } else {
      setExpandedRows([...expandedRows, id])
    }
  }

  return (
    <div>
      {/*{dataReceived && !!data.length && (*/}
      <>
        {query.isSuccess && (
          <div className="flex justify-between items-end mb-4 w-full">
            <div>
              {isSearchable && (
                <div className="search-form search-form--compact">
                  <div className="search-form__icon">
                    <SearchOutlined />
                  </div>
                  <input
                    type="text"
                    id="search"
                    name="search"
                    placeholder={searchInputPlaceholder}
                    className="search-form__field flex-1"
                    autoComplete="off"
                    value={search.value}
                    onChange={(e) => search.setValue(e.target.value)}
                  />
                </div>
              )}
            </div>

            {typeof getFilterComponent === "function" && getFilterComponent()}
          </div>
        )}
        <div id={containerId}>
          {!query.isSuccess && <Loader />}

          {!!containerWidth && (
            <div
              role={"table"}
              className={"pb-4 overflow-x-auto grid"}
              ref={tableRef}
              style={{
                gridTemplateColumns: gridCols,
                width: containerWidth,
              }}
            >
              {query.isSuccess && (
                <>
                  <DataTableRow
                    $isHeader
                    style={{
                      gridColumn: `span ${columns.length}`,
                    }}
                  >
                    {columns.map((column, index) => (
                      <DataTableCell
                        key={index}
                        $isFirstColumnPinned={isFirstColumnPinned}
                        $isLastColumnPinned={actionsPinned}
                        $isScrolled={isTableScrolled}
                        $isHeaderCell
                        className={"bg-grey7"}
                      >
                        <div
                          className={
                            hasLeftControls && index === 0
                              ? "pl-[calc(2rem_+_1px)]"
                              : ""
                          }
                        >
                          {column.label}
                        </div>
                      </DataTableCell>
                    ))}
                  </DataTableRow>
                  {query?.data?.list.map((record, index) => {
                    const isExpanded = expandedRows.includes(record.id)
                    return (
                      <>
                        <DataTableRow
                          key={index}
                          style={{
                            gridColumn: `span ${columns.length}`,
                          }}
                          $isExpanded={isExpanded}
                        >
                          {record.info.map((recordValue, valueIdx) => (
                            <DataTableCell
                              key={valueIdx}
                              $isFirstColumnPinned={isFirstColumnPinned}
                              $isScrolled={isTableScrolled}
                              $isRowExpanded={isExpanded}
                              className={"bg-white"}
                            >
                              {hasLeftControls && valueIdx === 0 ? (
                                <div className={"flex items-center gap-1"}>
                                  <div
                                    className={
                                      "flex flex-col gap-2 items-center justify-center border-r pr-1 border-gray-200"
                                    }
                                  >
                                    {canOpenDetails && (
                                      <Tooltip title="Open details" arrow>
                                        <button
                                          className={"text-primary"}
                                          onClick={() => onDetailsOpen(record)}
                                        >
                                          <InfoOutlinedIcon />
                                        </button>
                                      </Tooltip>
                                    )}
                                    {canEditData && (
                                      <Tooltip title="Edit record" arrow>
                                        <button
                                          className={"text-primary"}
                                          onClick={() => onEditOpen(record)}
                                        >
                                          <EditOutlinedIcon />
                                        </button>
                                      </Tooltip>
                                    )}
                                    {isRowExpandable && (
                                      <Tooltip title="Expand record" arrow>
                                        <button
                                          className={"text-primary"}
                                          onClick={() =>
                                            toggleExpandedRow(record.id)
                                          }
                                        >
                                          {isExpanded ? (
                                            <KeyboardArrowUpOutlined />
                                          ) : (
                                            <KeyboardArrowDownOutlined />
                                          )}
                                        </button>
                                      </Tooltip>
                                    )}
                                  </div>
                                  <div className="flex-1">
                                    {composeValue(recordValue)}
                                  </div>
                                </div>
                              ) : (
                                composeValue(recordValue)
                              )}
                            </DataTableCell>
                          ))}
                          {typeof actions === "function" && (
                            <DataTableCell
                              $isFirstColumnPinned={isFirstColumnPinned}
                              $isLastColumnPinned={actionsPinned}
                              $isScrolled={isTableScrolled}
                              className={"bg-white"}
                              $isRowExpanded={isExpanded}
                            >
                              <div className="flex flex-wrap gap-1 items-center justify-end w-full text-primary">
                                {typeof actions === "function" &&
                                  actions(record.raw, () => {}).map(
                                    (a, aIdx) => (
                                      <div key={aIdx}>{composeActions(a)}</div>
                                    )
                                  )}
                              </div>
                            </DataTableCell>
                          )}
                          {/*{actions(record)}*/}
                        </DataTableRow>
                        {isExpanded && (
                          <div
                            className={
                              "col-span-full border-l-primary50 border-l-2 border-b border-b-gray-200"
                            }
                          >
                            {typeof getRowToggleComponent === "function" &&
                              getRowToggleComponent({ record, isExpanded })}
                          </div>
                        )}
                      </>
                    )
                  })}
                </>
              )}
            </div>
          )}

          {query.isSuccess && !query?.data?.list?.length && (
            <Stub text={notFoundText} />
          )}
        </div>

        {query.isSuccess && !!query?.data?.list?.length && (
          <TablePagination
            count={query.data.totalRecords}
            page={pagination.page}
            onPageChange={(_, value) => onPageChange(value)}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={rowsPerPageOptions}
            onRowsPerPageChange={(e) =>
              onRowsPerPageChange(parseInt(e.target.value, 10))
            }
          />
        )}
      </>
    </div>
  )
}

export default DataTable
