import React from "react"
import { useAlert } from "../../../hooks/useAlert"
import * as yup from "yup"
import AuthPageLayout from "../components/AuthPageLayout"
import SEO from "../../../components/SEO"
import AuthFormLayout from "../components/AuthFormLayout"
import { Form, Formik } from "formik"
import TextInput from "../../../components/v2/inputs/textInput/textInput"
import Button from "@material-ui/core/Button"
import useCreator from "../../../hooks/useCreator"
import { call } from "../../../library/networking/API"
import * as endpoints from "../../../library/networking/apiEndpoints"
import * as routes from "../../../library/constants/routes"
import { Redirect, useHistory } from "react-router-dom"
import { useSearchQuery } from "../../../hooks/useSearchQuery"

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8)
    .max(32)
    .matches(/^\S*$/, "Spaces are not allowed")
    .required(),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required(),
})

const ResetPasswordConfirmationPage = () => {
  const { errorAlert, successAlert } = useAlert()
  const history = useHistory()

  const {
    fields: { id },
  } = useSearchQuery()

  const initialValues = {
    password: "",
    confirm_password: "",
  }

  if (!id) {
    return <Redirect to={routes.RESET_PASSWORD} />
  }

  const onSubmit = async ({ password }) => {
    try {
      await call(endpoints.USERS.CONFIRM_PASSWORD_RESET, {
        password,
        id,
      })
      successAlert("Password is updated.")
      history.push(routes.SIGN_IN)
    } catch (e) {
      if (e.error.code === 20006) return errorAlert("Invalid email")

      errorAlert(e)
    }
  }

  return (
    <AuthPageLayout>
      <SEO title={"New Password"} />
      <AuthFormLayout title={"New Password"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          <Form className={"w-full space-y-6"}>
            <TextInput
              type={"password"}
              name={"password"}
              label={"Password *"}
            />
            <TextInput
              type={"password"}
              name={"confirm_password"}
              label={"Confirm password *"}
            />
            <div className="flex justify-center">
              <Button color="primary" variant="contained" type="submit">
                Save password
              </Button>
            </div>
          </Form>
        </Formik>
      </AuthFormLayout>
    </AuthPageLayout>
  )
}

export default ResetPasswordConfirmationPage
