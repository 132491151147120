import React, { Component } from "react"
import "./profileSettingsGeneral.scss"
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import EditOutlined from "@material-ui/icons/EditOutlined"
import FormControl from "@material-ui/core/FormControl/FormControl"
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Radio from "@material-ui/core/Radio/Radio"
import { userRolesIds } from "../../../../../library/constants/userRoles"
import {
  deleteUser,
  getData,
  signOut,
  updateEmail,
  updateInfo,
  updatePassword,
  updateRole,
} from "../../../../../library/store/actions/creators/authCreators"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { withSnackbar } from "notistack"
import {
  passwordValidation,
  withoutSpaces,
} from "../../../../../library/utils/validators"
import DeleteUserProfile from "../deleteUserProfile/deleteUserProfile"
import getErrorText from "../../../../../library/constants/errorTypes"
import Button from "@material-ui/core/Button"
import FormHelperText from "@material-ui/core/FormHelperText"
import { clearChatsCount } from "../../../../../library/store/actions/creators/notificationCreators"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import { isTestAPI } from "../../../../../library/networking/API"
import TimezonePicker from "../../../../../components/timezones/TimezonePicker"
import { PlainButton } from "../../../../../components/v2/controls"

class ProfileSettingsGeneral extends Component {
  state = {
    passwordMask: true,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    passwordChanged: true,
    passwordsMatch: true,

    emailMask: true,
    email: this.props.auth.userData.email,
    newEmail: "",

    role: String(this.props.auth.userData.role),
    newRole: undefined,

    open: false,

    time_zone: this.props.auth.userData.time_zone,
  }

  async componentDidMount() {
    await this.props.getData()
    this.setState({
      email: this.props.auth.userData.email,
      time_zone: this.props.auth.userData.time_zone || null,
    })
    const { newPassword, confirmPassword } = this.state
    ValidatorForm.addValidationRule("passwordsMatch", () => {
      passwordValidation(newPassword, confirmPassword)
    })
    ValidatorForm.addValidationRule("withoutSpaces", (value) =>
      withoutSpaces(value)
    )
  }

  emailSecured = () => {
    const email = this.state.email
    const visiblePart = email.split("@")[0].split("").slice(0, 2)
    return `${visiblePart.join("")}***@${email.split("@")[1]}`
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })

    if (name === "newPassword" || name === "confirmPassword") {
      this.setState({
        passwordsMatch: true,
      })
    }
  }

  handleRoleChange = (event) => {
    this.setState(
      {
        role: event.target.value,
      },
      () => this.processRoleSave()
    )
  }

  processPasswordSave = async (event) => {
    event.preventDefault()

    const { oldPassword, newPassword, confirmPassword } = this.state
    const { updatePassword } = this.props

    if (oldPassword === newPassword || oldPassword === confirmPassword) {
      this.setState({ passwordChanged: false, passwordsMatch: true })
      return
    }

    if (newPassword !== confirmPassword) {
      this.setState({ passwordsMatch: false, passwordChanged: true })
      return
    }

    try {
      await updatePassword({
        old_password: oldPassword,
        new_password: newPassword,
      })
      this.cancelPasswordChange()
      this.props.enqueueSnackbar("Password successfully changed", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  cancelPasswordChange = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passwordMask: true,
      passwordsMatch: true,
      passwordChanged: true,
    })
  }

  processEmailSave = async () => {
    const { email, newEmail } = this.state
    const { updateEmail } = this.props
    try {
      await updateEmail({
        old_email: email.toLowerCase(),
        new_email: newEmail.toLowerCase(),
      })
      this.setState({ newEmail: "", emailMask: true })
      this.props.enqueueSnackbar(
        "Please check your email for confirmation letter",
        { variant: "success" }
      )
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  processRoleSave = async () => {
    const { role } = this.state
    const {
      getData,
      updateRole,
      auth: { userData },
    } = this.props

    try {
      await updateRole({ token: userData.token, role: parseInt(role) })
      await getData()
      this.props.enqueueSnackbar("Role successfully changed", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  initiateUserDelete = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  onTimeZoneSave = async (e) => {
    e.preventDefault()
    const { time_zone } = this.state

    console.log("time_zone", time_zone)

    try {
      await this.props.updateInfo({ time_zone: time_zone.id })
      await this.props.getData()
      this.props.enqueueSnackbar("Role successfully changed", {
        variant: "success",
      })
    } catch ({ error }) {
      this.props.enqueueSnackbar(getErrorText(error.code), { variant: "error" })
    }
  }

  render() {
    // const isRoleChangeDisabled = process.env.NODE_ENV !== 'development'
    const {
      oldPassword,
      newPassword,
      confirmPassword,
      passwordChanged,
      passwordsMatch,
      newEmail,
      passwordMask,
      emailMask,
      role,
    } = this.state
    const passwordSecured = (
      <div className="form__masked-value">
        <span>********</span>
      </div>
    )

    const passwordForm = (
      <ValidatorForm className="form" onSubmit={this.processPasswordSave}>
        <div className="form__fieldset">
          <label htmlFor="old-password" className="form__label">
            Old password *
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <FormControl error={!passwordChanged} fullWidth>
                <TextValidator
                  type="password"
                  id="old-password"
                  name="oldPassword"
                  value={oldPassword}
                  placeholder="Type your old password"
                  className="edit-form__input"
                  margin="normal"
                  fullWidth
                  validators={[
                    "required",
                    "minStringLength:8",
                    "maxStringLength:32",
                  ]}
                  errorMessages={[
                    "Field is required",
                    "Min length is 8 characters",
                    "Max length is 32 characters",
                  ]}
                  onChange={this.handleChange("oldPassword")}
                />
                {!passwordChanged && (
                  <FormHelperText className="error-message">
                    Password already is in use
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>
        <div className="form__fieldset">
          <label htmlFor="new-password" className="form__label">
            New password *
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <FormControl
                error={!passwordsMatch || !passwordChanged}
                fullWidth
              >
                <TextValidator
                  type="password"
                  id="new-password"
                  name="newPassword"
                  value={newPassword}
                  placeholder="Type your new password"
                  className="edit-form__input"
                  margin="normal"
                  fullWidth
                  validators={[
                    "withoutSpaces",
                    "required",
                    "minStringLength:8",
                    "maxStringLength:32",
                  ]}
                  errorMessages={[
                    "You can`t use space symbol",
                    "Field is required",
                    "Min length is 8 characters",
                    "Max length is 32 characters",
                  ]}
                  onChange={this.handleChange("newPassword")}
                />
                {!passwordsMatch && (
                  <FormHelperText className="error-message">
                    Passwords don't match
                  </FormHelperText>
                )}
                {!passwordChanged && (
                  <FormHelperText className="error-message">
                    Password already in use
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="form__fieldset">
          <label htmlFor="confirm-password" className="form__label">
            Repeat password *
          </label>
          <div className="form__fieldbox">
            <div className="form__input">
              <FormControl error={!passwordsMatch} fullWidth>
                <TextValidator
                  type="password"
                  id="confirm-password"
                  name="confirmPassword"
                  value={confirmPassword}
                  placeholder="Repeat new password"
                  className="edit-form__input"
                  margin="normal"
                  fullWidth
                  validators={[
                    "withoutSpaces",
                    "required",
                    "minStringLength:8",
                    "maxStringLength:32",
                  ]}
                  errorMessages={[
                    "You can`t use space symbol",
                    "Field is required",
                    "Min length is 8 characters",
                    "Max length is 32 characters",
                  ]}
                  onChange={this.handleChange("confirmPassword")}
                />
                {!passwordsMatch && (
                  <FormHelperText className="error-message">
                    Passwords don't match
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <Button
          color="primary"
          variant="contained"
          className="form__submit mb15"
          type="submit"
        >
          Change password
        </Button>

        <button
          className="form__manage"
          type="button"
          onClick={this.cancelPasswordChange}
        >
          <CloseOutlined /> Cancel
        </button>

        <div className="form__divider"></div>
      </ValidatorForm>
    )

    const passwordFormHidden = (
      <div className="form">
        <div className="form__fieldset">
          <label className="form__label">Password</label>
          <div className="form__fieldbox">
            <div className="form__controllable-input">
              <div className="form__input">{passwordSecured}</div>

              <button
                className="form__manage-input"
                type="button"
                onClick={() => this.setState({ passwordMask: false })}
              >
                <EditOutlined /> Edit
              </button>
            </div>
          </div>
        </div>
        <div className="form__divider"></div>
      </div>
    )

    return (
      <BoxPage title={"General"}>
        <form onSubmit={this.onTimeZoneSave} className="form">
          <TimezonePicker
            allowEmpty
            withLeftLabel
            value={this.state.time_zone}
            onChange={(tz) => this.setState({ time_zone: tz })}
          />
          {!this.state.time_zone && (
            <div className={"mb15 space-y-1"}>
              <p>
                Please select your timezone to have proper time in your bookings
              </p>
              <p>
                We assume that your current time zone is:{" "}
                <span className={"italic font-medium"}>
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </span>
                .
              </p>
              <div className={"flex items-baseline gap-2"}>
                <span>Do you want us to save it?</span>
                <div className="flex items-center">
                  <PlainButton>Yes</PlainButton>
                  <span> / </span>
                  <PlainButton>No</PlainButton>
                </div>
              </div>
            </div>
          )}
          <Button
            color="primary"
            variant="contained"
            className="form__submit mb15"
            type="submit"
          >
            Save
          </Button>
          <div className="form__divider"></div>
        </form>

        {passwordMask ? passwordFormHidden : passwordForm}

        <ValidatorForm
          className="form"
          onSubmit={this.processEmailSave}
          instantValidate={false}
        >
          <div className="form__fieldset">
            <label className="form__label">Email</label>
            <div className="form__fieldbox">
              <div className="form__controllable-input">
                <div className="form__input">
                  <div className="form__masked-value">
                    <span>{this.emailSecured()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!emailMask && (
            <>
              <div className="form__fieldset">
                <label htmlFor="new-email" className="form__label">
                  New email *
                </label>
                <div className="form__fieldbox">
                  <div className="form__input">
                    <TextValidator
                      type="text"
                      id="new-email"
                      name="newEmail"
                      value={newEmail}
                      placeholder="Type your new email"
                      className="edit-form__input"
                      margin="normal"
                      fullWidth
                      validators={[
                        "required",
                        "isEmail",
                        "maxStringLength:100",
                      ]}
                      errorMessages={[
                        "Field is required",
                        "Email is not valid",
                        "Max field length is 100 characters",
                      ]}
                      withRequiredValidator
                      onChange={this.handleChange("newEmail")}
                    />
                  </div>
                </div>
              </div>

              <Button
                color="primary"
                variant="contained"
                className="form__submit mb15"
                type="submit"
              >
                Change email
              </Button>
            </>
          )}

          {emailMask ? (
            <button
              className="form__manage"
              type="button"
              onClick={() => this.setState({ emailMask: false })}
            >
              <EditOutlined /> Edit
            </button>
          ) : (
            <button
              className="form__manage"
              type="button"
              onClick={() => this.setState({ emailMask: true })}
            >
              <CloseOutlined /> Cancel
            </button>
          )}
          <div className="form__divider"></div>
        </ValidatorForm>

        <form className="form">
          <div className="form__fieldset">
            <label htmlFor="status" className="form__label">
              Role
            </label>
            <div className="form__fieldbox">
              <div className="form__radio-group">
                <FormControl
                  component="fieldset"
                  fullWidth
                  disabled={!isTestAPI}
                >
                  <RadioGroup
                    aria-label="role"
                    name="role"
                    value={role}
                    onChange={(event) => this.handleRoleChange(event)}
                  >
                    {Object.keys(userRolesIds).map((id) => {
                      return (
                        parseInt(id) !== 3 && (
                          <FormControlLabel
                            key={id}
                            value={`${id}`}
                            control={<Radio color="primary" />}
                            label={
                              <div className="form__radio-label">
                                <div className="form__radio-title">
                                  {userRolesIds[id].name}
                                </div>
                                <div className="form__radio-description">
                                  {userRolesIds[id].description}
                                </div>
                              </div>
                            }
                            labelPlacement="end"
                          />
                        )
                      )
                    })}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="form__divider form__divider--no-margin"></div>
        </form>

        <div className="f jcc aic pt15">
          <div>
            You can{" "}
            <span
              className="link link--inline"
              onClick={this.initiateUserDelete}
            >
              delete your page.
            </span>
          </div>
          <DeleteUserProfile
            open={this.state.open}
            onClose={this.handleClose}
            deleteUser={this.props.deleteUser}
            signOut={this.props.signOut}
            clearChatsCount={this.props.clearChatsCount}
          />
        </div>
      </BoxPage>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getData,
      deleteUser,
      updatePassword,
      updateEmail,
      updateRole,
      updateInfo,
      signOut,
      clearChatsCount,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProfileSettingsGeneral))
