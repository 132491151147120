import React from "react"
import { signOut } from "../../../library/store/actions/creators/authCreators"
import useCreator from "../../../hooks/useCreator"
import { useQueryClient } from "@tanstack/react-query"

const SignOutPage = () => {
  const creator = useCreator()
  const queryClient = useQueryClient()

  const onSignOut = async () => {
    await creator(signOut())
    queryClient.removeQueries()
  }

  React.useEffect(() => {
    onSignOut()
  }, [])

  return null
}

export default SignOutPage
