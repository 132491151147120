import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { useBookMeet } from "./bookMeetContext"
import { useHistory } from "react-router-dom"
import { SIGN_UP } from "../../library/constants/routes"
import { useSelector } from "react-redux"
import PersonIcon from "@material-ui/icons/PersonOutlineOutlined"
import EventIcon from "@material-ui/icons/EventOutlined"
import GlobeIcon from "@material-ui/icons/LanguageOutlined"
import LocationIcon from "@material-ui/icons/LocationOnOutlined"
import { getUtcFromZonedDateTime } from "../../library/utils/dateConvert"
import { addMinutes, format } from "date-fns"
import { getUserName } from "../../library/utils/user/userUtils"
import TimezoneLabel from "../../components/timezones/timezoneLabel"
import LinkIcon from "@material-ui/icons/LinkOutlined"
import { Divider } from "../../components/v2/layout/forms/form/form"
import GroupAddOutlinedIcon from "@material-ui/icons/GroupAddOutlined"
import UserLink from "../../components/v2/utils/UserLink"
import { isLast } from "../../library/utils/arrays"

const formatDate = (date) => format(date, "eeee, MMMM do, yyyy")
const formatTime = (date) => format(date, "h:mm aaa")

const BookMeetStep5 = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const history = useHistory()

  const {
    data,
    onClose,
    user,
    selectedTimezone,
    friendInvitees,
    outsiderInvitees,
  } = useBookMeet()

  const toSignUp = () => {
    history.replace(SIGN_UP, {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
    })
  }

  const generatedDateTime = getUtcFromZonedDateTime(
    data.date.date,
    data.time.start,
    user.time_zone || "America/Chicago"
  )

  const startTime = formatTime(generatedDateTime)
  const endTime = formatTime(addMinutes(generatedDateTime, data.type))
  const formattedDate = formatDate(generatedDateTime)

  return (
    <>
      <DialogTitle className={"text-center"}>You are scheduled</DialogTitle>
      <DialogContent>
        <p className={"color-black-54 font-16 mb15"}>
          Thank you, your appointment has been booked.
        </p>
        <p className={"mb15 font-18 font-weight-500"}>{data.title}</p>
        <div className={"f aic mb10"}>
          <PersonIcon className={"color-primary"} />
          <p className={"ml10"}>{getUserName(user)}</p>
        </div>
        {!!(friendInvitees.length || outsiderInvitees.length) && (
          <div className={"f aifs mb10"}>
            <GroupAddOutlinedIcon className={"color-primary"} />
            <div className={"ml10"}>
              <p className={"mb10"}>Other invitees:</p>
              {!!friendInvitees.length && (
                <div className={!!outsiderInvitees.length ? "mb15" : ""}>
                  {friendInvitees.map((invitee, idx) => (
                    <div
                      className={!isLast(friendInvitees, idx) ? "mb5" : ""}
                      key={invitee.id}
                    >
                      <UserLink user={invitee} />
                    </div>
                  ))}
                </div>
              )}

              {outsiderInvitees.map((invitee, idx) => (
                <div
                  key={idx}
                  className={!isLast(outsiderInvitees, idx) ? "mb10" : ""}
                >
                  <p>
                    {invitee.first_name} {invitee.last_name}
                  </p>
                  <p className={"color-black-54 font-13"}>{invitee.email}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={"f aic mb10"}>
          <EventIcon className={"color-primary"} />
          <p className={"ml10"}>
            {startTime} - {endTime}, {formattedDate}
          </p>
        </div>
        {data.location && (
          <div className={"f aic mb10"}>
            <LocationIcon className={"color-primary"} />
            <p className={"ml10"}>{data.location}</p>
          </div>
        )}
        {data.conference_link && (
          <div className={"f aic mb10"}>
            <LinkIcon className={"color-primary"} />
            <p className={"ml10"}>{data.conference_link}</p>
          </div>
        )}
        <div className={"f aic mb10"}>
          <GlobeIcon className={"color-primary"} />
          <TimezoneLabel as={"p"} className={"ml10"} tz={selectedTimezone.id} />
        </div>
        {!isLoggedIn && (
          <>
            <Divider className={"mt30 mb30"} />
            <div className={"text-center"}>
              <p className={"font-16 mb10"}>
                Continue to create your own account?
              </p>
              <p className={"color-black-54"}>
                If you make your own account and sync your calendar, KnowStory
                will automatically find another time if you later have to push
                this meeting forward. Continue to create and sync?
              </p>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={toSignUp}
                className={"mt15"}
              >
                Sign Up To KnowStory
              </Button>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          Ok
        </Button>
      </DialogActions>
    </>
  )
}

export default BookMeetStep5
