import React from "react"
import { useToggle } from "../../hooks/useToggle"
import AlertDialog from "../../components/ui/alertDialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import Dialog from "@material-ui/core/Dialog/Dialog"
import { useHistory } from "react-router-dom"
import { HOME, SIGN_IN, SIGN_UP } from "../constants/routes"

const AuthorizationCTA = ({ component, redirectTo = HOME }) => {
  const history = useHistory()
  const [isOpen, toggle] = useToggle(false)

  const currentUrl = window.location.href

  return (
    <>
      {component({ toggle })}
      {isOpen && (
        <Dialog
          open={isOpen}
          onClose={toggle}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Authorization required
          </DialogTitle>
          <DialogContent>
            <p className="color-black-54 font-16">
              Please sign in to your account to perform this action.
            </p>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() =>
                history.push({
                  pathname: SIGN_UP,
                  search: `?redirectTo=${currentUrl}`,
                })
              }
              color="primary"
            >
              Sign Up
            </Button>
            <Button
              onClick={() =>
                history.push({
                  pathname: SIGN_IN,
                  search: `?redirectTo=${currentUrl}`,
                })
              }
              color="primary"
              autoFocus
            >
              Sign In
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default AuthorizationCTA
