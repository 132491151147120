import React from "react"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import SuperListPage from "../../../../../../components/v2/layout/superListPage/superListPage"
import { getCardSize } from "../../../../../../library/constants/styles/cardSizes"
import { User } from "../../../../../../library/utils/user/userUtils"
import UserCard from "../../../../../../components/v2/layout/cards/userCard"
import { GROUPS } from "../../../../../../library/store/actions/types/groupsTypes"
import { useToggle } from "../../../../../../hooks/useToggle"
import {
  useGroup,
  useGroupMemberMutations,
} from "../../../../../../hooks/data/groups/groups"
import UsersRosterModal from "./components/UsersRosterModal"
import InviteParticipantByEmailModal from "./components/InviteByEmailModal"

const GroupSettingsInvitesPage = () => {
  const groupId = useIdParam("groupId")
  const [showRosterDialog, toggleRosterDialog] = useToggle(false)
  const [showInviteByEmailModal, toggleInviteByEmailModal] = useToggle(false)

  const { group, isSuccess } = useGroup()

  const queryKey = [GROUPS.PARTICIPANTS.INVITES.SEARCH, { group_id: groupId }]

  const {
    invites: { create, cancel },
  } = useGroupMemberMutations({ queryKey })

  const onCreateInvite = async (userId) => {
    await create.mutateAsync({ group_id: groupId, user_id: userId })
  }
  const onCancelInvite = async (userId) => {
    await cancel.mutateAsync({ group_id: groupId, user_id: userId })
  }

  const canUploadRoster = [4, 5, 7].includes(group?.type)
  const canInviteByEmail = [2].includes(group?.type)

  return (
    isSuccess && (
      <>
        <SuperListPage
          queryConfig={{
            key: [GROUPS.PARTICIPANTS.INVITES.SEARCH, { group_id: groupId }],
            fetchParams: {
              group_id: groupId,
            },
            listKey: "users",
          }}
          headerProps={{
            title: "Invite",
            button: canUploadRoster
              ? {
                  label: "Upload Roster",
                  action: toggleRosterDialog,
                }
              : canInviteByEmail
                ? {
                    label: "Invite By Email",
                    action: toggleInviteByEmailModal,
                  }
                : undefined,
          }}
          listItemHeight={getCardSize(8).numValPx}
          getListItemComponent={({ item }) => {
            const __user = new User(item)

            return (
              <UserCard
                key={item.id}
                user={item}
                textAboveName={__user.accountRole}
                infoComponent={() => (
                  <div className={"space-y-1"}>
                    {__user.hasLocation && (
                      <p className={""}>{__user.location}</p>
                    )}
                    {__user.hasHighlightedInfo && (
                      <p>{__user.highlightedInfo}</p>
                    )}
                  </div>
                )}
                controls={{
                  button: !item.is_invited && {
                    label: "Invite",
                    action: () => onCreateInvite(item.id),
                  },
                  dropdownButton: !!item.is_invited && {
                    buttonLabel: "Invitation sent",
                    items: [
                      {
                        label: "Cancel Invitation",
                        action: () => onCancelInvite(item.id),
                      },
                    ],
                  },
                }}
              />
            )
          }}
        />
        {showRosterDialog && (
          <UsersRosterModal
            open={showRosterDialog}
            handleClose={toggleRosterDialog}
            groupId={groupId}
          />
        )}
        {showInviteByEmailModal && (
          <InviteParticipantByEmailModal
            open={showInviteByEmailModal}
            handleClose={toggleInviteByEmailModal}
          />
        )}
      </>
    )
  )
}

export default GroupSettingsInvitesPage
