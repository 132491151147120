import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useIdParam } from "../../useIdParam"
import { getGroupLogo } from "../../../library/utils/getGroupLogo"
import { call } from "../../../library/networking/API"
import bgDefault from "../../../assets/img/bg-default.png"
import React from "react"
import { useTry } from "../../useTry"
import { GROUPS } from "../../../library/store/actions/types/groupsTypes"

export const useGroup = ({
  params: {
    id,
    participants_limit = 5,
    connections_limit = 5,
    products_limit = 5,
  } = {},
  options = {},
} = {}) => {
  const idParam = useIdParam("groupId")

  const __id = id || idParam || null

  const queryKey = [GROUPS.GET, { id: __id }]

  const { data: group, ...query } = useQuery({
    queryKey,
    queryFn: async (ctx) => {
      const res = await call(ctx.queryKey[0], {
        id: __id,
        participants_limit,
        connections_limit,
        products_limit,
      })

      return res.data
    },
    enabled: options.enabled || !!__id,
    refetchOnWindowFocus: false,
    staleTime: 60000,
    ...options,
  })

  return {
    group,
    isAvailable: !group?.blocked && !group?.deleted,
    canEdit: group?.is_admin,
    groupHeader: group?.header || bgDefault,
    groupLogo: getGroupLogo(group),
    isInterest: group?.type === 1,
    isEvent: group?.type === 2,
    isCompany: group?.type === 3,
    isSchool: group?.type === 4,
    isDistrict: group?.type === 5,
    isOneTeacherSchool: group?.type === 7,
    isEducational: [4, 5, 7].includes(group?.type),
    isGovEducational: [4, 5].includes(group?.type),
    isAnySchool: [4, 7].includes(group?.type),
    isUserFollower: group?.tags?.includes(1),
    queryKey,
    ...query,
  }
}

export const useGroupProductAttachments = (options = {}) => {
  const groupId = useIdParam("groupId")

  const query = useQuery({
    queryKey: [GROUPS.LIBRARY_LIST, { group_id: groupId }],
    queryFn: async (ctx) => {
      const data = await call(ctx.queryKey[0], {
        group_id: groupId,
        offset: 0,
        limit: 5,
      })

      return data?.products
    },
    refetchOnWindowFocus: false,
    ...options,
  })
  return { ...query }
}

export const useGroupMutations = ({ queryKey }) => {
  const queryClient = useQueryClient()
  const safeCall = useTry()

  const updateGroup = useMutation({
    mutationFn: async ({
      id,
      name,
      description,
      website,
      country,
      city,
      state,
      address,
      zip_code,
      phone,
      start_date,
      end_date,
      full_image,
      full_header,
      closed,
      attachments,
      links,
      district_id,
      event_link,
    }) => {
      const group = queryClient.getQueryData(queryKey)

      const __attachments = attachments || [
        ...group.videos.map((f) => f.id),
        ...group.blobs.map((f) => f.id),
        ...group.images.map((f) => f.id),
      ]
      await safeCall(async () => {
        await call(GROUPS.UPDATE, {
          id,
          name,
          description,
          website,
          country,
          city,
          state,
          address,
          zip_code,
          phone,
          start_date,
          end_date,
          full_image,
          full_header,
          closed,
          attachments: __attachments,
          links,
          district_id,
          event_link,
        })
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const deleteGroup = useMutation({
    mutationFn: async (id) => {
      await safeCall(async () => {
        await call(GROUPS.DELETE, { id })
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  return {
    updateGroup,
    deleteGroup,
  }
}

export const useGroupMemberMutations = ({
  queryKey,
  isMutatingInfinite = true,
}) => {
  const queryClient = useQueryClient()
  const safeCall = useTry()

  const [mutatingIds, setMutatingIds] = React.useState([])

  const joinGroup = useMutation({
    mutationFn: async (id) => {
      await safeCall(async () => await call(GROUPS.JOIN, { id }))
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const leaveGroup = useMutation({
    mutationFn: async (id) => {
      await safeCall(async () => await call(GROUPS.LEAVE, { id }))
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const removeMember = useMutation({
    mutationFn: async ({ group_id, user_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.REMOVE, {
            group_id,
            user_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const changeMemberRole = useMutation({
    mutationFn: async ({ group_id, user_id, role }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.CHANGE_ROLE, {
            group_id,
            user_id,
            role,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const changeMemberOccupations = useMutation({
    mutationFn: async ({ group_id, user_id, tags }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.CHANGE_TAGS, {
            group_id,
            user_id,
            tags,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const cancelRequest = useMutation({
    mutationFn: async (group_id) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.REQUESTS.CANCEL, {
            group_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const declineRequest = useMutation({
    mutationFn: async ({ group_id, user_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.REQUESTS.DECLINE, {
            group_id,
            user_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const acceptRequest = useMutation({
    mutationFn: async ({ group_id, user_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.REQUESTS.ACCEPT, {
            group_id,
            user_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const createInvitation = useMutation({
    mutationFn: async ({ group_id, user_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.INVITES.CREATE, {
            group_id,
            user_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const cancelInvitation = useMutation({
    mutationFn: async ({ group_id, user_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.INVITES.CANCEL, {
            group_id,
            user_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  const declineInvitation = useMutation({
    mutationFn: async ({ group_id }) => {
      await safeCall(
        async () =>
          await call(GROUPS.PARTICIPANTS.INVITES.DECLINE, {
            group_id,
          })
      )
    },
    onSuccess: async (data, user_id, ctx) => {
      return queryClient.invalidateQueries({ queryKey })
    },
  })

  return {
    mutatingIds,
    joinGroup,
    member: {
      leave: leaveGroup,
      remove: removeMember,
      changeRole: changeMemberRole,
      changeOccupations: changeMemberOccupations,
    },
    requests: {
      cancel: cancelRequest,
      decline: declineRequest,
      accept: acceptRequest,
    },
    invites: {
      create: createInvitation,
      cancel: cancelInvitation,
      decline: declineInvitation,
    },
  }
}
