import { HOME } from "../library/constants/routes"
import { useHistory } from "react-router-dom"
import { useSearchQuery } from "./useSearchQuery"
import { isAbsoluteUrl } from "../library/utils/returnExternalUrl"

export const useAuthenticatedRedirect = ({
  shouldRedirect = true,
  redirectFromSearchQuery = false,
  redirectPath,
} = {}) => {
  const history = useHistory()

  const { fields: searchQuery } = useSearchQuery()

  if (shouldRedirect) {
    if (redirectFromSearchQuery && !!searchQuery.redirectTo) {
      window.location.replace(searchQuery.redirectTo)
    } else if (!!redirectPath && isAbsoluteUrl(redirectPath)) {
      window.location.replace(redirectPath)
    } else {
      history.replace(HOME)
    }
  }
}
