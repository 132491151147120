import React from "react"
import AuthPageLayout from "../components/AuthPageLayout"
import SEO from "../../../components/SEO"
import { useAlert } from "../../../hooks/useAlert"
import { generatePath, Redirect } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import SignUpProvider, { useSignUpContext } from "./SignUpProvider"
import Stepper from "@material-ui/core/Stepper/Stepper"
import SignUpStep1 from "./components/SignUpStep1"
import SignUpStep2 from "./components/SignUpStep2"
import SignUpStep3 from "./components/SignUpStep3"
import Step from "@material-ui/core/Step/Step"
import StepLabel from "@material-ui/core/StepLabel/StepLabel"
import { StepContent } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import PlainLink from "../../../components/ui/link"
import * as routes from "../../../library/constants/routes"
import Button from "@material-ui/core/Button"
import { signUp } from "../../../library/store/actions/creators/authCreators"
import { useSearchQuery } from "../../../hooks/useSearchQuery"
import { HOME } from "../../../library/constants/routes"
import { isAbsoluteUrl } from "../../../library/utils/returnExternalUrl"
import { useAuthenticatedRedirect } from "../../../hooks/useAuthenticatedRedirect"

const SignUpPageContent = () => {
  const { isLoggedIn, newFriendId, inviterGroupId } = useSelector(
    ({ auth }) => auth
  )
  const {
    fields: { knowcred, meetWith, redirectTo },
  } = useSearchQuery()

  const { data, activeStep } = useSignUpContext()
  const { errorAlert } = useAlert()
  const dispatch = useDispatch()

  const steps = [
    {
      label: "Main info",
      component: <SignUpStep1 />,
    },
    {
      label: "Role",
      component: <SignUpStep2 />,
    },
    {
      label: "Location",
      component: <SignUpStep3 />,
    },
  ]

  const [agreeWithTerms, setAgreeWithTerms] = React.useState(false)

  const onSubmit = async () => {
    try {
      await dispatch(
        signUp({
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          password: data.password,
          role: Number(data.role),
          country: data.country,
          state: data.country === "USA" ? data.state : "",
          city: data.city,
          work: data.role !== "1" && !!data.work ? data.work : undefined,
          education:
            data.role === "1" &&
            !!data.education?.type &&
            !!data.education?.name
              ? {
                  name: data.education.name,
                  type: Number(data.education.type),
                }
              : undefined,
          age_consent: data.age_consent === "true",

          newFriendId,
          inviterGroupId,
        })
      )
    } catch (e) {
      errorAlert(e)
    }
  }

  const [shouldRedirect, setShouldRedirect] = React.useState(false)

  React.useEffect(() => {
    setShouldRedirect(isLoggedIn)
  }, [isLoggedIn])

  const redirectPath = redirectTo
    ? redirectTo
    : knowcred
      ? generatePath(routes.KNOWCRED_SINGLE, {
          knowCredId: knowcred,
        })
      : meetWith
        ? generatePath(routes.BOOK_MEET, {
            userId: meetWith,
          })
        : routes.HOME

  useAuthenticatedRedirect({ shouldRedirect, redirectPath })

  return (
    <AuthPageLayout shouldRedirectIfLoggedIn={false}>
      <SEO title="Sign Up" />
      <div
        className={
          "max-w-[90%] sm:max-w-116 bg-white rounded-xl overflow-hidden mx-auto mt-16 mb-6"
        }
      >
        <div className="py-8 px-6 text-center bg-primary">
          <h1 className={"text-white font-normal text-4xl leading-none"}>
            Sign Up
          </h1>
        </div>
        <div className="flex flex-col w-full p-2">
          <Stepper activeStep={activeStep} orientation={"vertical"}>
            {steps.map(({ label, component }, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <div className={"max-w-formField"}>{component}</div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === steps.length - 1 && (
          <div className="p-6">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agreeWithTerms}
                  value={"true"}
                  disableRipple
                  onChange={(e) => setAgreeWithTerms(e.target.checked)}
                />
              }
              label={
                <div>
                  I have read and agree to{" "}
                  <PlainLink newTab inline to={routes.TERMS_AND_CONDITIONS}>
                    the Terms and Conditions
                  </PlainLink>
                </div>
              }
            />
            <div className="flex justify-center mt-4">
              <Button
                className={"mx-auto"}
                variant={"contained"}
                color={"primary"}
                onClick={onSubmit}
                disabled={!agreeWithTerms}
              >
                Sign Up
              </Button>
            </div>
          </div>
        )}
      </div>
    </AuthPageLayout>
  )
}

export const SignUpPage = () => {
  return (
    <SignUpProvider>
      <SignUpPageContent />
    </SignUpProvider>
  )
}

export default SignUpPage
