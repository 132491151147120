import React from "react"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import Button from "@material-ui/core/Button/Button"
import { getUserName } from "../../library/utils/user/userUtils"
import { PlainButton } from "../../components/v2/controls"
import { useHistory } from "react-router-dom"
import { SIGN_IN, SIGN_UP } from "../../library/constants/routes"
import { useBookMeet } from "./bookMeetContext"
import { useIdParam } from "../../hooks/useIdParam"

const BookMeetStep1 = () => {
  const userId = useIdParam("userId")
  const history = useHistory()

  const toSignUp = () =>
    history.replace({ pathname: SIGN_UP, search: `?meetWith=${userId}` })
  const toSignIn = () => history.replace(SIGN_IN)

  const { user, setStep } = useBookMeet()

  const toNextStep = () => setStep(2)
  return (
    <>
      <DialogTitle id="dialog-title" className={"text-center"}>
        You are trying to create a meeting with {getUserName(user)}
      </DialogTitle>
      <DialogContent className={"pb15"}>
        <div className="f fdc aic">
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={toSignUp}
            className={"mb15"}
          >
            Create an account
          </Button>
          <span className={"mb15"}>or</span>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={toSignIn}
            className={"mb15"}
          >
            Sign In
          </Button>
          <PlainButton className={"color-black-54"} onClick={toNextStep}>
            continue without account
          </PlainButton>
        </div>
      </DialogContent>
    </>
  )
}

export default BookMeetStep1
