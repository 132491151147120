import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { COURSE_MEETS } from "../../../library/store/actions/types/uknowTypes"
import { call } from "../../../library/networking/API"
import { useAlert } from "../../useAlert"
import { useIdParam } from "../../useIdParam"

export const useCourseMeets = ({ knowmix_id, options = {} } = {}) => {
  const knowmixId = useIdParam("knowmixId")
  const __knowmix_id = knowmix_id || knowmixId

  const { errorAlert } = useAlert()

  const queryKey = [COURSE_MEETS.LIST, { knowmix_id: __knowmix_id }]

  const { data, ...query } = useQuery({
    queryKey,
    queryFn: async (ctx) => {
      try {
        const res = await call(ctx.queryKey[0], ctx.queryKey[1])
        return res.data.course_meetings
      } catch (e) {
        errorAlert(e)
      }
    },
    ...options,
  })

  return {
    meets: data || [],
    queryKey,
    ...query,
  }
}

export const useCourseMeet = ({ id, options = {} }) => {
  const { errorAlert } = useAlert()

  const queryKey = [COURSE_MEETS.GET, { id }]

  const { data, ...query } = useQuery({
    queryKey,
    queryFn: async (ctx) => {
      try {
        const res = await call(ctx.queryKey[0], ctx.queryKey[1])
        return res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    ...options,
  })

  return {
    meet: data,
    queryKey,
    ...query,
  }
}

export const useCourseMeetByStep = ({ step_id }) => {
  const { meets } = useCourseMeets()
  return meets.find((meet) => meet.step_id === step_id)
}

export const useCourseMeetMutations = (queryKey = []) => {
  const queryClient = useQueryClient()
  const { errorAlert } = useAlert()

  const createCourseMeet = useMutation(
    async ({
      title,
      cohort_size,
      target_datetime,
      duration,
      target_interval,
      space_id,
      hold_ids,
      instructions,
      reminder,
      conference_link,
      other_links,
      attachments,
      allow_over_cohort,
      require_full_cohort,
      step_id,
    }) => {
      try {
        const res = await call(COURSE_MEETS.CREATE, {
          title,
          cohort_size,
          target_datetime,
          duration,
          target_interval,
          space_id,
          hold_ids,
          instructions,
          reminder,
          conference_link,
          other_links,
          attachments,
          allow_over_cohort,
          require_full_cohort,
          step_id,
        })

        return res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  const updateCourseMeet = useMutation(
    async ({
      id,
      title,
      cohort_size,
      target_datetime,
      duration,
      target_interval,
      space_id,
      hold_ids,
      instructions,
      reminder,
      conference_link,
      other_links,
      attachments,
      allow_over_cohort,
      require_full_cohort,
    }) => {
      try {
        const res = await call(COURSE_MEETS.UPDATE, {
          id,
          title,
          cohort_size,
          target_datetime,
          duration,
          target_interval,
          space_id,
          hold_ids,
          instructions,
          reminder,
          conference_link,
          other_links,
          attachments,
          allow_over_cohort,
          require_full_cohort,
        })

        return res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  const deleteCourseMeet = useMutation(
    async ({ id }) => {
      try {
        const res = await call(COURSE_MEETS.DELETE, { id })
        return res.data
      } catch (e) {
        errorAlert(e)
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    }
  )

  return {
    createCourseMeet,
    updateCourseMeet,
    deleteCourseMeet,
  }
}
