export const UKNOW = {
  STEP_CREATE: "knowmixes.uknows.create",
  STEP_UPDATE: "knowmixes.uknows.update",
  STEP_DELETE: "knowmixes.uknows.delete",
  STEP_GET: "knowmixes.uknows.get",
  KNOWMIX_GET: "knowmixes.uknows.get",
  STEP_CLEAR_CURRENT: "knowmixes.uknows.clear_current",
  STEP_LIST: "knowmixes.uknows.list",
  STEP_LIST_CLEAR: "knowmixes.uknows.list_clear",

  CLASS_CREATE: "classes.uknows.create",
  CLASS_UPDATE: "classes.uknows.update",
  CLASS_DELETE: "classes.uknows.delete",
  CLASS_GET: "classes.uknows.get",
  CLASS_CLEAR_CURRENT: "classes.uknows.clear_current",
  CLASS_LIST: "classes.uknows.list",
  CLASS_LIST_CLEAR: "classes.uknows.list_clear",

  LIST: "knowmap.learner.uknows.list",
  LIST_CLEAR: "uknows.list_clear",
  GET: "knowmixes.learner.uknows.get",
  CLEAR_CURRENT: "uknows.clear_current",
  MARK_AS_DONE: "classes.uknows.library.complete",
  MARK_CURRENT_AS_DONE: "uknow.current.complete",
  // KNOWMIX_GET: "knowmixes.learner.uknows.get",
  KNOWMIX_MARK_AS_DONE: "knowmixes.learner.uknows.complete",
}

export const KNOWMEET = {
  CREATE: "knowmixes.uknows.create",
  UPDATE: "knowmixes.uknows.update",
  DELETE: "knowmixes.uknows.delete",
  GET: "knowmixes.uknows.get",
  LIST: "knowmixes.uknows.list",
}

export const MEETS = {
  CREATE: "public_meetings.create",
  UPDATE: "public_meetings.update",
  GET: "public_meetings.get",
  CLEAR_CURRENT: "public_meetings.clear_current",
  DELETE: "public_meetings.delete",
  CREATOR_LIST: "public_meetings.list_creator",
  CLEAR_CREATOR_LIST: "public_meetings.clear_list_creator",
  LIST: "public_meetings.list",
  CLEAR_LIST: "public_meetings.clear_list",
  ACCEPT: "public_meetings.accept",
  DECLINE: "public_meetings.decline",
  ASSIGN_HOLD: "public_meetings.assign_hold",
  FORWARD: "public_meetings.forward",
  ATTEND: "public_meetings.attend",
  REMOVE: "public_meetings.remove",
}

export const COURSE_MEETS = {
  CREATE: "course_meetings.create",
  UPDATE: "course_meetings.update",
  GET: "course_meetings.get",
  CLEAR_CURRENT: "course_meetings.clear_current",
  DELETE: "course_meetings.delete",
  CREATOR_LIST: "course_meetings.list_creator",
  CLEAR_CREATOR_LIST: "course_meetings.clear_list_creator",
  LIST: "course_meetings.list_by_knowmix",
  CLEAR_LIST: "course_meetings.clear_list",
  ACCEPT: "course_meetings.accept",
  DECLINE: "course_meetings.decline",
  ASSIGN_HOLD: "course_meetings.assign_hold",
  FORWARD: "course_meetings.forward",
  ATTEND: "course_meetings.attend",
  REMOVE: "course_meetings.remove",
}
