import React, { useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { useCurrentUser } from "../../../../../hooks/data/user/useUser"
import { call } from "../../../../../library/networking/API"
import Loader from "../../../../../components/ui/loader"
import { PlainButton } from "../../../../../components/v2/controls"
import { FilterListOutlined } from "@material-ui/icons"
import { getLocalePeriod } from "../../../../../library/utils/timeString/timeString"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { GROUPS } from "../../../../../library/store/actions/types/groupsTypes"
import { safeGetEntity } from "../../../../../library/utils/entities"
import GroupLink from "../../../../../components/v2/utils/GroupLink"

const ProfileSchedule = () => {
  const { user } = useCurrentUser()

  const [filterAnchorEl, setFilterAnchorEl] = useState(null)
  const handleOpenFilters = (event) => {
    setFilterAnchorEl(event.currentTarget)
  }
  const handleCloseFilters = () => {
    setFilterAnchorEl(null)
  }

  const [selectedGroup, setSelectedGroup] = useState("")

  const { data: groups, isSuccess: groupsSuccess } = useQuery({
    queryKey: ["schedule_filter"],
    queryFn: async () => {
      const res = await call(GROUPS.LIST, {
        user_id: user.id,
        type: 4,
        active: true,
        paid: false,
        managed: false,
        offset: 0,
        limit: 100,
      })
      return res.data.groups || []
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  })

  const { data, isSuccess, isInitialLoading } = useQuery({
    queryKey: [
      user.role === 1
        ? "schedule_assignments.list_student"
        : "schedule_assignments.list_teacher",
      { group_id: selectedGroup?.id },
    ],
    queryFn: async (ctx) => {
      const res = await call(ctx.queryKey[0], {
        group_id: selectedGroup?.id || undefined,
      })
      return res.data.slots || []
    },
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  })

  return (
    <div className="box boxed-list">
      <div className="boxed-list__header">Your schedule for today</div>
      <div className="boxed-list__list pb-4">
        <div className={"flex items-center px-4 mb-4"}>
          <PlainButton
            onClick={handleOpenFilters}
            disabled={!groupsSuccess}
            className={"mr-4"}
          >
            <FilterListOutlined color={"primary"} />
            {!!selectedGroup ? (
              <span className={"text-primary line-clamp-1"}>
                {selectedGroup.name}
              </span>
            ) : (
              <span className={"text-black54"}>All groups</span>
            )}
          </PlainButton>

          {groupsSuccess && (
            <Menu
              id="calendar-views-menu"
              anchorEl={filterAnchorEl}
              keepMounted
              open={Boolean(filterAnchorEl)}
              onClose={handleCloseFilters}
              PaperProps={{
                style: {
                  maxHeight: 250,
                },
              }}
            >
              {groups.length ? (
                <>
                  <MenuItem
                    onClick={() => {
                      setSelectedGroup("")
                      handleCloseFilters()
                    }}
                  >
                    <span className={"text-black54"}>All schools</span>
                  </MenuItem>
                  {groups.map((g) => (
                    <MenuItem
                      key={g.id}
                      selected={selectedGroup?.id === g.id}
                      onClick={() => {
                        setSelectedGroup(g)
                        handleCloseFilters()
                      }}
                    >
                      {g.name}
                    </MenuItem>
                  ))}
                </>
              ) : (
                <MenuItem disabled>
                  You are not a participant of any school yet
                </MenuItem>
              )}
            </Menu>
          )}
        </div>
        {!isSuccess && isInitialLoading && <Loader />}
        {isSuccess && !!data.length && (
          <div className={"max-h-64 overflow-y-auto relative space-y-4"}>
            {data.map((v, i) => (
              <div key={v.id}>
                <div className={"px-4 text-sm"}>
                  <p className={"leading-none"}>
                    {getLocalePeriod(v.time_from, v.time_to)}
                  </p>
                  <p>
                    <span className={"font-medium"}>{v.subject.name}</span>
                    {!!v?.space?.id && user.role === 1 && (
                      <span>, {v.space?.name}</span>
                    )}
                  </p>
                  {!selectedGroup && !!safeGetEntity(v.school) && (
                    <GroupLink group={v.school} />
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {isSuccess && !data.length && (
          <div className={"px-4"}>Nothing scheduled for today</div>
        )}
      </div>
      {/*<div className={"boxed-list__footer"}>*/}
      {/*  <PlainButton onClick={onScheduleOpen}>*/}
      {/*    Show full schedule <KeyboardArrowRight />*/}
      {/*  </PlainButton>*/}
      {/*</div>*/}
    </div>
  )
}

export default ProfileSchedule
