import React from "react"
import { TextField, Tooltip } from "@material-ui/core"
import { useField } from "formik"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import { useAlert } from "../../../../hooks/useAlert"

const TextInput = (props) => {
  const { successAlert } = useAlert()
  const [field, meta] = useField(props)

  return (
    <div className={"f aic"}>
      <TextField
        id={field.name}
        margin="normal"
        fullWidth
        type={props.type || "text"}
        placeholder={props.placeholder}
        disabled={props.disabled}
        error={meta.touched && Boolean(meta.error)}
        helperText={(meta.touched && meta.error) || null}
        multiline={props.multiline}
        InputProps={{
          readOnly: props.readOnly || false,
          ...props.InputProps,
        }}
        label={props.label}
        {...field}
        onBlur={props.onBlur || field.onBlur || undefined}
      />
      {props.withCopyButton && (
        <Tooltip title="Copy" arrow className={"ml5"}>
          <FileCopyOutlinedIcon
            color={"primary"}
            onClick={async () => {
              await navigator.clipboard.writeText(field.value)
              successAlert("Data copied to clipboard")
            }}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default TextInput
