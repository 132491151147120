import React from "react"
import Dialog from "@material-ui/core/Dialog"
import { KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS } from "../../../library/constants/routes"
import { useHistory, useParams } from "react-router-dom"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import styled from "@emotion/styled"
import { colors } from "../../../library/constants/styles/colors"
import DateFnsUtils from "@date-io/date-fns"
import { format, isPast, isSameDay, isSameMonth, isSameYear } from "date-fns"
import SelectedMeetData from "../../bookMeet/selectedMeetData"
import Button from "@material-ui/core/Button"
import { ChevronLeftOutlined } from "@material-ui/icons"
import { ThemeProvider } from "@material-ui/styles"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import TimezonePicker from "../../../components/timezones/TimezonePicker"
import {
  getUtcFromZonedDateTime,
  localizedDateTime,
} from "../../../library/utils/dateConvert"
import { appointmentCalendarTheme } from "../../bookMeet/bookMeetStep3"
import { useQuery } from "@tanstack/react-query"
import { APPOINTMENTS } from "../../../library/store/actions/types/calendarTypes"
import { call } from "../../../library/networking/API"
import { getUserName } from "../../../library/utils/user/userUtils"
import { useSelector } from "react-redux"
import UserLink from "../../../components/v2/utils/UserLink"
import { Avatar } from "@material-ui/core"
import UserAvatar from "../../../components/userAvatar"
import { useAlert } from "../../../hooks/useAlert"
import { calendarGrades } from "../../../library/constants/educationalDictionary"
import AlertDialog from "../../../components/ui/alertDialog"
import { useToggle } from "../../../hooks/useToggle"

const Container = styled.div`
  display: grid;
  grid-template-columns: 25% 45% 30%;
  width: 100%;
  max-height: 75vh;

  & > div {
    padding: 1rem;
    &:not(:last-of-type) {
      border-right: 1px solid ${colors.black12};
    }
  }
`

const TimeButtonsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

class WeekDays extends DateFnsUtils {
  getWeekdays() {
    return ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]
  }
}

const areEqualDates = (d1, d2) =>
  isSameDay(d1, d2) && isSameMonth(d1, d2) && isSameYear(d1, d2)

const IncomingSolicitAppointmentFormModal = () => {
  const history = useHistory()
  const { requestId } = useParams()

  const { errorAlert } = useAlert()

  console.log({ requestId })

  const [date, setDate] = React.useState(new Date())
  const [selectedDateRecord, setSelectedDateRecord] = React.useState(null)
  const [selectedTimeRecord, setSelectedTimeRecord] = React.useState(null)

  const { data: requestData, isSuccess: requestSuccess } = useQuery({
    queryKey: [APPOINTMENTS.SOLICIT.GET_PUBLIC, { id: requestId }],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], ctx.queryKey[1])

      return response.data ?? {}
    },
  })

  const { data: slotsData, isSuccess: slotsDataSuccess } = useQuery({
    queryKey: [
      APPOINTMENTS.AVAILABLE_SLOTS,
      {
        hold_ids: requestData?.holds?.length
          ? requestData?.holds?.map((h) => h.id)
          : undefined,
        slot: requestData?.duration,
        user_id: !requestData?.holds?.length
          ? requestData?.creator_id
          : undefined,
      },
    ],
    queryFn: async (ctx) => {
      const response = await call(ctx.queryKey[0], ctx.queryKey[1])

      return (await response.data?.list) ?? []
    },
    staleTime: Infinity,
    enabled: requestSuccess,
  })

  const onClose = () => history.replace(KNOWMAP_INCOMING_APPOINTMENT_BOOKINGS)

  const [availableSlots, setAvailableSlots] = React.useState([])
  const [selectedSlot, setSelectedSlot] = React.useState(null)

  React.useEffect(() => {
    if (slotsDataSuccess && slotsData) {
      setAvailableSlots(slotsData || [])
      setSelectedDateRecord(slotsData?.[0])
    }
  }, [slotsDataSuccess])

  const onDateChange = (d) => {
    const dateRecord = availableSlots.find((fd) =>
      areEqualDates(d, new Date(fd.date))
    )

    if (!dateRecord) return

    setDate(d)
    setSelectedDateRecord(dateRecord)
    setSelectedTimeRecord(null)
  }

  const onTimeChange = (t) => {
    setSelectedTimeRecord(t)
  }

  const availableDates = availableSlots.map((d) => new Date(d.date))

  const isInAvailableDays = (day) =>
    availableDates.some((d) => areEqualDates(d, day))

  const [selectedTimezone, setSelectedTimezone] = React.useState(null)

  const creator = requestData?.creator

  const { isLoggedIn } = useSelector((state) => state.auth)

  const onSubmit = async () => {
    try {
      await call(APPOINTMENTS.SOLICIT.ACCEPT, {
        id: requestId,
        datetime: getUtcFromZonedDateTime(
          selectedDateRecord.date,
          selectedTimeRecord.start,
          "America/Chicago"
        ).toISOString(),
      })
    } catch (e) {
      errorAlert(e)
    }
  }

  const [isDeclining, toggleIsDeclining] = useToggle(false)

  const onDecline = async () => {
    try {
      await call(APPOINTMENTS.SOLICIT.DECLINE, { id: requestId })
      onClose()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"md"}
      fullWidth
    >
      <Container>
        <div className={"flex flex-col items-start justify-between"}>
          {requestSuccess && (
            <div className={"text-sm"}>
              <p className={"mb-4 text-xl font-medium"}>
                {requestData?.duration}-Minute Meeting
              </p>
              <div className={"max-h-100 overflow-y-auto"}>
                <div className={"mb-4"}>
                  <p className={"text-black54 text-sm"}>From:</p>
                  <div className="flex flex-col items-center gap-2 text-base">
                    <UserAvatar
                      user={creator}
                      photo={creator?.photo}
                      size={"md"}
                    />
                    <div>
                      {isLoggedIn ? (
                        <UserLink user={creator} interactive={false} newTab />
                      ) : (
                        <span>{getUserName(creator)}</span>
                      )}
                    </div>
                  </div>
                </div>
                {!!requestData?.email && (
                  <div className={"mb-2"}>
                    <p className={"text-black54 text-sm"}>Email:</p>
                    <p>{requestData?.email}</p>
                  </div>
                )}
                {!!requestData?.phone && (
                  <div className={"mb-2"}>
                    <p className={"text-black54 text-sm"}>Phone:</p>
                    <p>{requestData?.phone}</p>
                  </div>
                )}

                {!!requestData?.location && (
                  <div className={"mb-2"}>
                    <p className={"text-black54 text-sm"}>Physical location:</p>
                    <p>{requestData?.location}</p>
                  </div>
                )}
                {!!requestData?.conference_link && (
                  <div className={"mb-2"}>
                    <p className={"text-black54 text-sm"}>Conference link:</p>
                    <p>{requestData?.conference_link}</p>
                  </div>
                )}
                {!!requestData?.details && (
                  <div>
                    <p className={"text-black54 text-sm"}>Message:</p>
                    <p>{requestData?.details}</p>
                    <p>{requestData?.details}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div>
          <p className={"mb-4 text-xl font-medium text-center"}>
            Select a Date & Time
          </p>
          <div
            className={
              "flex flex-col justify-between max-h-100 overflow-y-auto"
            }
          >
            {/*{slotsDataSuccess && (*/}
            <ThemeProvider theme={appointmentCalendarTheme}>
              <MuiPickersUtilsProvider utils={WeekDays}>
                <DatePicker
                  autoOk
                  variant="static"
                  openTo="date"
                  value={date}
                  onChange={onDateChange}
                  disablePast
                  shouldDisableDate={(day) => {
                    return !isInAvailableDays(day)
                  }}
                  renderDay={(
                    day,
                    selectedDay,
                    isInCurrentMonth,
                    dayComponent
                  ) => {
                    const isHighlighted = !isPast(day) && isInAvailableDays(day)

                    return isHighlighted
                      ? React.cloneElement(dayComponent, {
                          style: { border: `1px solid ${colors.primary}` },
                        })
                      : dayComponent
                  }}
                />
              </MuiPickersUtilsProvider>
            </ThemeProvider>
            <div className={"mt-8"}>
              <TimezonePicker
                value={selectedTimezone}
                onChange={setSelectedTimezone}
              />
            </div>
            {/*)}*/}
          </div>
        </div>
        <div className={"flex flex-col gap-4"}>
          <div className={"flex-1"}>
            {slotsDataSuccess && !!selectedTimezone && (
              <>
                <p className={"mb-4 text-xl font-medium text-center"}>
                  {format(date, "eee, MMM do")}
                </p>
                <div className={"max-h-100 overflow-y-auto"}>
                  {selectedDateRecord?.slots?.length ? (
                    selectedDateRecord?.slots?.map((t) => (
                      <div
                        className={"flex gap-1 mb-2 pl-5 pr-5"}
                        key={t.start}
                      >
                        <Button
                          variant={"outlined"}
                          color={"primary"}
                          className={"flex-1"}
                          onClick={() => onTimeChange(t)}
                        >
                          {localizedDateTime(
                            selectedDateRecord.date,
                            t.start,
                            "America/Chicago",
                            selectedTimezone.id
                          )}
                        </Button>
                        {selectedTimeRecord?.start === t?.start && (
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            className={"flex-1"}
                            onClick={onSubmit}
                          >
                            Submit
                          </Button>
                        )}
                      </div>
                    ))
                  ) : (
                    <p className={"color-black-54 text-center"}>
                      No more time slots available.
                      <br />
                      Please, select another date.
                    </p>
                  )}
                </div>
              </>
            )}
          </div>

          <div className={"flex gap-2 justify-end"}>
            <Button
              variant={"text"}
              color={"primary"}
              onClick={toggleIsDeclining}
            >
              Decline
            </Button>
            <Button variant={"text"} color={"primary"} onClick={onClose}>
              Close
            </Button>
            {isDeclining && (
              <AlertDialog
                open={isDeclining}
                handleClose={toggleIsDeclining}
                handleAccept={onDecline}
                title={`Decline appointment request?`}
                message={`Are you sure you want to decline appointment request from ${getUserName(
                  creator
                )}?`}
                declineButtonTitle={"Cancel"}
                acceptButtonTitle={"OK"}
              />
            )}
          </div>
        </div>
      </Container>
    </Dialog>
  )
}

export default IncomingSolicitAppointmentFormModal
