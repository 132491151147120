import React from "react"
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import UserLink from "../../../../../components/v2/utils/UserLink"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import { minutesToString } from "../../../../../library/utils/timeConvert"
import returnExternalUrl from "../../../../../library/utils/returnExternalUrl"
import { Tooltip } from "@material-ui/core"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"
import CheckIcon from "@material-ui/icons/CheckCircle"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { formatBytes } from "../../../../../library/utils/fileSizeConverter"
import Loader from "../../../../../components/ui/loader"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import Button from "@material-ui/core/Button/Button"
import { useCourseMeet } from "../../../../../hooks/data/meets/courseMeets"
import { copyLink } from "../../../../../library/utils/links"
import { useAlert } from "../../../../../hooks/useAlert"
import { generatePath } from "react-router-dom"
import { INCOMING_COURSE_MEET } from "../../../../../library/constants/routes"

const StepMeetInfoDialog = ({ open, onClose, activeMeet, isConfirmable }) => {
  const { successAlert } = useAlert()

  const { meet, isSuccess } = useCourseMeet({
    id: activeMeet,
    options: { enabled: !!activeMeet },
  })

  const onCopyLink = async () => {
    await copyLink(generatePath(INCOMING_COURSE_MEET, { meetId: activeMeet }))
    successAlert("Link copied to clipboard")
  }

  const onForward = () => {}
  const onAttended = () => {}
  const onAccept = () => {}
  const onDecline = () => {}
  const onForwardCurrentUser = () => {}

  return (
    <Dialog
      aria-labelledby="create-record-dialog"
      onClose={onClose}
      open={open}
    >
      <DialogTitle id="alert-dialog-title" className="text-center">
        Meet details
      </DialogTitle>
      <DialogContent>
        {isSuccess ? (
          <div className="f jcc full-width">
            <table className="product-details product-details--invenstory-record">
              <tbody>
                <tr className="product-details__row">
                  <td className="product-details__label">Name</td>
                  <td className="product-details__value">{meet.title}</td>
                </tr>

                <tr className="product-details__row">
                  <td className="product-details__label">Teacher</td>
                  <td className="product-details__value">
                    {meet?.teacher ? <UserLink user={meet.user} /> : "—"}
                  </td>
                </tr>

                {!!meet.cohort_size && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Cohort size</td>
                    <td className="product-details__value">
                      {meet.cohort_size}
                    </td>
                  </tr>
                )}

                {!!meet?.target_datetime && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Target date and time
                    </td>
                    <td className="product-details__value">
                      {safeFormatDate(meet.target_datetime, {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}

                {!!meet?.target_interval && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Target interval</td>
                    <td className="product-details__value">
                      {minutesToString(meet?.target_interval)}
                    </td>
                  </tr>
                )}

                {meet?.b_creator && !!meet?.require_full_cohort && (
                  <tr className="product-details__row">
                    <td className="product-details__label"></td>
                    <td className="product-details__value">
                      <span>
                        * Require full cohort, rescheduling for all if a
                        participant who accepted in that cohort pushes it
                        forward
                      </span>

                      {/*{minutesToString(meet?.target_interval)}*/}
                    </td>
                  </tr>
                )}

                {meet?.b_creator && !!meet?.allow_over_cohort && (
                  <tr className="product-details__row">
                    <td className="product-details__label"></td>
                    <td className="product-details__value">
                      <span>
                        * If a full cohort sets a Meet and then more
                        participants accept, add them if it is within an 15
                        minutes prior
                      </span>

                      {/*{minutesToString(meet?.target_interval)}*/}
                    </td>
                  </tr>
                )}

                {!!(meet?.cohort_current_datetime && meet?.cohort_current) && (
                  <tr className="product-details__row">
                    <td className="product-details__label">
                      Set date and time
                    </td>
                    <td className="product-details__value">
                      {safeFormatDate(meet.cohort_current_datetime, {
                        withTime: true,
                      })}
                    </td>
                  </tr>
                )}

                {!!meet?.duration && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Duration</td>
                    <td className="product-details__value">
                      {minutesToString(meet?.duration)}
                    </td>
                  </tr>
                )}

                {!!meet.space?.id && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Space</td>
                    <td className="product-details__value">
                      {meet.space.name}
                    </td>
                  </tr>
                )}

                {!!meet?.conference_link && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Conference link</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        <a
                          href={`${returnExternalUrl(meet.conference_link)}`}
                          className="link"
                        >
                          {meet.conference_link}
                        </a>
                      </ul>
                    </td>
                  </tr>
                )}

                {!!meet.instructions && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Instructions</td>
                    <td className="product-details__value">
                      {meet.instructions}
                    </td>
                  </tr>
                )}

                {!!meet.reminder && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Reminder</td>
                    <td className="product-details__value">{meet.reminder}</td>
                  </tr>
                )}

                {!!meet?.participants?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Participants</td>
                    <td className="product-details__value">
                      <div className={"full-width"}>
                        {meet.participants.map((user) => (
                          <div className="mb5 full-width f jcsb" key={user.id}>
                            <div>
                              <UserLink user={user} />
                              {meet?.creator && (
                                <>
                                  {!!user.accepted && (
                                    <span className={"font-12 color-black-54"}>
                                      Accepted at{" "}
                                      {safeFormatDate(user.updated_accepted, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.forwarded && (
                                    <span className={"font-12 color-black-54"}>
                                      Forwarded at{" "}
                                      {safeFormatDate(user.updated_forwarded, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.declined && (
                                    <span className={"font-12 color-black-54"}>
                                      Declined at{" "}
                                      {safeFormatDate(user.updated_declined, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.removed && (
                                    <span className={"font-12 color-black-54"}>
                                      Removed at{" "}
                                      {safeFormatDate(user.updated_removed, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                  {!!user.attended && (
                                    <span className={"font-12 color-black-54"}>
                                      Attended at{" "}
                                      {safeFormatDate(user.updated_attended, {
                                        withTime: true,
                                      })}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                            {meet?.b_creator && (
                              <div className="f aic">
                                {!user.forwarded && user.accepted && (
                                  <Tooltip title="Move to next cohort" arrow>
                                    <button
                                      type={"button"}
                                      onClick={() => onForward({ user })}
                                      className={"f"}
                                    >
                                      <UpdateIcon color={"primary"} />
                                    </button>
                                  </Tooltip>
                                )}
                                {!user.removed &&
                                  !user.attended &&
                                  !user.forwarded && (
                                    <Tooltip title="Mark as present" arrow>
                                      <button
                                        type={"button"}
                                        onClick={() => onAttended({ user })}
                                        className={"f"}
                                      >
                                        <CheckIcon color={"primary"} />
                                      </button>
                                    </Tooltip>
                                  )}
                                {/*{!user.removed && !user.attended && (*/}
                                {/*  <Tooltip title="Remove from meet" arrow>*/}
                                {/*    <button*/}
                                {/*      type={"button"}*/}
                                {/*      onClick={() =>*/}
                                {/*        onRemove({user})*/}
                                {/*      }*/}
                                {/*      className={'f'}*/}
                                {/*    >*/}
                                {/*      <CloseIcon color={"primary"} />*/}
                                {/*    </button>*/}
                                {/*  </Tooltip>*/}
                                {/*)}*/}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                )}

                {!!meet?.other_links?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Links</td>
                    <td className="product-details__value">
                      <ul className="text-list">
                        {meet.other_links.map((link) => (
                          <li key={link}>
                            <a
                              href={`${returnExternalUrl(link)}`}
                              className="link"
                            >
                              {link}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                )}

                {!!meet?.blobs?.length && (
                  <tr className="product-details__row">
                    <td className="product-details__label">Attachments</td>
                    <td className="product-details__value">
                      <div className="post__files">
                        {meet.blobs.map(({ id, name, size, url }) => {
                          return (
                            <a
                              href={url}
                              target="_blank"
                              className="post__file"
                              key={id}
                            >
                              <InsertDriveFile
                                className="post__file-icon"
                                style={{ fontSize: "30px" }}
                              />
                              <div className="post__file-info">
                                <span className="link link--inline">
                                  {name}
                                </span>
                                <span className="post__file-size">
                                  {formatBytes(size)}
                                </span>
                              </div>
                            </a>
                          )
                        })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        ) : (
          <Loader />
        )}
      </DialogContent>
      <DialogActions>
        <div className="f jcsb full-width">
          <div>
            {isSuccess && (
              <>
                {isConfirmable && !meet?.b_creator && (
                  <>
                    {!meet.accepted && !meet.declined && (
                      <Button color="primary" onClick={onAccept}>
                        Accept
                      </Button>
                    )}
                    {/*{!meet.accepted && !meet.declined && (*/}
                    {/*  <Button*/}
                    {/*    color="primary"*/}
                    {/*    onClick={() => onAssignHoldOpen(meetId)}*/}
                    {/*  >*/}
                    {/*    Assign own hold*/}
                    {/*  </Button>*/}
                    {/*)}*/}
                    {!meet.accepted && !meet.declined && (
                      <Button color="primary" onClick={onDecline}>
                        Decline
                      </Button>
                    )}
                    {meet.accepted && !meet.declined && (
                      <Button color="primary" onClick={onForwardCurrentUser}>
                        Move to next cohort
                      </Button>
                    )}
                  </>
                )}

                {!!meet.b_creator && (
                  <Button color="primary" onClick={onCopyLink}>
                    Copy link
                  </Button>
                )}
              </>
            )}
          </div>
          <Button color="primary" onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default StepMeetInfoDialog
