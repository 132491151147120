import React from "react"

import "./userProfile.scss"
import { generatePath, Route } from "react-router-dom"
import UserProfileHeader from "./userProfileHeader/userProfileHeader"
import PostModal from "../../feed/postModal/postModal"
import * as routes from "../../../../library/constants/routes"
import { BOOKMARKS_PEOPLE } from "../../../../library/constants/routes"
import NotFound from "../../../../components/notFound/notFound"
import UserError from "../userError/userError"
import BookMeetModal from "../../../bookMeet/bookMeetModal"
import BookMeetProvider from "../../../bookMeet/bookMeetContext"
import BoxedList from "../../../../components/ui/boxedList"
import ProfileUpcomingAppointments from "./userProfileCalendar/ProfileUpcomingAppointments"
import CalendarProvider from "../../calendar/CalendarContext"
import ProfilePageSkeleton from "../../../../components/v2/layout/skeleton/profilePage/profilePageSkeleton"
import { useUser } from "../../../../hooks/data/user/useUser"
import { useIdParam } from "../../../../hooks/useIdParam"
import {
  PageContentLayout,
  PageMain,
  PageRightSidebar,
} from "../../../../components/v2/layout/layout"
import UserProfileCreds from "./userProfileKnowcred"
import BookmarksProvider from "../../../../hooks/modals/useBookmarksModal"
import { mutationTypes } from "../../../../library/utils/reactQuery"
import ProfileHowToBanner from "./userProfileHeader/components/ProfileHowToBanner"
import ComplaintsProvider from "../../../../hooks/modals/useComplaintsModal"
import UserFeed from "./userProfileFeed/userFeed"
import SEO from "../../../../components/SEO"
import { getUserName } from "../../../../library/utils/user/userUtils"
import { useSelector } from "react-redux"
import ProfileSchedule from "./userProfileCalendar/ProfileSchedule"

const UserProfile = () => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)

  const userId = useIdParam("userId")
  const { user, isCurrentUser, isSuccess, isLoading, isFetching, error, key } =
    useUser(userId)

  const [isContentVisible, setIsContentVisible] = React.useState(true)

  React.useEffect(() => {
    if (isSuccess && user.is_blocking) {
      setIsContentVisible(false)
    }
  }, [])

  if (error?.error?.code === 20100) return <NotFound entity="User" />

  if (!isSuccess && isLoading) return <ProfilePageSkeleton />

  if (user.blocked || user.deleted || user.is_blocked) {
    return <UserError user={user} />
  }

  return (
    <div className="grid auto-rows-max gap-4">
      <PageContentLayout>
        <SEO title={getUserName(user)} />
        <PageMain>
          <BookmarksProvider
            keyToUpdate={key}
            params={{ user_id: user.id }}
            pathToBookmarks={BOOKMARKS_PEOPLE}
            mutationType={mutationTypes.record}
          >
            <ComplaintsProvider
              entityUpdater={(u) => ({ ...u, is_reported: true })}
              keyToUpdate={key}
              params={{ user_id: user.id }}
            >
              <UserProfileHeader isContentVisible={isContentVisible} />
            </ComplaintsProvider>
          </BookmarksProvider>
          {isLoggedIn && isCurrentUser && <ProfileHowToBanner />}
          {isLoggedIn && <UserFeed />}
        </PageMain>
        <PageRightSidebar>
          {isLoggedIn && isCurrentUser && (
            <>
              <CalendarProvider contextType={"upcoming-appointments"}>
                <ProfileUpcomingAppointments />
              </CalendarProvider>
              {[1, 2].includes(user.role) && <ProfileSchedule />}
            </>
          )}
          {!!user.friends_number && !!user.friends && (
            <BoxedList
              title="Friends"
              counter={user.friends_number}
              list={user.friends}
              entity="users"
              linkShowAll={generatePath(routes.FRIENDS, {
                userId: user.id,
              })}
            />
          )}
          {isLoggedIn && <UserProfileCreds />}
        </PageRightSidebar>
      </PageContentLayout>

      <Route
        path={routes.USER_POST}
        render={(props) => (
          <PostModal
            {...props}
            userInfo={user}
            type={isCurrentUser ? "my-profile" : "other-profile"}
          />
        )}
      />

      <Route
        path={routes.BOOK_MEET_AUTHORIZED}
        render={(props) => (
          <BookMeetProvider>
            <BookMeetModal {...props} />
          </BookMeetProvider>
        )}
      />
    </div>
  )
}

export default UserProfile
