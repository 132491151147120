import React from "react"
import {
  useGroup,
  useGroupMutations,
} from "../../../../../../hooks/data/groups/groups"
import { Box } from "../../../../../../components/tw/layout"
import logoDefault from "../../../../../../assets/img/product.svg"
import EntityPhotoPicker from "../../../../../../components/EntityPhotoPicker/EntityPhotoPicker"

const GroupLogo = () => {
  const { group, groupLogo, canEdit, queryKey } = useGroup()
  const { updateGroup } = useGroupMutations({ queryKey })

  const onPhotoUpdate = async (full_image) => {
    await updateGroup.mutateAsync({ id: group.id, full_image })
  }

  return (
    <Box>
      <div className="product-logo mb-4">
        <img src={groupLogo} alt="Logo" className="product-logo__img" />
        {canEdit && (
          <EntityPhotoPicker
            photo={groupLogo}
            defaultPhoto={logoDefault}
            onSave={onPhotoUpdate}
            buttonClassName={"right-0 top-0"}
          />
        )}
      </div>
      <h2 className={"font-medium text-center text-lg"}>{group.name}</h2>
    </Box>
  )
}

export default GroupLogo
