import React from "react"
import { getUserName } from "../../library/utils/user/userUtils"
import { useBookMeet } from "./bookMeetContext"
import { addMinutes, format } from "date-fns"
import { getUtcFromZonedDateTime } from "../../library/utils/dateConvert"
import withErrorBoundary from "../../hocs/guards/withErrorBoundary"
import TimezoneLabel from "../../components/timezones/timezoneLabel"
import UserAvatar from "../../components/userAvatar"

const formatDate = (date) => format(date, "eee, MMM do")
const formatTime = (date) => format(date, "h:mm aaa")

const SelectedMeetData = () => {
  const { user, data, step, selectedTimezone } = useBookMeet()

  let startTime,
    endTime,
    formattedDate = null

  if (data.date && data.time && step === 4) {
    const generatedDateTime = getUtcFromZonedDateTime(
      data.date.date,
      data.time.start,
      user.time_zone || "America/Chicago"
    )

    startTime = formatTime(generatedDateTime)
    endTime = formatTime(addMinutes(generatedDateTime, data.type))
    formattedDate = formatDate(generatedDateTime)
  }

  return (
    <div>
      <div className={"mb-4"}>
        <UserAvatar size={"lg"} user={user} photo={user.photo} />
      </div>
      <p className={"mb-4 text-lg"}>{getUserName(user)}</p>
      <p className={"mb-4 text-xl font-medium"}>{data.type}-Minute Meeting</p>
      {data.hold && (
        <p className={"mb-4 text-black54"}>
          Hold: <span className={"text-black87"}>{data.hold.name}</span>
        </p>
      )}
      {step === 4 && (
        <>
          <div className={"mb-2"}>
            <p className={"font-medium"}>{formattedDate}</p>
            <p className={"font-medium"}>
              {startTime} - {endTime}
            </p>
            <TimezoneLabel
              tz={selectedTimezone.id}
              className={"text-black54"}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default withErrorBoundary(SelectedMeetData)
