import React from "react"
import { useIdParam } from "../../../../../../hooks/useIdParam"
import SuperListPage from "../../../../../../components/v2/layout/superListPage/superListPage"
import { GROUPS } from "../../../../../../library/store/actions/types/groupsTypes"
import { getCardSize } from "../../../../../../library/constants/styles/cardSizes"
import { User } from "../../../../../../library/utils/user/userUtils"
import Card from "../../../../../../components/v2/layout/cards/card"
import {
  groupMemberTagsIds,
  groupRolesIds,
} from "../../../../../../library/constants/userRoles"
import { renderArrayOfNames } from "../../../../../../library/utils/arrays"
import {
  useGroup,
  useGroupMemberMutations,
} from "../../../../../../hooks/data/groups/groups"
import { useCurrentUser } from "../../../../../../hooks/data/user/useUser"
import { useToggle } from "../../../../../../hooks/useToggle"
import GroupMemberRoleModal from "./components/GroupMemberRoleModal"
import GroupMemberOccupationsModal from "./components/GroupMemberOccupationsModal"

const GroupSettingsAdminsPage = () => {
  const groupId = useIdParam("groupId")
  const { group } = useGroup()
  const { id: currentUserId } = useCurrentUser()

  const queryKey = [GROUPS.PARTICIPANTS.LIST, { group_id: groupId, role: 2 }]

  const { member } = useGroupMemberMutations({ queryKey })

  const onRemoveMember = async (userId) => {
    await member.remove.mutateAsync({ group_id: groupId, user_id: userId })
  }

  const [activeUser, setActiveUser] = React.useState(null)

  const [isMemberRoleModalOpen, toggleMemberRoleModal] = useToggle(false)

  const [isMemberOccupationsModalOpen, toggleMemberOccupationsModal] =
    useToggle(false)

  const onMemberRoleModalOpen = (user) => {
    setActiveUser(user)
    toggleMemberRoleModal()
  }

  const onMemberRoleModalClose = () => {
    toggleMemberRoleModal()
    setActiveUser(null)
  }

  const onMemberOccupationsModalOpen = (user) => {
    setActiveUser(user)
    toggleMemberOccupationsModal()
  }

  const onMemberOccupationsModalClose = () => {
    toggleMemberOccupationsModal()
    setActiveUser(null)
  }

  return (
    <>
      <SuperListPage
        queryConfig={{
          key: queryKey,
          fetchParams: {
            group_id: groupId,
            role: 2,
          },
          listKey: "users",
        }}
        headerProps={{
          title: "Management",
        }}
        noResultsLabel={"Group doesn't have members yet"}
        listItemHeight={getCardSize(10).numValPx}
        getListItemComponent={({ item }) => {
          const __user = new User(item)

          return (
            <Card
              key={item.id}
              size={getCardSize(10).numValPx}
              name={__user.name}
              photo={__user.photo}
              link={__user.path}
              stats={groupRolesIds[__user.group_role]}
              textAboveName={__user.accountRole}
              infoComponent={() => (
                <div className={"space-y-1"}>
                  {!!item.tags.length && (
                    <p className="">
                      Occupation:{" "}
                      {renderArrayOfNames(
                        item.tags.map((tag) => ({
                          name: groupMemberTagsIds[tag],
                        }))
                      )}
                    </p>
                  )}
                  {__user.hasLocation && (
                    <p className={""}>{__user.location}</p>
                  )}
                  {__user.hasHighlightedInfo && <p>{__user.highlightedInfo}</p>}
                </div>
              )}
              controls={{
                dropdownMenu: {
                  items:
                    group?.creator?.id !== item.id && item.id !== currentUserId
                      ? [
                          {
                            type: "button",
                            action: () => onMemberRoleModalOpen(item),
                            label: "Change role",
                          },
                          {
                            type: "button",
                            action: () => onMemberOccupationsModalOpen(item),
                            label: "Change occupation",
                          },

                          { type: "h-divider", id: "hd1" },

                          {
                            type: "button",
                            action: () => onRemoveMember(item.id),
                            label: "Remove from group",
                          },
                        ]
                      : [
                          {
                            type: "button",
                            action: () => onMemberOccupationsModalOpen(item),
                            label: "Change occupation",
                          },
                        ],
                },
              }}
            />
          )
        }}
      />
      {isMemberRoleModalOpen && (
        <GroupMemberRoleModal
          user={activeUser}
          open={isMemberRoleModalOpen}
          onClose={onMemberRoleModalClose}
          queryKey={queryKey}
        />
      )}

      {isMemberOccupationsModalOpen && (
        <GroupMemberOccupationsModal
          open={isMemberOccupationsModalOpen}
          handleClose={onMemberOccupationsModalClose}
          user={activeUser}
          queryKey={queryKey}
        />
      )}
    </>
  )
}

export default GroupSettingsAdminsPage
