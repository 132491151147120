import React from "react"
import Loader from "../../ui/loader"
import PlainLink from "../../ui/link"
import { HtmlTooltip } from "../../../screens/main/calendar/components/eventsCalendar/components/calendarUI"
import { useQuery } from "@tanstack/react-query"
import { USERS } from "../../../library/networking/apiEndpoints"
import { User } from "../../../library/utils/user/userUtils"
import styled from "@emotion/styled"
import LockIcon from "@material-ui/icons/LockOutlined"
import { colors } from "../../../library/constants/styles/colors"
import { GROUP_TYPES } from "../../../library/constants/groupTypes"
import { userRolesIds } from "../../../library/constants/userRoles"

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  background-color: #fff;
`

const UserInfoTooltip = ({ user, children }) => {
  const { data, isLoading, isSuccess, isError, refetch } = useQuery({
    queryKey: [
      USERS.GET,
      { id: user.id || user.user_id, friends_limit: 0, products_limit: 0 },
      "user-data-preview",
    ],
    staleTime: 60000,
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
    retry: 1,
    enabled: false,
  })

  const [shouldFetch, setShouldFetch] = React.useState(false)

  React.useEffect(() => {
    if (shouldFetch) {
      refetch()
    }
  }, [shouldFetch])

  const userData = new User({ ...user, ...data })

  const path = userData.path
  const userName = userData.name
  const userPhoto = userData.photo

  return (
    <HtmlTooltip
      onOpen={() => setShouldFetch(true)}
      onClose={() => setShouldFetch(false)}
      interactive
      placement={"bottom-start"}
      title={
        <>
          <div style={{ minWidth: 100 }}>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={"flex items-center"}>
                <Avatar src={userPhoto} />
                <div>
                  <div>
                    <p className={"text-black54"}>{userData.accountRole}</p>
                    <PlainLink newTab bold to={path} inline>
                      {userName}
                    </PlainLink>
                    {userData.hasHighlightedInfo && (
                      <p>{userData.highlightedInfo}</p>
                    )}
                    {userData.hasLocation && (
                      <p className={"text-black54"}>{userData.location}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={"inline-block"}>{children}</div>
    </HtmlTooltip>
  )
}

export default UserInfoTooltip
