import React from "react"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { Collapse } from "@material-ui/core"
import { useToggle } from "../../../../../../hooks/useToggle"
import TimezonePicker from "../../../../../../components/timezones/TimezonePicker"
import { PlainButton } from "../../../../../../components/v2/controls"
import { useCurrentUser } from "../../../../../../hooks/data/user/useUser"
import { useUserInfoMutations } from "../../../../../../hooks/data/user/useUserInfoMutations"
import { Submit } from "../../../../../../components/v2/layout/forms/form/form"

const ProfileTimezoneForm = () => {
  const { user, queryKey } = useCurrentUser()
  const { updateUserInfo } = useUserInfoMutations({ queryKey })

  const [isOpen, toggleOpen] = useToggle(false)

  const [timeZone, setTimeZone] = React.useState(user?.time_zone)

  const onSubmit = async () => {
    await updateUserInfo.mutateAsync({ time_zone: timeZone?.id })
  }

  return (
    <div>
      <div
        role={"button"}
        onClick={toggleOpen}
        className="grid grid-cols-[1fr,_max-content] gap-8 items-center p-4 cursor-pointer bg-primary5 hover:bg-primary15"
      >
        <p>Preferred time zone</p>
        <div>
          {!isOpen ? (
            <KeyboardArrowDown className="text-black38" />
          ) : (
            <KeyboardArrowUp className="text-black38" />
          )}
        </div>
      </div>
      <Collapse in={isOpen}>
        <div className="p-4">
          <TimezonePicker
            allowEmpty
            withLeftLabel
            value={timeZone}
            onChange={setTimeZone}
          />
          {!timeZone && (
            <div className={"mb15 space-y-1"}>
              <p>
                Please select your timezone to have proper time in your bookings
              </p>
              <p>
                We assume that your current time zone is:{" "}
                <span className={"italic font-medium"}>
                  {Intl.DateTimeFormat().resolvedOptions().timeZone}
                </span>
                .
              </p>
              <div className={"flex items-baseline gap-2"}>
                <span>Do you want us to save it?</span>
                <div className="flex items-center">
                  <PlainButton>Yes</PlainButton>
                  <span> / </span>
                  <PlainButton>No</PlainButton>
                </div>
              </div>
            </div>
          )}
          <div className="flex justify-center">
            <Submit
              color="primary"
              variant="contained"
              onClick={onSubmit}
              disabled={!timeZone || timeZone?.id === user?.time_zone}
            >
              Save
            </Submit>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

export default ProfileTimezoneForm
