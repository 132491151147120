import React from "react"
import { useSelector } from "react-redux"
import Button from "@material-ui/core/Button"
import { call } from "../../../library/networking/API"
import * as endpoints from "../../../library/networking/apiEndpoints"
import SEO from "../../../components/SEO"
import AuthPageLayout from "../../auth/components/AuthPageLayout"
import AuthFormLayout from "../../auth/components/AuthFormLayout"
import TextInput from "../../../components/v2/inputs/textInput/textInput"
import { Form, Formik } from "formik"
import * as yup from "yup"
import { useAlert } from "../../../hooks/useAlert"

const validationSchema = yup.object({
  name: yup.string().max(75).required(),
  email: yup.string().email().required(),
  subject: yup.string().max(150).required(),
  message: yup.string().max(500).required(),
})

const ContactForm = () => {
  const { errorAlert, successAlert } = useAlert()
  const { userData } = useSelector((state) => state.auth)

  const initialValues = {
    name: userData ? `${userData.first_name} ${userData.last_name}` : "",
    email: userData ? userData.email : "",
    subject: "",
    message: "",
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      await call(endpoints.USERS.CONTACT, {
        ...values,
      })

      resetForm()

      successAlert(
        "Your message has been sent successfully. The answer from support will come to your e-mail."
      )
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <AuthPageLayout shouldRedirectIfLoggedIn={false}>
      <SEO title="Sign In" />
      <AuthFormLayout title={"Contact us"}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnBlur={false}
        >
          <Form className={"w-full space-y-6"}>
            <TextInput name={"name"} label={"Your name *"} disabled />
            <TextInput name={"email"} label={"Your Email *"} disabled />
            <TextInput name={"subject"} label={"Subject *"} />
            <TextInput name={"message"} label={"Description *"} />

            <div className="flex justify-center full-width">
              <Button type={"submit"} variant={"contained"} color={"primary"}>
                Send
              </Button>
            </div>
          </Form>
        </Formik>
      </AuthFormLayout>
    </AuthPageLayout>
  )
}
export default ContactForm
