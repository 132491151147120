import { useMutation, useQueryClient } from "@tanstack/react-query"
import { call } from "../../../library/networking/API"
import { USERS } from "../../../library/networking/apiEndpoints"

export const useUserInfoMutations = ({ queryKey, options = {} }) => {
  const queryClient = useQueryClient()

  const updateUserInfo = useMutation({
    mutationFn: async ({
      first_name,
      last_name,
      country,
      city,
      state,
      status,
      interests,
      full_image,
      full_header,
      phones,
      favorite_films,
      favorite_books,
      favorite_games,
      favorite_quotes,
      favorite_subjects,
      birthdate,
      invenstory_preferences,
      restore,
      highlighted,
      backup_email,
      time_zone,
    }) => {
      await call(USERS.UPDATE_INFO, {
        first_name,
        last_name,
        country,
        city,
        state,
        status,
        interests,
        full_image,
        full_header,
        phones,
        favorite_films,
        favorite_books,
        favorite_games,
        favorite_quotes,
        favorite_subjects,
        birthdate,
        invenstory_preferences,
        restore,
        highlighted,
        backup_email,
        time_zone,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: ["currentUser"] })
    },
  })

  const addWork = useMutation({
    mutationFn: async ({
      name,
      role,
      country,
      city,
      description,
      date_from,
      date_to,
    }) => {
      await call(USERS.WORK_ADD, {
        name,
        role,
        country,
        city,
        description,
        date_from,
        date_to,
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const updateWork = useMutation({
    mutationFn: async ({
      id,
      name,
      role,
      country,
      city,
      description,
      date_from,
      date_to,
    }) => {
      await call(USERS.WORK_UPDATE, {
        id,
        name,
        role,
        country,
        city,
        description,
        date_from,
        date_to,
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const deleteWork = useMutation({
    mutationFn: async (id) => {
      await call(USERS.WORK_DELETE, { id })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const highlightWork = useMutation({
    mutationFn: async (id) => {
      await call(USERS.WORK_HIGHLIGHT, { id })
    },
    // onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const addEducation = useMutation({
    mutationFn: async ({
      name,
      type,
      department,
      country,
      city,
      date_from,
      date_to,
    }) => {
      await call(USERS.EDUCATION_ADD, {
        name,
        type,
        department,
        country,
        city,
        date_from,
        date_to,
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const updateEducation = useMutation({
    mutationFn: async ({
      id,
      name,
      type,
      department,
      country,
      city,
      date_from,
      date_to,
    }) => {
      await call(USERS.EDUCATION_UPDATE, {
        id,
        name,
        type,
        department,
        country,
        city,
        date_from,
        date_to,
      })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const deleteEducation = useMutation({
    mutationFn: async (id) => {
      await call(USERS.EDUCATION_DELETE, { id })
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const highlightEducation = useMutation({
    mutationFn: async (id) => {
      await call(USERS.EDUCATION_HIGHLIGHT, { id })
    },
    // onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  return {
    updateUserInfo,
    work: {
      add: addWork,
      update: updateWork,
      delete: deleteWork,
      highlight: highlightWork,
    },
    education: {
      add: addEducation,
      update: updateEducation,
      delete: deleteEducation,
      highlight: highlightEducation,
    },
  }
}
