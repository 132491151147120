import React from "react"
import HoldCard from "../../../../../components/v2/layout/cards/holdCard"
import CalendarHoldFormModal from "../../../calendar/subpages/holds/componenst/calendarHoldFormModal/calendarHoldFormModal"
import CalendarHoldInfoDialog from "../../../calendar/subpages/holds/componenst/calendarHoldInfoDialog/calendarHoldInfoDialog"
import { useParams } from "react-router-dom"
import { useHoldManage } from "../../../../../hooks/data/calendar/useHold"
import SuperListPage from "../../../../../components/v2/layout/superListPage/superListPage"
import { USER } from "../../../../../library/store/actions/types/authTypes"
import { withPagePermissions } from "../../../../../permissionChecker/withPagePermissions"
import { getCardSize } from "../../../../../library/constants/styles/cardSizes"
import Button from "@material-ui/core/Button/Button"
import ActionWithPermissions from "../../../../../components/v2/controls/buttons/ActionWithPermissions"
import {
  useSubscriptions,
  useSubscriptionsQuery,
} from "../profileSettingsSubscriptions/useSubscriptions"

const ProfileSettingsHolds = () => {
  const params = useParams()
  const listQueryKey = [USER.HOLDS.LIST]

  const { createHold, editHold, deleteHold } = useHoldManage({
    queryKey: listQueryKey,
    user: true,
  })

  const { isSuccess } = useSubscriptionsQuery()

  return (
    isSuccess && (
      <SuperListPage
        queryConfig={{
          key: [USER.HOLDS.LIST],
          listKey: "list",
        }}
        headerProps={{
          title: "Holds",
          button: {
            getComponent: ({
              openFormDialog,
              itemsCount,
              isInitialLoading,
            }) => {
              if (isInitialLoading) return null
              if (itemsCount < 1) {
                return (
                  <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => openFormDialog()}
                  >
                    Create one hold for free
                  </Button>
                )
              } else {
                return (
                  <ActionWithPermissions
                    component={Button}
                    variant={"contained"}
                    color={"primary"}
                    action={() => openFormDialog()}
                    paidOnly
                    allowGrantedSubscription
                  >
                    Create hold
                  </ActionWithPermissions>
                )
              }
            },
          },
        }}
        noResultsLabel={"You don't have Holds yet"}
        listItemHeight={getCardSize(7).numValPx}
        getListItemComponent={({
          item,
          openEditDialog,
          openDeletionDialog,
          openInfoDialog,
        }) => (
          <HoldCard
            hold={item}
            openDeleteHold={openDeletionDialog}
            openEditHold={openEditDialog}
            openHoldDetails={openInfoDialog}
          />
        )}
        getFormDialogComponent={({ open, onClose, activeItem }) => {
          return (
            <CalendarHoldFormModal
              open={open}
              handleClose={onClose}
              onSubmit={
                !activeItem ? createHold.mutateAsync : editHold.mutateAsync
              }
              activeHold={activeItem}
              edit={!!activeItem}
              user={true}
            />
          )
        }}
        getDeletionDialogComponent={{
          entityName: "hold",
          onDelete: deleteHold.mutateAsync,
        }}
        getInfoDialogComponent={({ open, onClose, activeItem }) => (
          <CalendarHoldInfoDialog
            open={open}
            onClose={onClose}
            activeHold={activeItem}
            user={true}
          />
        )}
      />
    )
  )
}

export default ProfileSettingsHolds

// export default withPagePermissions({
//   allowedPlans: [1, 2, 3, 4],
//   pageTitle: "Holds",
//   allowGrantedSubscription: true,
// })(ProfileSettingsHolds)
