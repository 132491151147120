import React from "react"
import { useSelector } from "react-redux"
import {
  generatePath,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import { clsx } from "clsx"

const AuthPageLayout = ({
  shouldRedirectIfLoggedIn = true,
  redirectRouteIfLoggedIn,
  children,
  className = "",
}) => {
  const params = useParams()
  const location = useLocation()
  const { isLoggedIn, userData } = useSelector((state) => state.auth)

  if (isLoggedIn && userData?.id && shouldRedirectIfLoggedIn) {
    const redirectTo = redirectRouteIfLoggedIn
      ? redirectRouteIfLoggedIn
      : generatePath(routes.USER, { userId: userData.id })

    console.log("redirectTo", redirectTo)

    return (
      <Redirect
        to={{
          pathname: redirectTo,
          state: { from: location },
        }}
      />
    )
  } else {
    return (
      <div className={clsx("container relative py-8 h-full", className)}>
        {children}
      </div>
    )
  }
}

export default AuthPageLayout
