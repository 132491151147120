import { USER } from "../types/authTypes"
import { call, nestAPI } from "../../../networking/API"
import * as endpoints from "../../../networking/apiEndpoints"
import { LIMIT } from "../../../constants/limits"
import { FRIENDS } from "../types/friendsTypes"
import { APPOINTMENTS } from "../types/calendarTypes"
import { GROUPS } from "../types/groupsTypes"

export const signIn = ({ email, password }) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.SIGNIN, {
      email,
      password,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.SIGNED_IN,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const signUp = ({
  first_name,
  last_name,
  email,
  password,
  role,
  education,
  work,
  country,
  state,
  city,
  newFriendId,
  inviterGroupId,
  age_consent,
}) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.SIGNUP, {
      first_name,
      last_name,
      email,
      password,
      role,
      education,
      work,
      country,
      state,
      city,
      age_consent,
    })

    const json = await response.data

    if (response.status === 200) {
      if (newFriendId) {
        await call(FRIENDS.REQUESTS.SEND, {
          token: json.token,
          user_id: newFriendId,
        })
      }

      if (inviterGroupId) {
        await call(GROUPS.JOIN, {
          token: json.token,
          group_id: inviterGroupId,
        })
      }

      return dispatch({
        type: USER.SIGNED_UP,
        payload: json,
      })
    } else {
      throw json.error
    }
  }
}

export const confirmSignup = (id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.CONFIRM_SIGNUP, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.SIGN_UP_CONFIRMED,
      })
    } else {
      throw json.error
    }
  }
}

export const denySignup = (id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.DENY_SIGNUP, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.SIGN_UP_DENIED,
      })
    } else {
      throw json.error
    }
  }
}

export const signOut = () => {
  return async (dispatch) => {
    return dispatch({
      type: USER.SIGNED_OUT,
    })
  }
}

export const deleteUser = (password) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.DELETE, {
      token: getState().auth.userData.token,
      password,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.ACCOUNT_DELETED,
      })
    } else {
      throw json.error
    }
  }
}

export const resetPasswordRequest = (email) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.REQUEST_PASSWORD_RESET, {
      email,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.PASSWORD_RESET,
      })
    } else {
      throw json.error
    }
  }
}

export const resetPasswordApply = (password, id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.CONFIRM_PASSWORD_RESET, {
      password,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.PASSWORD_RESET,
      })
    } else {
      throw json.error
    }
  }
}

export const resetPasswordDeny = (id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.DENY_PASSWORD_RESET, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.PASSWORD_RESET,
      })
    } else {
      throw json.error
    }
  }
}

export const getData = ({ friends_limit = 5, products_limit = 5 } = {}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.GET, {
      id: getState().auth.userData.id,
      friends_limit,
      products_limit,
    })
    const json = await response.data
    if (response.status === 200) {
      dispatch({
        type: USER.DATA_RECEIVED,
        payload: json,
      })
      return json
    } else {
      throw json.error
    }
  }
}

export const updateInfo = ({
  first_name,
  last_name,
  country,
  city,
  state,
  status,
  interests,
  full_image,
  full_header,
  phones,
  favorite_films,
  favorite_books,
  favorite_games,
  favorite_quotes,
  favorite_subjects,
  birthdate,
  invenstory_preferences,
  restore,
  highlighted,
  backup_email,
  ignored_email_types,
  time_zone,
}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.UPDATE_INFO, {
      first_name,
      last_name,
      country,
      city,
      state,
      status,
      interests,
      full_image,
      full_header,
      phones,
      favorite_films,
      favorite_books,
      favorite_games,
      favorite_quotes,
      favorite_subjects,
      birthdate,
      invenstory_preferences,
      restore,
      highlighted,
      backup_email,
      ignored_email_types,
      time_zone,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.INFO_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const updateEmail = ({ old_email, new_email }) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.UPDATE_EMAIL, {
      token: getState().auth.userData.token,
      old_email,
      new_email,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.INFO_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const updateEmailConfirm = (id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.CONFIRM_EMAIL_UPDATE, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.EMAIL_UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const updateEmailDeny = (id) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.DENY_EMAIL_UPDATE, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.EMAIL_UPDATE,
      })
    } else {
      throw json.error
    }
  }
}

export const updatePassword = ({ old_password, new_password }) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.UPDATE_PASSWORD, {
      token: getState().auth.userData.token,
      old_password,
      new_password,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.INFO_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const updateRole = ({ token, role }) => {
  return async (dispatch) => {
    const response = await call(endpoints.USERS.UPDATE_ROLE, {
      token,
      role,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.INFO_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const addEducation = ({
  name,
  type,
  department,
  country,
  city,
  date_from,
  date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.EDUCATION_ADD, {
      token: getState().auth.userData.token,
      name,
      type,
      department,
      country,
      city,
      date_from,
      date_to,
    })
    const json = await response.data

    if (response.status === 200) {
      // return dispatch({
      //     type: USER.EDUCATION_UPDATED,
      // })
    } else {
      throw json.error
    }
  }
}

export const updateEducation = ({
  id,
  name,
  type,
  department,
  country,
  city,
  date_from,
  date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.EDUCATION_UPDATE, {
      token: getState().auth.userData.token,
      id,
      name,
      type,
      department,
      country,
      city,
      date_from,
      date_to,
    })
    const json = await response.data

    if (response.status === 200) {
      // return dispatch({
      //     type: USER.EDUCATION_UPDATED,
      // })
    } else {
      throw json.error
    }
  }
}

export const deleteEducation = (id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.EDUCATION_DELETE, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.EDUCATION_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const highlightEducation = (id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.EDUCATION_HIGHLIGHT, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.EDUCATION_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const unhighlightEducation = (token, id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.EDUCATION_UNHIGHLIGHT, {
      token: getState().auth.userData.id,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.EDUCATION_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const addWork = ({
  name,
  role,
  country,
  city,
  description,
  date_from,
  date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.WORK_ADD, {
      token: getState().auth.userData.token,
      name,
      role,
      country,
      city,
      description,
      date_from,
      date_to,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.WORK_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const updateWork = ({
  id,
  token,
  name,
  role,
  country,
  city,
  description,
  date_from,
  date_to,
}) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.WORK_UPDATE, {
      id,
      token: getState().auth.userData.token,
      name,
      role,
      country,
      city,
      description,
      date_from,
      date_to,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.WORK_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const deleteWork = (id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.WORK_DELETE, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.WORK_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const highlightWork = (id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.WORK_HIGHLIGHT, {
      token: getState().auth.userData.token,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.WORK_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const unhighlightWork = (id) => {
  return async (dispatch, getState) => {
    const response = await call(endpoints.USERS.WORK_UNHIGHLIGHT, {
      token: getState().auth.userData.id,
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.WORK_UPDATED,
      })
    } else {
      throw json.error
    }
  }
}

export const setKnowcred = (id) => {
  return {
    type: USER.HAS_KNOWKRED,
    payload: id,
  }
}

export const setNewFriend = (id) => {
  return {
    type: USER.HAS_FRIEND,
    payload: id,
  }
}

export const setMeetCreation = (hash) => {
  return {
    type: USER.SAVE_MEET_HASH,
    payload: hash,
  }
}

export const setInviterGroup = (id) => {
  return {
    type: USER.HAS_GROUP,
    payload: id,
  }
}

export const getBlacklist = ({ name, offset = 0, limit = LIMIT }) => {
  return async (dispatch, getState) => {
    const response = await call(USER.BLACKLIST.GET, {
      token: getState().auth.userData.token,
      name,
      offset,
      limit,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.BLACKLIST.GET,
        payload: { list: json.users, offset },
      })
    } else {
      throw json.error
    }
  }
}

export const clearBlacklist = () => {
  return async (dispatch) => {
    return dispatch({
      type: USER.BLACKLIST.CLEAR,
    })
  }
}

export const addUserToBlacklist = ({ user }) => {
  return async (dispatch, getState) => {
    const response = await call(USER.BLACKLIST.ADD_USER, {
      token: getState().auth.userData.token,
      user_id: user.id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.BLACKLIST.ADD_USER,
        payload: user,
      })
    } else {
      throw json.error
    }
  }
}

export const removeUserFromBlacklist = ({ user_id }) => {
  return async (dispatch, getState) => {
    const response = await call(USER.BLACKLIST.REMOVE_USER, {
      token: getState().auth.userData.token,
      user_id,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.BLACKLIST.REMOVE_USER,
        payload: { user_id },
      })
    } else {
      throw json.error
    }
  }
}

export const saveGoogleAuthCode = ({ code }) => {
  return async (dispatch) => {
    const response = await call(USER.GOOGLE_CODE_SAVE, {
      code,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.GOOGLE_CODE_SAVE,
      })
    } else {
      throw json.error
    }
  }
}

export const revokeGoogleAuthCode = () => {
  return async (dispatch) => {
    const response = await call(USER.GOOGLE_CODE_REVOKE)
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.GOOGLE_CODE_REVOKE,
      })
    } else {
      throw json.error
    }
  }
}

export const saveAzureAuthCode = ({ code }) => {
  return async (dispatch) => {
    const response = await call(USER.AZURE_CODE_SAVE, {
      code,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.AZURE_CODE_SAVE,
      })
    } else {
      throw json.error
    }
  }
}

export const revokeAzureAuthCode = () => {
  return async (dispatch) => {
    const response = await call(USER.AZURE_CODE_REVOKE)
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: USER.AZURE_CODE_REVOKE,
      })
    } else {
      throw json.error
    }
  }
}

export const getUserFromMeetLink = ({ id }) => {
  return async (dispatch) => {
    const response = await call(APPOINTMENTS.USER_INFO, {
      id,
    })
    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: APPOINTMENTS.USER_INFO,
        payload: json?.user,
      })

      return json?.user
    } else {
      throw json.error
    }
  }
}

export const updateAppointmentTypes = ({ types = [] }) => {
  return async (dispatch) => {
    const response = await call(APPOINTMENTS.UPDATE_TYPES, {
      types,
    })
    const json = await response.data

    if (response.status === 200) {
      return dispatch({
        type: APPOINTMENTS.UPDATE_TYPES,
        payload: types,
      })
    } else {
      throw json.error
    }
  }
}

export const getAppointmentTypes = ({ id }) => {
  return async (dispatch) => {
    const response = await call(APPOINTMENTS.GET_TYPES, { id })
    const json = await response.data

    if (response.status === 200) {
      dispatch({
        type: APPOINTMENTS.GET_TYPES,
        payload: json.types,
      })

      return json.types
    } else {
      throw json.error
    }
  }
}

export const getUserSubscriptions = () => {
  return async (dispatch, getState) => {
    const response = await fetch(nestAPI + "users/subscriptions", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getState().auth.userData.token}`,
      },
    })

    if (!response.ok) throw new Error("Cant fetch subscriptions")

    const data = await response.json()

    dispatch({
      type: USER.CURRENT_SUBSCRIPTIONS,
      payload: data,
    })

    return data
  }
}

export const getUserCustomerPortalUrl = (customerId) => {
  return async (dispatch, getState) => {
    const response = await fetch(
      nestAPI + "customers/" + customerId + "/portal",
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.userData.token}`,
        },
      }
    )

    if (!response.ok) throw new Error("Cant fetch customer portal url")

    const data = await response.json()

    dispatch({
      type: USER.CURRENT_SUBSCRIPTIONS,
      // payload: data,
    })

    return data.url
  }
}
