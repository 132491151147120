import React from "react"
import { useGroup } from "../../../../../../hooks/data/groups/groups"
import { Box } from "../../../../../../components/tw/layout"
import HorizontalScroll from "../../../../../../components/ui/HorizontalScroll"
import PlainLink from "../../../../../../components/ui/link"
import { GROUP_ATTACHMENTS } from "../../../../../../library/constants/routes"
import { useQuery } from "@tanstack/react-query"
import { GROUPS } from "../../../../../../library/store/actions/types/groupsTypes"
import { call } from "../../../../../../library/networking/API"
import InsertDriveFile from "@material-ui/icons/InsertDriveFile"
import { LinkOutlined } from "@material-ui/icons"

const GroupAttachments = () => {
  const { group } = useGroup()

  //TODO: add products to attachments list
  const { data: products, isSuccess } = useQuery({
    queryKey: [
      GROUPS.LIBRARY_LIST,
      { group_id: group.id, offset: 0, limit: 10 },
    ],
    queryFn: async (ctx) => {
      const res = await call(ctx.queryKey[0], ctx.queryKey[1])
      return res.data.products
    },
    enabled: !!group.products_number,
    refetchOnWindowFocus: false,
    staleTime: 60000,
  })

  const list = React.useMemo(() => {
    return [
      ...group.links.map((v) => ({ ...v, entityType: "link" })),
      ...group.images.map((v) => ({ ...v, entityType: "image" })),
      ...group.videos.map((v) => ({ ...v, entityType: "video" })),
      ...group.blobs.map((v) => ({ ...v, entityType: "blob" })),
      // ...products,
    ]
      .filter((v) => !!v.id)
      .sort((a, b) => {
        return new Date(b.created).getTime() - new Date(a.created).getTime()
      })
      .slice(0, 10)
  }, [group])

  // console.log(list)

  const composeImage = (v) => {
    switch (v.entityType) {
      case "image":
        return (
          <img
            src={v.url}
            alt={"Image attachment"}
            className={"aspect-square object-center object-cover"}
          />
        )
      case "blob":
        return <InsertDriveFile className="text-black54 !w-20 !h-20" />
      default:
        return <LinkOutlined className="text-black54 !w-20 !h-20" />
    }
  }

  // const composeLink = (v) => {
  //   switch (v.entityType) {
  //     case "image":
  //       return <PlainLink native to={v.url}>{v.name}</PlainLink>
  //   }
  // }

  return (
    <Box>
      <p>Attachments</p>
      <HorizontalScroll>
        <div className="grid gap-4 grid-flow-col justify-start">
          {list.map((v, i) => {
            // const image =
            return (
              <div
                className={
                  "grid grid-rows-subgrid row-span-2 gap-2 rounded-sm bg-white w-32"
                }
              >
                <div className={"flex items-center justify-center w-full"}>
                  {composeImage(v)}
                </div>
                <PlainLink native to={v.url} className={"!line-clamp-1"}>
                  {v.name}
                </PlainLink>
              </div>
            )
          })}
        </div>
      </HorizontalScroll>
      {list.length > 10 && (
        <div>
          <PlainLink to={GROUP_ATTACHMENTS}>Show All</PlainLink>
        </div>
      )}
    </Box>
  )
}

export default GroupAttachments
