import React from "react"
import BoxPage from "../../../../../components/v2/layout/boxPage/boxPage"
import SuperDataTable from "../../../../../components/v2/dataTable/SuperDataTable"
import UserLink from "../../../../../components/v2/utils/UserLink"
import { safeFormatDate } from "../../../../../library/utils/dateConvert"
import PlainLink from "../../../../../components/ui/link"
import CheckOutlined from "@material-ui/icons/CheckOutlined"
import CloseOutlined from "@material-ui/icons/CloseOutlined"
import UpdateIcon from "@material-ui/icons/UpdateOutlined"
import { DeleteForeverOutlined } from "@material-ui/icons"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"

const TrackingOutgoingAiAppointmentsNonCoursePage = () => {
  return (
    <BoxPage title={"Outgoing AI meets — Non-Course"}>
      <SuperDataTable
        columns={[
          { label: "Title", role: "name" },
          { label: "Target or Set Date" },
          { label: "Cohort/Arrivals" },
          { label: "Members/Complete" },
          { label: "Location" },
          { label: "Conference Link" },
          { label: "Reminder" },
          { label: "", role: "actions" },
        ]}
        queryConfig={{
          noFetch: true,
          key: [
            {
              type: "ai-meets-outgoing-no-course",
              url: "tracking.ai_meets",
              fetchParams: { b_creator: true, for_course: false },
            },
          ],
          listKey: "list",
          counterKey: "total",
          composeRowData: (record) => [
            record.name || "",
            safeFormatDate(record.target),
            "cohort/arrivals",
            "members/complete",
            record.location || "",
            record.conference_link.length
              ? {
                  type: "component",
                  component: () => (
                    <PlainLink native to={record.conference_link}>
                      {record.conference_link}
                    </PlainLink>
                  ),
                }
              : "",
            record.reminder || "",
          ],
        }}
        actions={[
          {
            type: "button",
            label: () => (
              <>
                <FileCopyOutlinedIcon />
                <span>Duplicate</span>
              </>
            ),
            onClick: () => {
              console.log("duplicate")
            },
          },
          {
            type: "button",
            label: () => (
              <>
                <DeleteForeverOutlined />
                <span>Delete</span>
              </>
            ),
            onClick: () => {
              console.log("delete")
            },
          },
        ]}
        actionsPinned
        isSearchable
        customColumnsWidth
      />
    </BoxPage>
  )
}

export default TrackingOutgoingAiAppointmentsNonCoursePage
