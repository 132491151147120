import React from "react"
import { useDispatch } from "react-redux"
import { useAlert } from "../../../../../../hooks/useAlert"
import {
  getAppointmentTypes,
  updateAppointmentTypes,
} from "../../../../../../library/store/actions/creators/authCreators"
import { useToggle } from "../../../../../../hooks/useToggle"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp"
import { Collapse } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox/Checkbox"
import { Submit } from "../../../../../../components/v2/layout/forms/form/form"
import Loader from "../../../../../../components/ui/loader"

const AppointmentTypes = () => {
  const dispatch = useDispatch()
  const { successAlert, errorAlert } = useAlert()

  const [isOpen, toggleOpen] = useToggle(false)

  const [dataReceived, setDataReceived] = React.useState(false)

  const [values, setValues] = React.useState([])

  React.useEffect(() => {
    const getTypes = async () => {
      const types = await dispatch(getAppointmentTypes({}))

      if (types.length) {
        setValues(types)
      }

      setDataReceived(true)
    }

    getTypes()
  }, [])

  const onSubmit = async () => {
    try {
      await dispatch(updateAppointmentTypes({ types: values }))
      successAlert()
    } catch (e) {
      errorAlert(e)
    }
  }

  return (
    <div>
      <div
        role={"button"}
        onClick={toggleOpen}
        className="grid grid-cols-[1fr,_max-content] gap-8 items-center p-4 cursor-pointer bg-primary5 hover:bg-primary15"
      >
        <p className={"line-clamp-1"}>Appointment types</p>
        <div>
          {!isOpen ? (
            <KeyboardArrowDown className="text-black38" />
          ) : (
            <KeyboardArrowUp className="text-black38" />
          )}
        </div>
      </div>
      <Collapse in={isOpen}>
        <div className="p-4">
          {dataReceived ? (
            <>
              {[5, 15, 30, 45, 60].map((t) => (
                <div key={t}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.includes(t)}
                        onChange={(e) => {
                          setValues((prevState) => {
                            if (e.target.checked) {
                              return [...prevState, t]
                            } else {
                              return prevState.filter((v) => v !== t)
                            }
                          })
                        }}
                      />
                    }
                    label={`${t}-Minute Meeting`}
                  />
                </div>
              ))}
              <div className="full-width f jcc mt20">
                <Submit
                  onClick={onSubmit}
                  color={"primary"}
                  variant={"contained"}
                >
                  Save
                </Submit>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </Collapse>
    </div>
  )
}

export default AppointmentTypes
