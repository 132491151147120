import React from "react"
import { useBookmarksMutations, useFoldersList } from "../data/bookmarks"
import { useAlert } from "../useAlert"
import { Button } from "@material-ui/core"

import { useHistory } from "react-router-dom"
import FoldersPicker from "../../screens/main/bookmarks/folders/addToFolder/foldersPicker"
import { useSelector } from "react-redux"

const BookmarksContext = React.createContext(null)

export const useBookmarksModal = () => React.useContext(BookmarksContext)

const BookmarksProvider = ({
  children,
  keyToUpdate,
  mutationType,
  pathToBookmarks,
  enabled = true,
}) => {
  const { isLoggedIn } = useSelector(({ auth }) => auth)
  const history = useHistory()
  const { toast, closeToast } = useAlert()

  const { data: foldersData } = useFoldersList({
    staleTime: Infinity,
    enabled: isLoggedIn && enabled,
  })

  const { createBookmark, deleteBookmark } = useBookmarksMutations({
    queryKey: keyToUpdate,
    mutationType,
  })

  const [createdBookmark, setCreatedBookmark] = React.useState(null)

  const [isOpen, setIsOpen] = React.useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const onCreateBookmark = async ({
    params: {
      user_id,
      group_id,
      product_id,
      post_id,
      knowcred_id,
      standard_id,
      record_id,
    },
    updater,
  }) => {
    setCreatedBookmark(null)

    const { id } = await createBookmark.mutateAsync({
      params: {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      },
      updater,
    })

    setCreatedBookmark(id)

    await toast("Saved to bookmarks", {
      autoHideDuration: 5000,
      action: (key) => {
        return (
          <>
            <Button
              color="primary"
              onClick={() => {
                closeToast(key)
                history.push(pathToBookmarks)
              }}
            >
              View
            </Button>
            {!!foldersData?.folders?.length && (
              <Button color="primary" onClick={onOpen}>
                Add to folder
              </Button>
            )}
          </>
        )
      },
    })
  }

  const onDeleteBookmark = async ({
    params: {
      user_id,
      group_id,
      product_id,
      post_id,
      knowcred_id,
      standard_id,
      record_id,
    },
    updater,
  }) => {
    await deleteBookmark.mutateAsync({
      params: {
        user_id,
        group_id,
        product_id,
        post_id,
        knowcred_id,
        standard_id,
        record_id,
      },
      updater,
    })
  }

  return (
    <BookmarksContext.Provider
      value={{
        createBookmark: onCreateBookmark,
        deleteBookmark: onDeleteBookmark,
      }}
    >
      {children}

      {enabled && isOpen && (
        <FoldersPicker
          open={isOpen}
          onClose={onClose}
          bookmarkId={createdBookmark}
        />
      )}
    </BookmarksContext.Provider>
  )
}

export default BookmarksProvider
