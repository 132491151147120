import React from "react"
import InfoLayout from "../infoLayout"
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom"
import * as routes from "../../../library/constants/routes"
import { SectionHeading } from "../uknow/uknowInfoPage"
import SolutionsOneTeacherPage from "./SolutionsOneTeacherPage"
import SolutionsEducatorsPage from "./SolutionsEducatorsPage"
import SolutionsProductsPage from "./SolutionsProductsPage"
import SolutionsAutoCohortingPage from "./SolutionsAutoCohortingPage"
import SolutionsMasterSchedulePage from "./SolutionsMasterSchedulePage"
import SolutionsSchoolsPage from "./SolutionsSchoolsPage"
import SolutionsCompaniesPage from "./SolutionsCompaniesPage"
import SolutionsEducatorIntroPage from "./SolutionsEducatorIntroPage"

const navItems = [
  {
    label: "Educator Course Creation",
    path: routes.SOLUTIONS_TEACHER_INTRO,
    component: () => SolutionsEducatorIntroPage,
  },
  {
    label: "One-Teacher Schools & Gig Teaching",
    path: routes.SOLUTIONS_ONE_TEACHER,
    component: () => SolutionsOneTeacherPage,
  },
  {
    label: "Free Educator Start",
    path: routes.SOLUTIONS_EDUCATORS,
    component: () => SolutionsEducatorsPage,
  },
  {
    label: "Schools & Districts",
    path: routes.SOLUTIONS_SCHOOLS,
    component: () => SolutionsSchoolsPage,
  },
  {
    label: "Auto-Cohorting AI Calendaring",
    path: routes.SOLUTIONS_AUTO_COHORTING,
    component: () => SolutionsAutoCohortingPage,
  },
  {
    label: "Flex Master Schedules",
    path: routes.SOLUTIONS_MASTER_SCHEDULE,
    component: () => SolutionsMasterSchedulePage,
  },
  {
    label: "Company Groups",
    path: routes.SOLUTIONS_COMPANIES,
    component: () => SolutionsCompaniesPage,
  },
  {
    label: "Featured Products",
    path: routes.SOLUTIONS_PRODUCTS,
    component: () => SolutionsProductsPage,
  },
]

const SolutionsPage = () => {
  const match = useRouteMatch()

  const isParentPage = match.path === routes.SOLUTIONS

  if (isParentPage) {
    return <Redirect to={navItems?.[0]?.path} />
  }

  return (
    <InfoLayout>
      <section className={"bg-white w-full pb-8"}>
        <div
          className={
            "w-full p-4 bg-primary text-center text-white font-medium text-2xl"
          }
        >
          Solutions
        </div>
        <div className="grid grid-cols-[1fr_minmax(min-content,_1200px)_1fr] w-full py-2">
          <div className="col-start-2 px-4 flex items-center justify-between w-full container">
            <nav className={"mx-auto"}>
              <ul
                className={
                  "list-none grid grid-cols-4 gap-4 text-center text-lg"
                }
              >
                {navItems.map((item) => (
                  <li key={item.path}>
                    <NavLink
                      exact
                      to={item.path}
                      activeClassName={"underline"}
                      className={
                        "text-primary  duration-200 transition-all hover:underline"
                      }
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <Switch>
        {navItems.map((item) => (
          <Route
            key={item.path}
            exact
            path={item.path}
            component={item.component()}
          />
        ))}
      </Switch>
    </InfoLayout>
  )
}

export default SolutionsPage
