import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { QueryParamProvider } from "use-query-params"
import Header from "../components/header/index"
import Footer from "../components/footer/index"
import { MuiThemeProvider } from "@material-ui/core/styles"
import theme from "../library/m-ui-theme"
import AuthPage from "./auth/auth-page"
import NotFound from "../components/notFound/notFound"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { SnackbarProvider } from "notistack"
import BlankPage from "./auth/blankPage/blankPage"
import InfoPage from "./info/infoPage/infoPage"
import DateFnsUtils from "@date-io/date-fns"
import { SnackbarUtilsConfigurator } from "../components/snackbarUtilsConfig"
import {
  ACCOUNTS_INFO,
  ACCOUNTS_INFO_AGENT,
  ACCOUNTS_INFO_EDUCATOR,
  ACCOUNTS_INFO_LEARNER,
  ACCOUNTS_INFO_ORGANIZATION,
  APP_PROMOTE,
  BASE,
  blankPageRoutes,
  BOOK_MEET,
  BOOK_MEET_AUTHORIZED,
  BOOK_MEET_UNAUTHORIZED,
  bookmarksRoutes,
  calendarRoutes,
  chatsRoutes,
  COMPLETE_SIGNUP,
  CONFIRM_PASSWORD_RESET,
  CONTACT_FORM,
  friendsRoutes,
  GROUP,
  groupsRoutes,
  HOME,
  HOW_TO,
  HOW_TO_ARTICLE,
  HOW_TO_CATEGORY,
  INCOMING_SOLICIT_APPOINTMENT,
  INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED,
  INCOMING_SOLICIT_APPOINTMENT_UNAUTHORIZED,
  invenstoryRoutes,
  knowcredRoutes,
  knowmapRoutes,
  knowmixRoutes,
  marketplaceRoutes,
  newsRoutes,
  notificationsRoutes,
  PRICING,
  RESET_PASSWORD,
  searchRoutes,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SOLUTIONS,
  SOLUTIONS_AUTO_COHORTING,
  SOLUTIONS_COMPANIES,
  SOLUTIONS_EDUCATORS,
  SOLUTIONS_MASTER_SCHEDULE,
  SOLUTIONS_ONE_TEACHER,
  SOLUTIONS_PRODUCTS,
  SOLUTIONS_SCHOOLS,
  SOLUTIONS_TEACHER_INTRO,
  standardsRoutes,
  TERMS_AND_CONDITIONS,
  userRoutes,
  USER as USER_PROFILE,
} from "../library/constants/routes"
import AuthHome from "./auth/authHome/authHome"
import PrivateRoute from "./privateRouteV2"
import { store } from "../library/store/configureStore"
import { USER } from "../library/store/actions/types/authTypes"
import AccountsInfoMain from "./info/accounts/accountsInfoMain"
import AccountsInfoLearner from "./info/accounts/accountsInfoLearner"
import AccountsInfoEducator from "./info/accounts/accountsInfoEducator"
import AccountsInfoAgent from "./info/accounts/accountsInfoAgent"
import AccountsInfoOrganization from "./info/accounts/accountsInfoOrganization"
import SolutionsPage from "./info/solutions/SolutionsPage"
import GroupPage from "./main/groups/group/groupPage/GroupPage"
import SignInPage from "./auth/SignInPage/SignInPage"
import SignUpPage from "./auth/SignUp/SignUpPage"
import CompleteSignUpPage from "./auth/SignUp/CompleteSignUpPage"
import ResetPasswordRequestPage from "./auth/resetPassword/ResetPasswordRequestPage"
import ResetPasswordConfirmationPage from "./auth/resetPassword/ResetPasswordConfirmationPage"
import UserProfile from "./main/user/userProfile"

class AppRouter extends Component {
  signOutAll = (e) => {
    if (e.key === "isLoggedIn" && e.oldValue !== e.newValue) {
      if (e.newValue !== "true") {
        store.dispatch({ type: USER.SIGNED_OUT })
      } else {
        window.location.reload()
      }
    }
  }

  componentDidMount() {
    window.addEventListener("storage", this.signOutAll)
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.signOutAll)
  }

  render() {
    const { isLoggedIn } = this.props.auth
    return (
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <MuiThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              preventDuplicate={true}
              autoHideDuration={10000}
            >
              <SnackbarUtilsConfigurator />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="grid-page">
                  <div className="grid-content-wrapper">
                    {!isLoggedIn ? (
                      <Header
                        isLoggedIn={isLoggedIn}
                        location={this.props.location}
                      />
                    ) : null}
                    <Switch>
                      <Route exact path={BASE} component={AuthHome} />

                      <Route exact path={SIGN_IN} component={SignInPage} />

                      <Route exact path={SIGN_UP} component={SignUpPage} />

                      <Route
                        exact
                        path={COMPLETE_SIGNUP}
                        component={CompleteSignUpPage}
                      />

                      <Route
                        exact
                        path={RESET_PASSWORD}
                        component={ResetPasswordRequestPage}
                      />

                      <Route
                        exact
                        path={CONFIRM_PASSWORD_RESET}
                        component={ResetPasswordConfirmationPage}
                      />

                      <Route
                        exact
                        path={BOOK_MEET_UNAUTHORIZED}
                        render={(props) => (
                          <AuthPage
                            {...props}
                            pageType={"book-meet-unauthorized"}
                            auth={this.props.auth}
                            redirectRouteIfLoggedIn={BOOK_MEET}
                          />
                        )}
                      />

                      <PrivateRoute path={SIGN_OUT} />

                      <PrivateRoute
                        path={BOOK_MEET_AUTHORIZED}
                        redirectRouteIfLoggedOut={BOOK_MEET}
                      />

                      <Route
                        exact
                        path={INCOMING_SOLICIT_APPOINTMENT_UNAUTHORIZED}
                        render={(props) => (
                          <AuthPage
                            {...props}
                            pageType={
                              "incoming-solicit-appointment-unauthorized"
                            }
                            auth={this.props.auth}
                            redirectRouteIfLoggedIn={
                              INCOMING_SOLICIT_APPOINTMENT
                            }
                          />
                        )}
                      />

                      <PrivateRoute
                        path={INCOMING_SOLICIT_APPOINTMENT_AUTHORIZED}
                        redirectRouteIfLoggedOut={INCOMING_SOLICIT_APPOINTMENT}
                      />

                      <Route
                        exact
                        path={blankPageRoutes}
                        component={BlankPage}
                      />

                      <PrivateRoute
                        path={[
                          HOME,
                          ...userRoutes,
                          ...newsRoutes,
                          ...searchRoutes,
                          ...friendsRoutes,
                          ...knowcredRoutes,
                          ...groupsRoutes,
                          ...chatsRoutes,
                          ...marketplaceRoutes,
                          ...bookmarksRoutes,
                          ...standardsRoutes,
                          ...notificationsRoutes,
                          ...knowmixRoutes,
                          ...invenstoryRoutes,
                          ...calendarRoutes,
                          ...knowmapRoutes,
                          HOW_TO,
                          HOW_TO_CATEGORY,
                          HOW_TO_ARTICLE,
                        ]}
                      />

                      <PrivateRoute
                        path={USER_PROFILE}
                        publicView={(props) => (
                          <div className={"container"}>
                            <div className="common-page">
                              <UserProfile />
                            </div>
                          </div>
                        )}
                      />

                      <PrivateRoute
                        path={GROUP}
                        publicView={(props) => (
                          <div className={"container"}>
                            <div className="common-page">
                              <UserProfile />
                            </div>
                          </div>
                        )}
                      />

                      <PrivateRoute
                        path={TERMS_AND_CONDITIONS}
                        publicView={(props) => (
                          <InfoPage {...props} pageType={"terms"} />
                        )}
                      />

                      <PrivateRoute
                        path={CONTACT_FORM}
                        publicView={(props) => (
                          <AuthPage {...props} pageType={"feed-back"} />
                        )}
                      />
                      <PrivateRoute
                        path={APP_PROMOTE}
                        publicView={(props) => (
                          <InfoPage {...props} pageType={"app-promote"} />
                        )}
                      />

                      <PrivateRoute
                        path={ACCOUNTS_INFO}
                        publicView={(props) => <AccountsInfoMain {...props} />}
                      />

                      <PrivateRoute
                        path={ACCOUNTS_INFO_LEARNER}
                        publicView={(props) => (
                          <AccountsInfoLearner {...props} />
                        )}
                      />

                      <PrivateRoute
                        path={ACCOUNTS_INFO_EDUCATOR}
                        publicView={(props) => (
                          <AccountsInfoEducator {...props} />
                        )}
                      />

                      <PrivateRoute
                        path={ACCOUNTS_INFO_AGENT}
                        publicView={(props) => <AccountsInfoAgent {...props} />}
                      />

                      <PrivateRoute
                        path={ACCOUNTS_INFO_ORGANIZATION}
                        publicView={(props) => (
                          <AccountsInfoOrganization {...props} />
                        )}
                      />
                      <PrivateRoute
                        path={[
                          SOLUTIONS,
                          SOLUTIONS_SCHOOLS,
                          SOLUTIONS_COMPANIES,
                          SOLUTIONS_ONE_TEACHER,
                          SOLUTIONS_PRODUCTS,
                          SOLUTIONS_EDUCATORS,
                          SOLUTIONS_MASTER_SCHEDULE,
                          SOLUTIONS_AUTO_COHORTING,
                          SOLUTIONS_TEACHER_INTRO,
                        ]}
                        publicView={(props) => <SolutionsPage {...props} />}
                      />

                      <PrivateRoute
                        path={PRICING}
                        publicView={(props) => (
                          <InfoPage
                            {...props}
                            pageType={"pricing"}
                            isLoggedIn={isLoggedIn}
                          />
                        )}
                      />

                      <Route component={NotFound} />
                    </Switch>
                  </div>
                  <Footer isLoggedIn={isLoggedIn} />
                </div>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </MuiThemeProvider>
        </QueryParamProvider>
      </Router>
    )
  }
}

const mapStateToProps = ({ auth }) => ({ auth })
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter)
